import {DROPDOWN_MENU_CONTACTS_LINKS} from 'constants/contactsMenu';
import {
    LINK_WOMANS_EYEGLASSES,
    LINK_MENS_EYEGLASSES,
    LINK_KIDS_EYEGLASSES,
    LINK_DESIGNER_GLASSES,
    LINK_PRESCRIPTION_SUNGLASSES,
    LINK_BRAND,
    LINK_SAFETY_GLASSES,
} from 'constants/links';

export const DROP_DOWN_MENU_LINKS = {
    eyeglasses: {
        women: {
            name: 'Women',
            link: LINK_WOMANS_EYEGLASSES,
        },
        men: {
            name: 'Men',
            link: LINK_MENS_EYEGLASSES,
        },
        kids: {
            name: 'Kids',
            link: LINK_KIDS_EYEGLASSES,
        },
        safety: {
            name: 'Safety',
            link: `${LINK_SAFETY_GLASSES}?eyewear_type=eyeglasses`,
        },
    },
    sunglasses: {
        women: {
            name: 'Women',
            link: `${LINK_PRESCRIPTION_SUNGLASSES}?gender=women`,
        },
        men: {
            name: 'Men',
            link: `${LINK_PRESCRIPTION_SUNGLASSES}?gender=men`,
        },
        kids: {
            name: 'Kids',
            link: `${LINK_KIDS_EYEGLASSES}?eyewear_type=sunglasses`,
        },
        safety: {
            name: 'Safety',
            link: `${LINK_SAFETY_GLASSES}?eyewear_type=sunglasses`,
        },
    },
    brands: {
        rayBan: {
            name: 'RayBan',
            link: LINK_BRAND.RAY_BAN,
        },
        oakley: {
            name: 'Oakley',
            link: LINK_BRAND.OAKLEY,
        },
        coach: {
            name: 'Coach',
            link: LINK_BRAND.COACH,
        },
        michael: {
            name: 'MichaelKors',
            link: LINK_BRAND.MICHAEL_KORS,
        },
        versace: {
            name: 'Versace',
            link: LINK_BRAND.VERSACE,
        },
        prada: {
            name: 'Prada',
            link: LINK_BRAND.PRADA,
        },
        ottoto: {
            name: 'Ottoto',
            link: LINK_BRAND.OTTOTO,
        },
        muse: {
            name: 'Muse',
            link: LINK_BRAND.MUSE,
        },
        burberry: {
            name: 'Burberry',
            link: LINK_BRAND.BURBERRY,
        },
        persol: {
            name: 'Persol',
            link: LINK_BRAND.PERSOL,
        },
        toryBurch: {
            name: 'ToryBurch',
            link: LINK_BRAND.TORY_BURCH,
        },
        costaDelMar: {
            name: 'CostaDelMar',
            link: LINK_BRAND.COSTA_DEL_MAR,
        },
        armani: {
            name: 'Armani',
            link: LINK_BRAND.ARMANI,
        },
        vogue: {
            name: 'Vogue',
            link: LINK_BRAND.VOGUE,
        },
        all: {
            name: 'Shop All Brands',
            link: LINK_DESIGNER_GLASSES,
        },
    },
    contacts: DROPDOWN_MENU_CONTACTS_LINKS.contacts,
};
