/**
 * Disable scroll on page
 *
 * @param {boolean} state - current state
 * @param {boolean} [restoreScrollPosition=true] - restoring scroll position when scroll disabling is turning off
 */
export const disableScroll = (state: boolean, restoreScrollPosition = true) => {
    const {body} = document;

    if (state) {
        body.style.top = `-${document.documentElement.scrollTop || document.body.scrollTop}px`;
        body.classList.add('no-scroll');
    } else {
        body.classList.remove('no-scroll');

        if (body.style.top && restoreScrollPosition) {
            window.scrollTo(0, Math.abs(parseInt(body.style.top, 10)));
        }

        body.style.top = '';
    }
};
