import {createAction} from 'redux-act';

import {ENDPOINTS} from 'constants/endpoints';

import {AddToCartType} from './addToCartType';

type StatusType = {
    isLoading: boolean;
    isUpdatePending: boolean;
};

export const initialState: StatusType = {
    isLoading: false,
    isUpdatePending: false,
};

export const addEECToCart = createAction('ADD_CONTACTS_TO_CART');

export const updateCartClItem = createAction('UPDATE_CART_CL_ITEM', (data: AddToCartType) => ({
    request: {
        method: 'PUT',
        headers: {Authorization: '', 'Content-Type': 'application/json'},
        url: `/backend/${ENDPOINTS.CART_PUT_GET_QUOTE_ITEM_V2}/${data.quoteItemId}`,
        data,
    },
}));
