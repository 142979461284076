import {createAction, createReducer} from 'redux-act';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {Status} from 'constants/reducerStatus';
import {RequestType} from 'constants/typescript/types';
import {getErrorMessages} from 'libs/getErrorMessages';

import {AddressType, AddressRequestType} from './addressType';

export const defaultState: AddressType = {
    list: [],
    status: Status.Fulfilled,
    error: {},
};

export const getAddresses = createAction<RequestType>('GET_ADDRESSES', () => ({
    request: {
        headers: {Authorization: ''},
        method: 'GET',
        url: '/backend/api/rest/customer/address',
    },
}));
export const createAddress = createAction<AddressRequestType, RequestType>(
    'CREATE_ADDRESS',
    data => ({
        request: {
            headers: {Authorization: ''},
            method: 'POST',
            url: '/backend/api/rest/customer/address',
            data,
        },
    }),
);
export const updateAddress = createAction(
    'UPDATE_ADDRESS',
    (id: number, data: AddressRequestType): RequestType => ({
        request: {
            headers: {Authorization: ''},
            method: 'PUT',
            url: `/backend/api/rest/customer/address/${id}`,
            data,
        },
    }),
);
export const deleteAddress = createAction<number, RequestType>('DELETE_ADDRESS', (id: number) => ({
    request: {
        headers: {Authorization: ''},
        method: 'DELETE',
        url: `/backend/api/rest/customer/address/${id}`,
    },
}));
export const clearAddressError = createAction('CLEAR_ADDRESS_ERROR');
export const clearAddressErrorField = createAction<string, string>(
    'CLEAR_ADDRESS_ERROR_FIELD',
    fieldName => fieldName,
);

/**
 * Address reducer
 */
export const address = createReducer<AddressType>(
    {
        [`${getAddresses}${FULFILLED}`]: (state, {data}): AddressType => ({
            ...state,
            list: data,
            status: Status.Fulfilled,
        }),
        [`${createAddress}${FULFILLED}`]: (state): AddressType => ({
            ...state,
            status: Status.Fulfilled,
        }),
        [`${updateAddress}${FULFILLED}`]: (state): AddressType => ({
            ...state,
            status: Status.Fulfilled,
        }),
        [`${createAddress}`]: (state): AddressType => ({
            ...state,
            status: Status.Pending,
        }),
        [`${updateAddress}`]: (state): AddressType => ({
            ...state,
            status: Status.Pending,
        }),
        [`${createAddress}${REJECTED}`]: (state, {error: {response}}): AddressType => ({
            ...state,
            status: Status.Rejected,
            error: getErrorMessages(response.data.messages.error),
        }),
        [`${updateAddress}${REJECTED}`]: (state, {error: {response}}): AddressType => ({
            ...state,
            status: Status.Rejected,
            error: getErrorMessages(response.data.messages.error),
        }),
        [`${clearAddressError}`]: (state): AddressType => ({
            ...state,
            error: {},
        }),
        [`${clearAddressErrorField}`]: (state, payload): AddressType => ({
            ...state,
            error: {
                ...state.error,
                [payload]: '',
            },
        }),
    },
    defaultState,
);
