import React, {memo} from 'react';

import {Link} from 'react-router-dom';
import {useTransition, animated} from 'react-spring';

import {SearchItemType} from 'constants/typescript/types';
import {MenuItemsLoginPopUpShownEvent} from 'libs/analytics/loginPopUp/GeneralInteractionEvents';

import s from './searchResults.scss';

export type PropsType = {
    keyword: string;
    searchedItems: Array<SearchItemType>;
    keywordSearching: (x: string) => void;
    page: string;
    isLoggedIn: boolean;
};

/**
 * Displays the search result for search input in header
 *
 * @param {PropsType} props - props
 * @class
 */
export const SearchResults = memo<PropsType>(
    ({searchedItems, keywordSearching, keyword, page, isLoggedIn}) => {
        /**
         * Provides the highlight for name
         *
         * @param {string} name - product name
         * @returns {string} matched string
         */
        const displayNameWithMatchSymbols = (name: string): string => {
            const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

            return name.replace(new RegExp(escapedKeyword, 'gi'), match => `<b>${match}</b>`);
        };

        const filteredSearchItems = searchedItems.filter(product => product.image);

        const transitions = useTransition(filteredSearchItems, {
            keys: filteredSearchItems.map(product => product.sku),
            from: {opacity: 0},
            enter: {opacity: 1},
            leave: {opacity: 0, display: 'none'},
            config: {duration: 300, tension: 125, friction: 20, precision: 0.1},
        });

        const clickHandlerAnalytics = (): void => {
            if (isLoggedIn) return;
            MenuItemsLoginPopUpShownEvent(page === 'category', 'SearchClick');
        };

        if (searchedItems.length === 0) return null;

        return (
            <ul className={s.list}>
                {transitions((props, item) => (
                    <animated.li className={s.item} style={props}>
                        <Link
                            className={s.link}
                            to={item.link}
                            onClick={() => {
                                keywordSearching(item.name);
                                clickHandlerAnalytics();
                            }}
                        >
                            {item.image && (
                                <img className={s.image} src={item.image} alt={item.name} />
                            )}
                            <span
                                className={s.text}
                                dangerouslySetInnerHTML={{
                                    __html: displayNameWithMatchSymbols(item.name),
                                }}
                            />
                        </Link>
                    </animated.li>
                ))}
            </ul>
        );
    },
);
