import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from 'react';

import {RoundButton} from '@optimaxdev/design-system-u';
import {useMutation} from '@tanstack/react-query';
import cn from 'classnames';
import {pickBy} from 'ramda';
import InputMask from 'react-text-mask';

import {PHONE_MASK} from 'constants/masks/masks';
import {getToken} from 'features/loginPopup/api/customerMs/customerMs';
import {uhcLogin} from 'features/loginPopup/api/uhc/uhc';
import {
    LoginPopupContext,
    LoginPopupContextType,
} from 'features/loginPopup/context/loginPopupContext';

import {COUNTRY_CODE, PHONE_NUMBER_LENGTH} from '../../../constants/loginPopup';
import {LoginPopupNames} from '../../loginPopup';
import {sendMFAAnalytic} from '../analytics';
import {AUTH_STEPS} from '../twoFactorAuth';
import {WaitForPhoneNumberType} from '../types';
import {PolicyText} from './getPolicyText';

import s from './waitForPhoneNumber.scss';

export const WaitForPhoneNumber = ({
    setStep,
    setPopup,
    setAnalyticsStep,
}: WaitForPhoneNumberType) => {
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        setAnalyticsStep('1');
        sendMFAAnalytic('Shown', 1, false);
    }, [setAnalyticsStep]);

    const {emailLoginData, phoneNumber, setPhoneNumber, isMember, memberLoginData, setAccountUuid} =
        useContext(LoginPopupContext) as LoginPopupContextType;

    const [isDisabled, setIsDisabled] = useState(phoneNumber?.length !== PHONE_NUMBER_LENGTH);
    const handleChange = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(value);

        if (value.length === PHONE_NUMBER_LENGTH) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };

    const handleOnFocus = () => {
        if (!phoneNumber.startsWith(COUNTRY_CODE)) setPhoneNumber(`${COUNTRY_CODE} ${phoneNumber}`);
    };

    const handleOnBlur = () => {
        if (phoneNumber.length <= COUNTRY_CODE.length + 1) setPhoneNumber('');
    };

    const {isLoading, mutate} = useMutation(
        () =>
            isMember
                ? uhcLogin(pickBy(value => value !== '', {...memberLoginData, phone: phoneNumber}))
                : getToken(emailLoginData.email.value, emailLoginData.password.value, phoneNumber),
        {
            onSuccess({accountUuid}) {
                setAccountUuid(accountUuid);
                setPhoneNumber(phoneNumber);
                setStep(AUTH_STEPS.WAIT_FOR_CODE);
                sendMFAAnalytic('Success', 1);
            },
            onError({response}) {
                setErrorMsg(response.data.hint || response.data.message);
                sendMFAAnalytic(`Error - ${response.data.hint || response.data.message}`, 1);
            },
        },
    );

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        mutate();
    };

    return (
        <div className={s.wrap}>
            <button
                className={s.backBtn}
                onClick={() => setPopup(isMember ? LoginPopupNames.member : LoginPopupNames.email)}
                aria-label="back"
            >
                Back
            </button>
            <h3 className={s.title}>We want to confirm it&apos;s you.</h3>
            <p className={s.desc}>Get a verification code sent to your phone.</p>
            <form onSubmit={handleSubmit}>
                <label
                    className={cn(s.inputWrap, {
                        [s.inputActive]: isDisabled && phoneNumber,
                        [s.inputValid]: !isDisabled,
                        [s.inputError]: errorMsg,
                    })}
                >
                    <InputMask
                        mask={PHONE_MASK}
                        onChange={handleChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        value={phoneNumber}
                        guide={false}
                        render={(ref, props) => (
                            <input
                                {...props}
                                placeholder="e.g. +1 (201) 000-0000"
                                ref={ref}
                                required
                            />
                        )}
                    />
                </label>
                {errorMsg && <p className={s.errorMsg}>{errorMsg}</p>}
                <PolicyText />
                <RoundButton
                    isLoading={isLoading}
                    className={s.btn}
                    size="regular"
                    isDisabled={isDisabled}
                    type="submit"
                    ariaLabel="Send Code"
                >
                    Send Code
                </RoundButton>
            </form>
        </div>
    );
};
