import {AxiosAuthRefreshRequestConfig} from 'axios-auth-refresh';

import {ENDPOINTS} from 'constants/endpoints';
import flags from 'constants/flags/flags';
import {LogInType} from 'features/loginPopup/api/customerMs/customerMsType';
import {axiosClient} from 'libs/configureStore';

import {UhcLoginFormParams} from './uhcType';

export const getFormattedBirthday = birthday =>
    `${birthday.substring(6, birthday.length)}-${birthday.substring(0, 5)}`;
/**
 * Log In UHC user and response result
 *
 * @param {UhcLoginFormParams} formData - value of form's inputs
 * @returns {Promise<LogInType>} result response
 */
export const uhcLogin = async ({
    id,
    birthday,
    first_name,
    last_name,
    zip_code,
    phone,
}: UhcLoginFormParams): Promise<LogInType> => {
    const {data} = await axiosClient.post<LogInType>(
        ENDPOINTS.UHC_API_LOGIN,
        {
            id,
            birthday: birthday ? getFormattedBirthday(birthday) : '',
            first_name,
            last_name,
            zip_code,
            network: 'UHC',
            ...(phone && {phone}),
        },
        {
            skipAuthRefresh: true,
            params: {
                '2fa': flags.mfaUHCGD.isEnabled() ? 'sms' : null,
            },
        } as AxiosAuthRefreshRequestConfig,
    );

    return data;
};
