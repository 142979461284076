import React, {FC} from 'react';

import {Price} from 'components/__helpers/price/price';

import s from './planAllowanceRow.scss';

type PropsType = {
    allowance: number;
    copay: number;
    frameTypeTitle?: string;
    isFrameAllowanceForCL?: boolean;
    isFrameAllowance?: boolean;
    isFrameAllowanceZero?: boolean;
};
const getIsDividerShown = (
    isFrameAllowanceForCL: boolean,
    isFrameAllowance: boolean,
    isFrameAllowanceZero: boolean,
) => isFrameAllowanceForCL && isFrameAllowance && !isFrameAllowanceZero;
/**
 * Item for a plan description
 */
export const PlanAllowanceItem: FC<PropsType> = ({
    allowance,
    copay,
    frameTypeTitle = 'any frame',
    isFrameAllowanceForCL = false,
    isFrameAllowance = false,
    isFrameAllowanceZero = false,
}) => {
    const isDividerShown = getIsDividerShown(
        isFrameAllowanceForCL,
        isFrameAllowance,
        isFrameAllowanceZero,
    );
    return (
        <>
            {isDividerShown && (
                <p>
                    <span className={s.divider}>Or</span>
                </p>
            )}
            <p>
                Up to{' '}
                <span className={s.price}>
                    <Price>{allowance}</Price>
                </span>
            </p>

            <p>
                for {frameTypeTitle} (co-pay <Price>{copay}</Price>)
            </p>
        </>
    );
};
