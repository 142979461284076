import {pushDataLayer} from '@optimaxdev/analytics/desktop';
import {type ActionType} from 'redux-actions';
import {type SagaIterator} from 'redux-saga';
import {select, put, putResolve, takeEvery, call} from 'redux-saga/effects';

import {REJECTED} from 'constants/actionSuffix';
import {setRecentlyViewed} from 'reducers/additionalProducts';
import {getProduct, setProduct} from 'reducers/product';
import {setPage, type PageType} from 'reducers/route';
import {getCategoryData, getProductFromCategory} from 'selectors/category/category';
import {getPageData} from 'selectors/page/getPageData';
import {getProductById} from 'selectors/product/product';

/**
 * Set current product
 *
 * @param {number} id - product id
 */
export function* setCurrentProduct(id: number): SagaIterator {
    let product = yield select(getProductById, id);
    const {page} = yield select(getPageData);

    if (product || ['wizard', 'configureWizard'].includes(page)) return;

    product = yield select(getProductFromCategory, id);

    if (product) {
        yield put(setProduct(product));
    } else {
        yield putResolve(getProduct(id));
    }
}

/**
 * Set recently viewed product
 *
 * @param {string} page - current page
 * @param {number} id - product id
 */
export function* setRecentlyViewedProduct(page: string, id: number): SagaIterator {
    if (page !== 'product') return;

    const product = yield select(getProductById, id);
    const {title: category = ''} = yield select(getCategoryData);
    if (product) yield put(setRecentlyViewed({...product, category}));
}

/**
 * Set data product
 *
 * @param {ActionType<PageType>} action - action
 */
export function* setDataProduct(action: ActionType<PageType>): SagaIterator {
    const {page, params} = action.payload;

    if (!['product', 'wizard'].includes(page)) return;

    yield call(setCurrentProduct, params.id);
    yield call(setRecentlyViewedProduct, page, params.id);
}

/**
 * Send Product Error Event to dataLayer
 *
 * @param {string} error - error message.
 */
export function* sendProductRejectedAnalytics(error: any): SagaIterator {
    yield call(pushDataLayer, {
        event: 'PDPInteraction',
        eventAction: 'Product details',
        eventCategory: 'PDP - D',
        eventLabel: `Error - ${error?.payload?.error?.response?.data?.message || 'Unknown error'}`,
    });
}

/**
 * Begin of saga
 */
export function* productSaga(): SagaIterator {
    yield takeEvery(setPage, setDataProduct);
    yield takeEvery(`${getProduct}${REJECTED}`, sendProductRejectedAnalytics);
}
