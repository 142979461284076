/* eslint-disable max-lines */
import {Status} from 'constants/reducerStatus';

export const FilterItemFrameSizeFixture = [
    {id: 68, value: 'small', title: 'Small', image: '', position: 1, count: 10},
    {id: 67, value: 'medium', title: 'Medium', image: '', position: 2, count: 20},
    {id: 66, value: 'large', title: 'Large', image: '', position: 3, count: 30},
    {id: 235, value: 'xl', title: 'XL', image: '', position: 4, count: 40},
];

export const FilterItemColorsFixture = [
    {id: 15, value: 'blue', title: 'Blue', image: '', position: 2, count: 10},
    {id: 18, value: 'brown', title: 'Brown', image: '', position: 3, count: 20},
    {id: 11, value: 'gray', title: 'Gray', image: '', position: 4, count: 20},
];

export const FiltersFixture = [
    {
        code: 'color',
        title: 'Color',
        input: 'multiselect',
        position: 30,
        items: FilterItemColorsFixture,
    },
    {
        code: 'frame_size',
        title: 'Size',
        input: 'multiselect',
        position: 40,
        items: FilterItemFrameSizeFixture,
    },
];

export const CategoryFiltersFixture = {
    filters: FiltersFixture,
    status: Status.Fulfilled,
};

export const AllFiltersFixture = [
    {
        code: 'shape',
        position: 10,
        input: 'multiselect',
        title: 'Frame Shape',
        items: [
            {
                id: 186,
                value: 'square',
                title: 'Square',
                image: '',
                position: 1,
                count: 100,
            },
        ],
    },
    {
        code: 'gender',
        position: 20,
        input: 'multiselect',
        title: 'Gender',
        items: [
            {
                id: 5,
                value: 'women',
                title: 'Women',
                image: '',
                position: 1,
                count: 100,
            },
        ],
    },
    {
        code: 'brand',
        position: 30,
        input: 'select',
        title: 'Brand',
        items: [
            {
                id: 5,
                value: 'muse',
                title: 'Muse',
                image: '/brand/1/Muse_0_image.png',
                position: 1,
                count: 79,
            },
        ],
    },
    {
        code: 'frame_size',
        position: 40,
        input: 'select',
        title: 'Size',
        items: [
            {
                id: 5,
                value: 'small',
                title: 'Small',
                image: '',
                position: 1,
                count: 12,
            },
        ],
    },
    {
        code: 'color',
        position: 50,
        input: 'multiselect',
        title: 'Color',
        items: [
            {
                id: 5,
                value: 'tortoise',
                title: 'Tortoise',
                image: '',
                position: 1,
                count: 12,
            },
        ],
    },
    {
        code: 'glasses_type',
        position: 60,
        input: 'multiselect',
        title: 'Glasses type',
        items: [
            {
                id: 5,
                value: 'sunglasses',
                title: 'Sunglasses',
                image: '',
                position: 1,
                count: 12,
            },
        ],
    },
    {
        code: 'frame_type',
        position: 70,
        input: 'multiselect',
        title: 'Frame Type',
        items: [
            {
                id: 5,
                value: 'full_frame',
                title: 'Full Frame',
                image: '',
                position: 1,
                count: 12,
            },
        ],
    },
    {
        code: 'lenstype',
        position: 80,
        input: 'multiselect',
        title: 'Lens Type',
        items: [
            {
                id: 5,
                value: 'single_vision',
                title: 'Single Vision',
                image: '',
                position: 1,
                count: 12,
            },
        ],
    },
];
