import {pushDataLayer} from '@optimaxdev/analytics/desktop';
import {insertScript} from '@optimaxdev/utils';

/**
 * Sends Ada chat opened event
 */
const sendAdaChatOpenedEvent = () => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: 'Bubble Chat Opened',
        eventCategory: 'General',
        eventLabel: '',
    });
};
export const ADA_CHAT_SETTINGS = {
    toggleCallback: (isOpen: boolean) => {
        if (isOpen && !window.adaManualToggle) sendAdaChatOpenedEvent();
        delete window.adaManualToggle;
    },
};
/**
 * Adds ada chat script
 */
export const addAdaChat = () => {
    window.adaSettings = {...ADA_CHAT_SETTINGS, handle: 'uhcg', lazy: false, stopped: false};

    const script = insertScript('https://static.ada.support/embed2.js', false);
    script.id = '__ada';
    script.defer = true;
};

/**
 * Wrapper for open chat action
 */
export const openChat = () => {
    if (window.adaEmbed) {
        window.adaManualToggle = true;
        window.adaEmbed.toggle();
        sendAdaChatOpenedEvent();
    }
};
