import {createAction, createReducer} from 'redux-act';

import {ELIGIBLE_MESSAGE} from '../constants';

export type EligibilityWidgetType = {
    activeMessage: keyof typeof ELIGIBLE_MESSAGE | '';
    isOpen: boolean;
    isPlanRead: boolean;
    isMergeAccountRead: boolean;
};

export const defaultState: EligibilityWidgetType = {
    activeMessage: '',
    isOpen: true,
    isPlanRead: false,
    isMergeAccountRead: false,
};

export const setActiveEligibilityMessage = createAction<keyof typeof ELIGIBLE_MESSAGE | ''>(
    '@@WIDGET/SET_ACTIVE_ELIGIBILITY_MESSAGE>',
);
export const toggleEligibilityWidget = createAction('@@WIDGET/SET_ACTIVE_ELIGIBILITY_SWITCH');
export const resetEligibilityWidget = createAction('@@WIDGET/RESET_ELIGIBILITY_WIDGET');
export const hideEligibilityWidget = createAction('@@WIDGET/HIDE_ELIGIBILITY_WIDGET');

export const setReadPlanMessage = createAction<boolean>('@@WIDGET/SET_READ_PLAN_MESSAGE');
export const setReadMergeMessage = createAction<boolean>('@@WIDGET/SET_READ_MERGE_MESSAGE');

/**
 * Eligibility widget reducer
 */
export const eligibilityWidget = createReducer<EligibilityWidgetType>({}, defaultState)
    .on(toggleEligibilityWidget, state => ({...state, isOpen: !state.isOpen}))
    .on(
        setActiveEligibilityMessage,
        ({isPlanRead, isMergeAccountRead, activeMessage, isOpen}, value) => ({
            activeMessage: value,
            isOpen: activeMessage !== value || isOpen,
            isPlanRead,
            isMergeAccountRead,
        }),
    )
    .on(setReadPlanMessage, state => ({...state, isPlanRead: true}))
    .on(setReadMergeMessage, state => ({...state, isMergeAccountRead: true}))
    .on(hideEligibilityWidget, state => ({...state, isOpen: false}))
    .on(resetEligibilityWidget, () => defaultState);
