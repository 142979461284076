import React from 'react';

const ELEMENT_ID = 'popperContainer';

export const getPopperContainer = (): HTMLElement => {
    return document.getElementById(ELEMENT_ID) as HTMLElement;
};

export const PopperContainer: React.FC = () => {
    return <div id={ELEMENT_ID} />;
};
