export const EYEGLASSES_GROUP_IDS = ['Polarized Lenses', 'Color Tinted Lenses', 'Mirrored Lenses'];
export const PHOTOCHROMIC_GROUP_IDS = ['Photochromic Lenses', 'Transitions® VI'];
export const EXTRA_TRANSITION_IDS = ['Transitions XTRActive', 'Transitions XTRActive Polarized'];

export const EYEGLASSES_COLORS_TITLES = {
    [EYEGLASSES_GROUP_IDS[0]]: {
        title: 'Polarized',
        description: 'Reduce glare and haze for clearer vision',
        tooltipDescription:
            'Enhanced color contrast provides the highest level of clarity and visual comfort, with reduced reflections and maximum UV protection.',
    },
    [EYEGLASSES_GROUP_IDS[1]]: {
        title: 'Color Tint',
        description: 'Sun protection basic lenses',
        tooltipDescription:
            'High level of clarity and visual comfort. Choose lenses with solid colors.',
    },
    [EYEGLASSES_GROUP_IDS[2]]: {
        title: 'Mirrored',
        description: 'High fashionable reflective color',
        tooltipDescription:
            'Mirrored lenses deliver a super cool look with a higher level of clarity, full UV protection, and reduced glare.',
    },
};

export const SUNGLASSES_GROUP_IDS = [
    EYEGLASSES_GROUP_IDS[1],
    EYEGLASSES_GROUP_IDS[0],
    EYEGLASSES_GROUP_IDS[2],
];

export const SUNGLASSES_COLORS_TITLES = {
    [SUNGLASSES_GROUP_IDS[0]]: {
        title: 'Colorized',
        description: 'Provides basic protection from the sun and comes in several color options.',
    },
    [SUNGLASSES_GROUP_IDS[1]]: {
        title: 'Polarized',
        description: 'Reduces glare and provides the highest image definition possible.',
    },
    [SUNGLASSES_GROUP_IDS[2]]: {
        title: 'Mirrored',
        description: 'Delivers an unparalleled level of cool and reflective lens protection.',
    },
};

export const COLORIZED_ALIASES = ['light_adjusting', 'sunglasses_options'];

export const TRANSITION_COLLECTIONS_GROUP_IDS = [
    'Transitions® VI',
    ...EXTRA_TRANSITION_IDS,
    PHOTOCHROMIC_GROUP_IDS[0],
];

export const TRANSITION_GROUP_IDS = ['Transitions® VI', ...EXTRA_TRANSITION_IDS];
export const TRANSITION_COLOR_TITLES = {
    [TRANSITION_GROUP_IDS[0]]: 'Transitions Gen 8 Signature',
    [TRANSITION_GROUP_IDS[1]]: 'Transitions Xtractive',
    [TRANSITION_GROUP_IDS[2]]: 'Transitions Xtractive Polarized',
};

export const SUMMARY_TITLES = {
    'trans-gray': 'Transitions GEN 8 Signature Gray',
    'trans-brown': 'Transitions GEN 8 Signature Brown',
    'trans-sapphire': 'Transitions GEN 8 Signature Sapphire',
    'trans-amethyst': 'Transitions GEN 8 Signature Amethyst',
    'trans-emerald': 'Transitions GEN 8 Signature Emerald',
    'trans-amber': 'Transitions GEN 8 Signature Amber',
    'trans-green': 'Transitions GEN 8 Signature Green',
};

export const PHOTOCHROMIC_ANALYTICS_TITLES = {
    [PHOTOCHROMIC_GROUP_IDS[0]]: 'House',
    [PHOTOCHROMIC_GROUP_IDS[1]]: 'Transition',
};

export const TRANSITION_ANALYTICS_TITLES = {
    [TRANSITION_COLLECTIONS_GROUP_IDS[0]]: 'GEN8',
    [TRANSITION_COLLECTIONS_GROUP_IDS[1]]: 'XTRACTIVE',
    [TRANSITION_COLLECTIONS_GROUP_IDS[2]]: 'XTRACTIVE POLARIZED',
};
