// @flow

type ErrorObjType = {[string]: string};

/**
 * Return object with fields as key and error message as value
 *
 * @param {string} message - error message
 * @returns {ErrorObjType} object with error messages
 */
export const getMessage = (message: string): ErrorObjType => {
    const [fieldName, value] = message.split(':');
    if (message.toLowerCase().includes('email'))
        return {originalEmail: message, email: value ? value.trim() : ''};

    return fieldName && value ? {[fieldName]: value.trim()} : {form: message};
};

/**
 * Return object with fields as key and error message as value
 *
 * @param {Array<{message: string}>} errorArr - array of error objects
 * @returns {ErrorObjType} object with error messages
 */
export const getErrorMessages = (errorArr: Array<{message: string}>): ErrorObjType =>
    errorArr.reduce((obj, item) => ({...obj, ...getMessage(item.message)}), {});
