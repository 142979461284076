// @flow
import type {ApplicationStoreType} from 'constants/flow/flowTypes';
import type {CountryRegionItemType, CountryType, CountryItemType} from 'reducers/country';

/**
 * Get regions data
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {Array<CountryRegionItemType>} - regions data
 */
export const getRegionsData = (state: ApplicationStoreType): Array<CountryRegionItemType> =>
    state.country.regions;

/**
 * Get country data
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {CountryType} - country data
 */
export const getCountryData = (state: ApplicationStoreType): CountryType => state.country;

/**
 * Get country by code
 *
 * @param {ApplicationStoreType} state - Application state
 * @param {string} code - country code
 * @returns {CountryItemType} - country data
 */
export const getCountryByCode = (
    state: ApplicationStoreType,
    code: string,
): CountryItemType | {name: string} => {
    return state.country.list.find(item => item.id === code) || {name: ''};
};
