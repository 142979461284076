// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';

import type {SizeCalculatorType} from './sizeCalculatorType';

export const defaultState = {
    lens: '',
    bridge: '',
    temple: '',
    active: '',
};

export const {setCalculatorSizeValue, setCalculatorDefaultValues} = createActions({
    SET_CALCULATOR_SIZE_VALUE: (
        field: string,
        value: string,
    ): ActionType<{field: string, value: string}> => ({field, value}),
    SET_CALCULATOR_DEFAULT_VALUES: (): ActionType<> => {},
});

/**
 * Size calculator reducer
 *
 * @returns {SizeCalculatorType} state - next state
 */
export const sizeCalculator = handleActions(
    {
        [setCalculatorSizeValue]: (
            state: SizeCalculatorType,
            {payload: {field, value}}: ActionType<typeof setCalculatorSizeValue>,
        ): SizeCalculatorType => ({
            ...state,
            [field]: value,
            active: field,
        }),
        [setCalculatorDefaultValues]: (): SizeCalculatorType => defaultState,
    },
    defaultState,
);
