// @flow
import type {SagaIterator} from 'redux-saga';
import {call} from 'redux-saga/effects';

import {wizardValidation} from '../wizardValidation/wizardValidation';

/**
 * Begin of wizard saga
 */
export default function* wizardSaga(): SagaIterator {
    yield call(wizardValidation);
}
