import {LENS_TYPE_OPTIONS_KEYS} from 'constants/wizard/wizard';
import {EYEGLASSES_GROUP_IDS} from 'features/wizard/store/constants/upsells';

export const MULTIPLE_SELECTION_UPSELL_ALIASES = [
    LENS_TYPE_OPTIONS_KEYS.DIGITAL,
    LENS_TYPE_OPTIONS_KEYS.SUNGLASSES,
    LENS_TYPE_OPTIONS_KEYS.PHOTOCHROMIC,
];

export const SUNGLASSES_GROUP_IDS = [
    EYEGLASSES_GROUP_IDS[1],
    EYEGLASSES_GROUP_IDS[0],
    EYEGLASSES_GROUP_IDS[2],
];

export const SUNGLASSES_COLORS_TITLES = {
    [SUNGLASSES_GROUP_IDS[0]]: 'Colorized',
    [SUNGLASSES_GROUP_IDS[1]]: 'Polarized',
    [SUNGLASSES_GROUP_IDS[2]]: 'Mirrored',
};

export const SUNGLASSES_COLORS_DESCRIPTION = {
    [SUNGLASSES_COLORS_TITLES[SUNGLASSES_GROUP_IDS[0]]]: 'High-level clarity and visual comfort',
    [SUNGLASSES_COLORS_TITLES[SUNGLASSES_GROUP_IDS[1]]]:
        'Block out glare, increase clarity & enhance color contrast',
    [SUNGLASSES_COLORS_TITLES[SUNGLASSES_GROUP_IDS[2]]]:
        'Block out glare  & UV rays with a light-reflective look',
};
