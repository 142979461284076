import React, {FC, useContext, useEffect, useState} from 'react';

import {MemberDataType} from 'components/home/signInHomePage/hooks';
import {LogInType} from 'features/loginPopup/api/customerMs/customerMsType';

import {LoginPopupContext, LoginPopupContextType} from '../../context/loginPopupContext';
import {LoginPopupNames} from '../loginPopup';
import {SuccessLogin} from './successLogin/successLogin';
import {WaitForCode} from './waitForCode/waitForCode';
import {WaitForPhoneNumber} from './waitForPhoneNumber/waitForPhoneNumber';

export enum AUTH_STEPS {
    WAIT_FOR_PHONE_NUMBER = 'WAIT_FOR_PHONE_NUMBER',
    WAIT_FOR_CODE = 'WAIT_FOR_CODE',
    SUCCESS_LOGIN = 'SUCCESS_LOGIN',
}

type PropsType = {
    onRequestClose: () => void;
    setAnalyticsStep: (step: string) => void;
    setPopup: (popup: LoginPopupNames) => void;
    memberLoginPhoneDataHP?: LogInType;
    memberLoginDataHP?: MemberDataType;
};

/**
 * MFA
 */
export const TwoFactorAuth: FC<PropsType> = ({
    onRequestClose,
    setPopup,
    setAnalyticsStep,
    memberLoginDataHP,
    memberLoginPhoneDataHP,
}) => {
    const {
        canEditPhone,
        setInputValue,
        setIsMember,
        setCanEditPhone,
        setAccountUuid,
        setPhoneNumber,
    } = useContext(LoginPopupContext) as LoginPopupContextType;
    const [step, setStep] = useState<AUTH_STEPS>(
        canEditPhone ? AUTH_STEPS.WAIT_FOR_PHONE_NUMBER : AUTH_STEPS.WAIT_FOR_CODE,
    );

    // Set user data when the user comes from the Home page
    useEffect(() => {
        if (memberLoginDataHP) {
            setInputValue(memberLoginDataHP);
            setIsMember(true);
        }
        if (memberLoginPhoneDataHP) {
            setCanEditPhone(memberLoginPhoneDataHP.canEditPhone);
            setAccountUuid(memberLoginPhoneDataHP.accountUuid);
            setPhoneNumber(memberLoginPhoneDataHP.phone);

            setStep(AUTH_STEPS.WAIT_FOR_CODE);
        }
    }, [
        memberLoginDataHP,
        memberLoginPhoneDataHP,
        setAccountUuid,
        setCanEditPhone,
        setInputValue,
        setIsMember,
        setPhoneNumber,
    ]);

    const authScreen = {
        WAIT_FOR_PHONE_NUMBER: (
            <WaitForPhoneNumber
                setPopup={setPopup}
                setStep={setStep}
                setAnalyticsStep={setAnalyticsStep}
            />
        ),
        WAIT_FOR_CODE: (
            <WaitForCode
                setStep={setStep}
                onRequestClose={onRequestClose}
                setAnalyticsStep={setAnalyticsStep}
            />
        ),
        SUCCESS_LOGIN: <SuccessLogin onRequestClose={onRequestClose} />,
    };
    return <>{authScreen[step]}</>;
};
