import {createAction, createReducer} from 'redux-act';

import {AdditionalProductType} from '.';

export const defaultState: AdditionalProductType[] = [];

export const setRecentlyViewed = createAction<AdditionalProductType>('SET_RECENTLY_VIEWED');

/**
 * Additional products reducer
 */
export const additionalProducts = createReducer<AdditionalProductType[]>({}, defaultState).on(
    setRecentlyViewed,
    (state, product) => [
        {
            ...product,
            img: product.media.front?.src || '',
        },
        ...state.filter(item => item.id !== product.id).slice(0, 5),
    ],
);
