/* eslint-disable no-underscore-dangle, max-statements, @typescript-eslint/no-var-requires, @typescript-eslint/no-explicit-any */
import {getVisitorId} from '@optimaxdev/libs';
import {createReduxEnhancer} from '@sentry/react';
import axios from 'axios';
import {applyMiddleware, compose, combineReducers} from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import {createStore} from 'redux-dynamic-modules';
import {getSagaExtension} from 'redux-dynamic-modules-saga';
import {persistStore, persistReducer, createMigrate, getStoredState} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import Visibility from 'visibilityjs';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {SITE_ID, STORE_ID} from 'constants/store';
import {setAuthorizationHeaderInterceptor} from 'libs/axiosInterceptors';
import {createCancellationMiddleware} from 'libs/middleware/cancellationMiddleware';
import {migrations} from 'libs/migrations/migrations';
import {filterStateForSentry} from 'libs/utils/utils';

import {rootReducer, sessionReducers} from '../reducers';
import rootSaga from '../sagas';

export const sagaMiddleware = createSagaMiddleware();

export const axiosClient = axios.create({
    baseURL: (window as any).SITE_URL,
    headers: {
        website: SITE_ID as any,
        store: STORE_ID as any,
        'X-Visitor-Id': getVisitorId(),
    },
    responseType: 'json',
});

export const onError = ({action, dispatch, error}: Record<string, any>) =>
    dispatch({type: `${action.type}${REJECTED}`, payload: {error}});

export const axiosOptions = {
    successSuffix: FULFILLED,
    errorSuffix: REJECTED,
    interceptors: {
        request: [{success: setAuthorizationHeaderInterceptor}],
    },
    onError,
};

export const persistConfig = {
    key: 'uhc-v1',
    storage,
    blacklist: [
        ...Object.keys(sessionReducers),
        'route',
        'country',
        'cmsPopup',
        'cmsPage',
        'wizard',
        'category',
        'categoryItems',
        'categorySort',
        'sizeCalculator',
        'creditCard', // very important! Do not to save this data!
        'myPlan',
        'tryOn',
        'tracking',
        'accountSettings',
        'product',
        'dialog',
        'address',
        'newPassword',
        'offsetTop',
        'wizardMs',
        'customer',
        'user',
        'rma',
        'contacts',
    ],
    version: Object.keys(migrations).length - 1, // default version is "-1"
    migrate: createMigrate(migrations, {debug: false}),
};

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

/**
 * Add middleware and enable hot loading
 *
 * @param {{}} initialState State before applying middleware etc
 * @returns {{}} React store and persistor
 */
export default function configureStore(initialState = {}) {
    const rootReducerModule = {
        id: 'root',
        reducerMap: rootReducer,
    };

    /* setup of https://github.com/zalmoxisus/redux-devtools-extension */
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : f => f || compose;

    const store = createStore(
        {
            initialState,
            ...(process.env.NODE_ENV === 'production'
                ? {}
                : {advancedComposeEnhancers: composeEnhancers}),
            advancedCombineReducers: reducersMap =>
                persistReducer(persistConfig, combineReducers(reducersMap)),
            enhancers: [
                applyMiddleware(
                    createCancellationMiddleware(),
                    axiosMiddleware(axiosClient, axiosOptions),
                    sagaMiddleware,
                ),
                createReduxEnhancer({
                    stateTransformer: state => filterStateForSentry(state),
                }),
            ],
            extensions: [getSagaExtension({})],
        },
        rootReducerModule,
    );

    /* istanbul ignore next */
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        /* istanbul ignore next */
        module.hot.accept(() => {
            // This fetch the new state of the above reducers.
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
        });
    }
    const persistor = persistStore(store);

    const {addModules} = store;
    (store.addModules as unknown) = modules => {
        /* istanbul ignore next */
        addModules(modules);
        /* istanbul ignore next */
        persistor.persist();
    };

    Visibility.change(async () => {
        if (!Visibility.hidden()) {
            const state = await getStoredState(persistConfig);
            store.dispatch({
                type: 'persist/REHYDRATE',
                key: persistConfig.key,
                payload: state,
            });
        }
    });

    const sagaTask = sagaMiddleware.run(rootSaga);
    return {store, persistor, sagaTask};
}
