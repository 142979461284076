import {pushDataLayer, sendHashedEmailAndUserIdEvent} from '@optimaxdev/analytics/desktop';
import {SagaIterator} from 'redux-saga';
import {call, takeEvery, select, put, take} from 'redux-saga/effects';

import {REJECTED, FULFILLED} from 'constants/actionSuffix';
import {ActionType, ResponseErrorType} from 'constants/typescript/types';
import {sendLoginEvent} from 'libs/analytics/analytics';
import {
    addToCartFake,
    changeProductInCart,
    getCartTotals,
    removeCartItem,
    updateCartItem,
} from 'reducers/cart/cart';
import {getTokens, putRefreshToken, Oauth2TokenErrorType} from 'reducers/oauth2';
import {setPaymentStep, setProductCategories, setShippingStep} from 'reducers/order';
import {addSunglassesToCart} from 'reducers/product';
import {getUserDiscount} from 'reducers/user';
import {checkoutEECSaga, addToCartEECSaga} from 'sagas/analytics/EEC';
import {getCategoryData} from 'selectors/category/category';
import {getCategoryMap} from 'selectors/order/order';
import {isPopupVisible} from 'selectors/popup/popup';
import {getUserEmailAnalytic, getUserId} from 'selectors/user/user';

import {
    sendAnalyticsEveryChangeProductInCart,
    sendAnalyticsWhenRemoveInCard,
    sendWhenCartHaveItems,
} from './EEC/cart';
import {AddToCartResponseType} from '../../features/wizard/components/summaryBottomCta/summaryBottomCta';
import {createAddress, updateAddress} from '../../reducers/address';
import {getAddressErrors} from '../../selectors/address/address';
import {getPageData} from '../../selectors/page/getPageData';

/**
 * Send hashed email analytic
 */
export function* sendHashedEmail(): SagaIterator {
    yield take(`${getUserDiscount}${FULFILLED}`);
    const email = yield select(getUserEmailAnalytic);
    const userId = yield select(getUserId);

    yield call(sendHashedEmailAndUserIdEvent, email, userId);
}

/**
 * Send error event
 *
 * @param {ActionType<Oauth2TokenErrorType>} action - login error action
 */
export function* sendAuthRejected({
    payload,
}: ActionType<ResponseErrorType<Oauth2TokenErrorType>>): SagaIterator {
    yield call(
        sendLoginEvent,
        'Error',
        payload.error.response && payload.error.response.data.error,
    );
}

/**
 * Send success event
 *
 */
export function* sendAuthEvent(): SagaIterator {
    const isPopupOpen = yield select(isPopupVisible);

    yield call(sendLoginEvent, 'Success');
    yield call(sendLoginEvent, 'Logged in', isPopupOpen ? 'Popup' : 'Homepage');
}

/**
 * Preparing a category for products added to the cart
 *
 * @param {ActionType<AddToCartType>} action - Add to cart action
 */
export function* prepareCategoryData({
    payload: {data},
}: ActionType<{data: AddToCartResponseType}>): SagaIterator {
    const {title = ''} = yield select(getCategoryData);
    if (!title) return;
    const categoryMap = yield select(getCategoryMap);
    yield put(setProductCategories({...categoryMap, [data.sku]: title}));
}

/**
 * Send analytic with error fields
 */
export function* sendErrorFieldsAnalytic(): SagaIterator {
    const errors = yield select(getAddressErrors);
    const {page} = yield select(getPageData);
    const filteredErrors = Object.keys(errors).filter(field => field !== 'form');
    if (page === 'checkout' && filteredErrors.length) {
        pushDataLayer({
            event: 'CheckoutInteraction',
            eventCategory: 'Checkout - D',
            eventAction: 'Step 1 - Delivery',
            eventLabel: `Error - Required Fields Missing - ${filteredErrors.join('|')}`,
        });
    }
}

/**
 * Begin of saga
 */
export function* analyticsSaga(): SagaIterator {
    yield takeEvery(`${getTokens}${FULFILLED}`, sendAuthEvent);
    yield takeEvery([`${getTokens}${REJECTED}`, `${putRefreshToken}${REJECTED}`], sendAuthRejected);
    yield takeEvery(
        [`${addToCartFake}`, `${addSunglassesToCart}${FULFILLED}`],
        prepareCategoryData,
    );
    yield takeEvery([`${addToCartFake}`, `${addSunglassesToCart}${FULFILLED}`], addToCartEECSaga);
    yield takeEvery([setShippingStep, setPaymentStep], checkoutEECSaga);
}

/**
 * Begin of saga
 */
export function* analyticsSecondSaga(): SagaIterator {
    yield takeEvery(
        [`${updateAddress}${REJECTED}`, `${createAddress}${REJECTED}`],
        sendErrorFieldsAnalytic,
    );
    yield takeEvery([`${getCartTotals}${FULFILLED}`, updateCartItem], sendWhenCartHaveItems);
    yield takeEvery(changeProductInCart, sendAnalyticsEveryChangeProductInCart);
    yield takeEvery(removeCartItem, sendAnalyticsWhenRemoveInCard);
}
