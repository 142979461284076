import {AxiosResponse} from 'axios';

import {API_URL_ELASTICSEARCH} from 'constants/api';
import flags from 'constants/flags/flags';
import {STORE_ID} from 'constants/store';
import {SearchItemType} from 'constants/typescript/types';

import {axiosClient} from '../configureStore';

type QueryItemType = {
    name: string;
    results: number;
};

type QuickSearchDtoType = {
    products: Array<SearchItemType>;
    queries: Array<QueryItemType>;
};

export const getQuickSearchResults = async (
    search: string,
    isLensesOnly?: boolean,
): Promise<Array<SearchItemType>> => {
    const getCategory = () => {
        if (isLensesOnly) return ['Contact Lenses'];
        if (!flags.dUHCCL.isEnabled()) return ['Eyeglasses'];
        return ['Eyeglasses', 'Contact Lenses'];
    };

    const rawSearchResult: AxiosResponse<QuickSearchDtoType> = await axiosClient({
        headers: {Authorization: '', 'Content-Type': 'application/json'},
        baseURL: `${API_URL_ELASTICSEARCH}${STORE_ID}/autocomplete`,
        method: 'get',
        params: {
            celebros_site_key: 'UnitedVP',
            q: search,
            search_provider: 'algolia',
            category: getCategory(),
        },
    });

    return rawSearchResult.data.products.map(elm => {
        return {...elm, link: `/${elm.url}`};
    });
};
