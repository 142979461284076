import React, {lazy, useMemo, ReactElement} from 'react';

import {REACTPV} from '@optimaxdev/analytics/desktop';
import {useLoaderData} from 'react-router-dom';

import {LINK_PAGE} from 'constants/links';
import {routeMsResponseType} from 'router/api/dynamicPageLoader/dynamicPageLoader';
import {useRedirectUnauthorised} from 'router/hooks/useRedirectUnauthorised/useRedirectUnauthorised';
import {useSetPage} from 'router/hooks/useSetPage/useSetPage';

import {PAGE_PARAM2} from '../../../constants/analytics';

const CategoryConnected = lazy(
    () => import(/* webpackChunkName: "Category" */ 'pages/category/category'),
);
const ProductConnected = lazy(
    () => import(/* webpackChunkName: "Product" */ 'pages/product/product'),
);
const Page404 = lazy(() => import(/* webpackChunkName: "Product" */ 'pages/404/404'));

type PageType = {
    element: ReactElement;
    event: string;
    type: string;
    redirectUnauthorised: string;
    page_type_param2: string;
};

const availablePages: Record<string, PageType> = {
    category: {
        element: <CategoryConnected />,
        event: REACTPV.CATEGORY,
        type: 'category',
        redirectUnauthorised: '#login',
        page_type_param2: PAGE_PARAM2.CATEGORY,
    },
    product: {
        element: <ProductConnected />,
        event: REACTPV.PRODUCT,
        type: 'product',
        redirectUnauthorised: `${LINK_PAGE.HOME}#login`,
        page_type_param2: PAGE_PARAM2.PRODUCT,
    },
    '404': {
        element: <Page404 />,
        event: REACTPV.OTHER,
        type: '404',
        redirectUnauthorised: '',
        page_type_param2: PAGE_PARAM2.OTHER,
    },
};

export const DynamicPage = (): ReactElement => {
    const {type, id} = useLoaderData() as routeMsResponseType;
    const params = useMemo(
        () => ({
            id,
        }),
        [id],
    );

    const page = availablePages[type] || availablePages['404'];

    useRedirectUnauthorised(page.redirectUnauthorised, Boolean(page.redirectUnauthorised));
    useSetPage({
        page: page.type,
        params,
        event: page.event,
        page_type_param2: page.page_type_param2,
    });
    return page.element;
};
