export const INPUTS = [
    {name: 'email', placeholder: 'Email', autoComplete: 'email'},
    {name: 'password', placeholder: 'Password', autoComplete: 'current-password'},
] as const;

export const ERROR_REASONS = [
    {
        input: 'email',
        error: 'invalid_email',
    },
    {
        input: 'password',
        error: 'invalid_grant',
    },
];

export const COUNTRY_CODE = '+1 ';
export const PHONE_NUMBER_LENGTH = 17;
