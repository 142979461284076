import React, {useCallback, SyntheticEvent, memo} from 'react';

import {CtaButton} from '@optimaxdev/design-system-u';

import {LoginPopupNames} from '../loginPopup';
import {sendAccessLogEvent, sendSignInOrCreateAccountEvent} from '../memberLogin/analytics';

import loginStyle from '../loginPopup.scss';

import s from './bottomBlock.scss';

type PropsType = {
    popup: LoginPopupNames;
    setPopup: (popup: LoginPopupNames) => void;
};

export const BottomBlock: React.FC<PropsType> = memo<PropsType>(({popup, setPopup}) => {
    const isMemberForm = popup === 'member';

    const onClick = useCallback(
        (event: SyntheticEvent<HTMLButtonElement>) => {
            setPopup(event.currentTarget.value as LoginPopupNames);
            if (isMemberForm) {
                sendSignInOrCreateAccountEvent(false);
            } else {
                sendAccessLogEvent();
            }
        },
        [setPopup, isMemberForm],
    );

    const handleSignIn = () => {
        setPopup(LoginPopupNames.email);
        sendSignInOrCreateAccountEvent(true);
    };

    return (
        <section>
            <hr className={s.divider} />
            <h2 className={loginStyle.title}>
                {isMemberForm
                    ? 'No vision insurance? We got you!'
                    : 'Already have vision insurance?'}
            </h2>
            <div className={s.ctaButton}>
                <CtaButton
                    variant="ghost"
                    size="large"
                    isFull
                    value={isMemberForm ? LoginPopupNames.create : LoginPopupNames.member}
                    onClick={onClick}
                >
                    {isMemberForm ? 'Create UHCGlasses.com Account' : 'Access Benefits'}
                </CtaButton>
            </div>
            {isMemberForm && (
                <p className={`${loginStyle.text} ${loginStyle.signUp}`}>
                    Already have an account? <button onClick={handleSignIn}>Sign in</button>
                </p>
            )}
        </section>
    );
});

BottomBlock.displayName = 'CreateAccountBlock';
