import React, {FC, ReactElement, useEffect, useState} from 'react';

import classNames from 'classnames';
import {connect} from 'react-redux';

import {Icon} from 'components/__helpers/icon/icon';
import {WelcomePopupConnected} from 'components/home/welcomePopup/welcomePopup';
import {ApplicationStoreType, Maybe} from 'constants/typescript/types';
import {EligibilityWidgetType, setActiveEligibilityMessage} from 'features/store';
import {setReadPlanMessage} from 'features/store/eligibilityWidget';
import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';
import {UhcBenefitType} from 'reducers/myPlan/myPlanType';
import {getFlatBenefit} from 'selectors/myPlan/myPlan';
import {isUHCBenefitsEligible} from 'selectors/user/user';

import {MemberMessage} from './memberMessage';
import {ELIGIBLE_MESSAGE} from '../../../constants';

import s from './planMessage.scss';

export type PropsType = {
    isUHCBenefits: boolean;
    flatBenefits?: Maybe<UhcBenefitType>;
    setActiveMessage: (key: keyof typeof ELIGIBLE_MESSAGE) => void;
    eligibilityWidget: EligibilityWidgetType;
    openPopup: (node: ReactElement) => void;
    isUHCMember: boolean;
    isPlanMessageRead: boolean;
    markPlanMessage: (state: boolean) => void;
};

export const renderMessage = (message: string): ReactElement[] =>
    message.split('\n').map(paragraph => <span key={paragraph}>{paragraph}</span>);

export const PlanMessage: FC<PropsType> = ({
    isUHCBenefits,
    flatBenefits,
    setActiveMessage,
    eligibilityWidget,
    openPopup,
    isUHCMember,
    isPlanMessageRead,
    markPlanMessage,
}) => {
    const messageKey = isUHCMember ? 'withEligible' : 'nonUHCMember';
    const message = ELIGIBLE_MESSAGE[eligibilityWidget.activeMessage || messageKey];
    const [isCollapsed, setCollapsed] = useState(isPlanMessageRead || false);

    useEffect(() => {
        setActiveMessage(messageKey);
    }, [messageKey, setActiveMessage]);

    const collapseHandler = () => {
        markPlanMessage(true);
        setCollapsed(prevState => !prevState);
    };

    return (
        <article>
            <div className={classNames(s.main, {[s.notRead]: !isPlanMessageRead})}>
                <div className={classNames(s.titleWrap, {[s.titleWrapCollapsed]: isCollapsed})}>
                    <p className={s.articleTitle}>Your plan</p>
                    <button
                        type="button"
                        className={classNames(s.collapseButton, {[s.collapsed]: isCollapsed})}
                        onClick={collapseHandler}
                    >
                        <Icon name="arrow" size="small" />
                    </button>
                </div>
                {!isCollapsed && (
                    <div className={s.messageWrap}>
                        {isUHCMember ? (
                            <MemberMessage
                                allowance={flatBenefits?.allowance}
                                isUHCBenefits={isUHCBenefits}
                                isFlatBenefits={Boolean(flatBenefits)}
                                message={message}
                            />
                        ) : (
                            renderMessage(ELIGIBLE_MESSAGE.withoutEligible)
                        )}
                        <a
                            className={s.planDetailsLink}
                            onClick={() => openPopup(<WelcomePopupConnected isWelcomeText />)}
                        >
                            Plan details
                        </a>
                    </div>
                )}
            </div>
        </article>
    );
};

export const mapStateToProps = (store: ApplicationStoreType) => ({
    isPlanMessageRead: store.widgets.eligibilityWidget.isPlanRead,
    flatBenefits: getFlatBenefit(store),
    isUHCBenefits: isUHCBenefitsEligible(store),
});

export const mapDispatchToProps = {
    markPlanMessage: setReadPlanMessage,
    setActiveMessage: setActiveEligibilityMessage,
    openPopup: (content: ReactElement) =>
        setCmsPopupVisibility(true, content, {
            className: 'welcomePopup',
        }),
};

export const PlanMessageConnected = connect(mapStateToProps, mapDispatchToProps)(PlanMessage);
