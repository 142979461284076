/**
 * Insert script into document body
 *
 * @param {string} src - script source url
 * @param {Function} callback - onLoad callback
 * @returns {Promise<HTMLScriptElement>} - Inserted script
 */
export const insertScript = (src: string, callback?): Promise<HTMLScriptElement> =>
    new Promise(resolve => {
        const script =
            (document.querySelector(`script[src="${src}"]`) as HTMLScriptElement) ||
            document.createElement('script');

        script.src = src;
        script.defer = true;

        document.body.appendChild(script);
        // istanbul ignore next
        script.onload = () => {
            callback();
            resolve(script);
        };
    });
