// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';

import {PRESCRIPTION_SEND_EMAIL_OR_FAX} from 'constants/wizard/wizard';

import type {PrescriptionSendByType} from './index';

export const defaultState = {
    type: PRESCRIPTION_SEND_EMAIL_OR_FAX,
    content: {
        name: '',
        image: '',
    },
};

export const {
    wizard: {setType, setContent, clearContent},
} = createActions({
    WIZARD: {
        SET_TYPE: (type: string) => type,
        SET_CONTENT: (content: string) => content,
        CLEAR_CONTENT: () => {},
    },
});

/**
 * Type of send prescription
 */
export const prescriptionSendBy = handleActions(
    {
        [setType]: (
            state: PrescriptionSendByType,
            {payload}: ActionType<PrescriptionSendByType>,
        ): PrescriptionSendByType => ({
            ...state,
            type: payload,
        }),
        [setContent]: (
            state: PrescriptionSendByType,
            {payload}: ActionType<PrescriptionSendByType>,
        ): PrescriptionSendByType => ({
            ...state,
            content: payload,
        }),
        [clearContent]: (state: PrescriptionSendByType): PrescriptionSendByType => ({
            ...state,
            content: defaultState.content,
        }),
    },
    defaultState,
);
