// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';

import {checkCardType} from 'libs/card';

import type {CreditCardType} from './creditCardType';

export const defaultState = {
    cardNumber: '',
    cardMonth: '',
    cardYear: '',
    cardCVV: '',
    cardType: '',
    paymentOsToken: '',
};

export const {setCardNumber, setCardMonth, setCardYear, setCardCvv, clearCard, setPaymentOsToken} =
    createActions({
        SET_CARD_NUMBER: (cardNumber: string): ActionType<string> => cardNumber,
        SET_CARD_MONTH: (cardMonth: string): ActionType<string> => cardMonth,
        SET_CARD_YEAR: (cardYear: string): ActionType<string> => cardYear,
        SET_CARD_CVV: (cardCVV: string): ActionType<string> => cardCVV,
        CLEAR_CARD: (): ActionType<> => {},
        SET_PAYMENT_OS_TOKEN: (paymentOsToken: string): ActionType<string> => paymentOsToken,
    });

/**
 * Credit Card reducer
 *
 * @returns {CreditCardType} state - next state
 */
export const creditCard = handleActions(
    {
        [setCardNumber]: (
            state: CreditCardType,
            {payload: cardNumber}: ActionType<typeof setCardNumber>,
        ): CreditCardType => ({
            ...state,
            cardNumber,
            cardType: checkCardType(cardNumber),
        }),
        [setCardMonth]: (
            state: CreditCardType,
            {payload: cardMonth}: ActionType<typeof setCardMonth>,
        ): CreditCardType => ({
            ...state,
            cardMonth,
        }),
        [setCardYear]: (
            state: CreditCardType,
            {payload: cardYear}: ActionType<typeof setCardYear>,
        ): CreditCardType => ({
            ...state,
            cardYear,
        }),
        [setCardCvv]: (
            state: CreditCardType,
            {payload: cardCVV}: ActionType<typeof setCardCvv>,
        ): CreditCardType => ({
            ...state,
            cardCVV,
        }),
        [clearCard]: (): CreditCardType => defaultState,
        [setPaymentOsToken]: (
            state: CreditCardType,
            {payload: paymentOsToken}: ActionType<typeof setPaymentOsToken>,
        ): CreditCardType => ({
            ...state,
            paymentOsToken,
        }),
    },
    defaultState,
);
