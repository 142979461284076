import {MigrationManifest} from 'redux-persist';

import {defaultState as additionalProductsDefaultsState} from 'reducers/additionalProducts';

/**
 * Redux Persist Migrations
 *
 * {@link https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md}
 */
export const migrations: MigrationManifest = {
    0: state => ({...state, contactUsForm: undefined}), // eslint-disable-line no-undefined
    1: state => ({
        ...state,
        additionalProducts: additionalProductsDefaultsState,
    }),
};
