import {createAction, createReducer} from 'redux-act';

import {AppliedFiltersType, AppliedFilterType} from './categoryAppliedFiltersType';

export const defaultState: AppliedFiltersType = {};

export const addAppliedFilter = createAction<string, string, AppliedFilterType>(
    'ADD_APPLIED_FILTER',
    (code: string, value: string) => ({
        code,
        value,
    }),
);
export const removeAppliedFilter = createAction<string, string, AppliedFilterType>(
    'REMOVE_APPLIED_FILTER',
    (code: string, value: string) => ({
        code,
        value,
    }),
);
export const replaceAppliedFilter = createAction<string, string, AppliedFilterType>(
    'CHANGE_APPLIED_FILTER',
    (code: string, value: string) => ({
        code,
        value,
    }),
);
export const setAppliedFilters = createAction<AppliedFiltersType>('SET_APPLIED_FILTERS');
export const clearAppliedFilters = createAction('CLEAR_APPLIED_FILTERS');

/**
 * Category applied filters reducer
 */
export const categoryAppliedFilters = createReducer<AppliedFiltersType>(
    {
        [addAppliedFilter.toString()]: (state, {code, value}) => ({
            ...state,
            [code]: {...state[code], [value]: true},
        }),
        [removeAppliedFilter.toString()]: (state, {code, value}) => ({
            ...state,
            [code]: {
                ...Object.keys(state[code])
                    .filter(key => key !== value)
                    .reduce((obj, key) => ({...obj, [key]: true}), {}),
            },
        }),
        [replaceAppliedFilter.toString()]: (state, {code, value}) => ({
            ...state,
            [code]: {
                [value]: true,
            },
        }),
        [setAppliedFilters.toString()]: (state, filters) => ({
            ...filters,
        }),
        [clearAppliedFilters.toString()]: () => defaultState,
    },
    defaultState,
);
