import {createAction, createReducer} from 'redux-act';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {API_URL_ELASTICSEARCH} from 'constants/api';
import {ENDPOINTS} from 'constants/endpoints';
import {PRODUCT_TYPE} from 'constants/productType';
import {Status} from 'constants/reducerStatus';
import {RequestType} from 'constants/typescript/types';

import {
    ProductType,
    ProductReducerType,
    SelectProductViewType,
    ProductGlassesType,
    ProductViewType,
} from './productType';

export const defaultState: ProductReducerType = {
    item: {
        id: 0,
        url: '',
        brand: null,
        name: '',
        sku: '',
        children: [],
    },
    isCustomizePlano: false,
    selectedType: PRODUCT_TYPE.OPTICAL,
    status: Status.Pending,
    selectedView: '',
    isNonRxSentToCart: false,
};

export const getProduct = createAction<number, RequestType>('GET_PRODUCT', id => ({
    request: {
        method: 'GET',
        baseURL: API_URL_ELASTICSEARCH,
        url: `${ENDPOINTS.CART_PRODUCTS}/${id}`,
    },
}));
export const addSunglassesToCart = createAction<SelectProductViewType, RequestType>(
    'ADD_SUNGLASSES_TO_CART',
    data => ({
        request: {
            method: 'POST',
            headers: {Authorization: ''},
            url: `/backend/${ENDPOINTS.CART_ITEMS}`,
            data,
        },
    }),
);
export const setProduct = createAction<ProductType>('SET_PRODUCT');
export const selectProductType = createAction<ProductGlassesType>('SELECT_PRODUCT_TYPE');
export const selectProductView = createAction<ProductViewType>('SELECT_PRODUCT_VIEW');
export const setCustomizePlano = createAction<boolean>('SET_CUSTOMIZE_PLANO');
export const clearNonRxStatus = createAction('CLEAR_NON_RX_STATUS');

/**
 * Product reducer
 */
export const product = createReducer<ProductReducerType>({}, defaultState)
    .on(getProduct, state => ({
        ...state,
        status: Status.Pending,
    }))
    .on(`${getProduct}${FULFILLED}`, (state, {data}) => ({
        ...state,
        item: data,
        status: Status.Fulfilled,
        selectedView: window.location.hash.replace('#', ''),
        selectedType: data.children[0]?.glassesTypeIds && data.children[0]?.glassesTypeIds[0],
    }))
    .on(`${getProduct}${REJECTED}`, state => ({
        ...state,
        status: Status.Rejected,
    }))
    .on(setProduct, (state, item) => ({
        ...state,
        item,
        selectedType: item.children[0]?.glassesTypeIds && item.children[0]?.glassesTypeIds[0],
        status: Status.Fulfilled,
        selectedView: window.location.hash.replace('#', ''),
        isCustomizePlano: false,
        isNonRxSentToCart: false,
    }))
    .on(selectProductType, (state, selectedType) => ({
        ...state,
        selectedType,
    }))
    .on(selectProductView, (state, selectedView) => ({
        ...state,
        selectedView,
    }))
    .on(setCustomizePlano, (state, payload) => ({
        ...state,
        isCustomizePlano: payload,
    }))
    .on(`${addSunglassesToCart}${FULFILLED}`, state => ({
        ...state,
        isNonRxSentToCart: true,
    }))
    .on(`${clearNonRxStatus}`, state => ({
        ...state,
        isNonRxSentToCart: false,
    }));
