import {pipe, equals, values, all} from 'ramda';

export type FormFieldType = {value: string; disabled?: boolean; success?: boolean};
export type FormType<Fields> = Record<keyof Fields, FormFieldType>;
type ValidationFunction<Fields> = (value: string, form: FormType<Fields>) => string;
type ValidationFunctionVoid = () => '';
type RulesType<Fields> = Record<
    keyof FormType<Fields>,
    ValidationFunction<Fields> | ValidationFunctionVoid
>;

/**
 *  Class for validation form
 */
export class Validation<Fields> {
    form: FormType<Fields>;
    rules: RulesType<Fields>;

    /**
     * Write form and rules
     *
     * @param {FormType} form - from
     * @param {RulesType} rules - rules from fields
     */
    constructor(form: FormType<Fields>, rules: RulesType<Fields>) {
        this.rules = rules;
        this.form = form;
    }

    /**
     * Validation one field form form
     *
     * @param {string} field - name field
     * @returns {string | boolean} - if there are no errors return false
     */
    one = (field: string): string =>
        this.form[field] && this.rules[field](this.form[field].value, this.form);

    /**
     * Validation all fields form form
     *
     * @returns {*} if there are no errors return false
     */
    all = (): Record<keyof FormType<Fields>, string> => {
        const form = Object.keys(this.form) as (keyof FormType<Fields>)[];
        // I have no idea why Record<keyof FormType<Fields> is not accessible to empty object
        // this opportunity allows to watch which fields were validated
        // @ts-ignore
        const result: Record<keyof FormType<Fields>, string> = {};

        form.forEach(value => {
            if (!this.form[value].disabled && this.rules[value]) {
                result[value] = this.rules[value](this.form[value].value, this.form);
            } else {
                result[value] = '';
            }
        });

        return result;
    };
    /**
     * Returns the `true` if all values after validation are equal the passed value.
     * @param {any} validationResult - The value that all fields should correspond to.
     * @returns {boolean} The flag, is all fields are valid.
     */
    allPass = (validationResult): boolean =>
        // @ts-ignore
        pipe(values, all(equals(validationResult)))(this.all());
}
