// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';

import {UPLOAD_OR_SEND_LATER_TAB} from 'constants/wizard/wizard';

import type {PrescriptionOptionType} from './prescriptionOptionsType';

export const defaultState = {
    activePrescriptionTab: UPLOAD_OR_SEND_LATER_TAB,
};

export const {
    wizard: {setActivePrescription, clearActivePrescription},
} = createActions({
    WIZARD: {
        SET_ACTIVE_PRESCRIPTION: (id: number): ActionType<number> => id,
        CLEAR_ACTIVE_PRESCRIPTION: () => {},
    },
});

export const prescriptionOptions = handleActions(
    {
        [setActivePrescription]: (
            state: PrescriptionOptionType,
            {payload}: ActionType<number>,
        ): PrescriptionOptionType => ({
            ...state,
            activePrescriptionTab: payload,
        }),
        [clearActivePrescription]: () => defaultState,
    },
    defaultState,
);
