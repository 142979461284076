import React, {FC} from 'react';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {CartBtnConnected} from 'components/__helpers/header/cartBtn/cartBtn';
import {Icon} from 'components/__helpers/icon/icon';
import {LINK_PAGE} from 'constants/links';
import {ApplicationStoreType} from 'constants/typescript/types';

import s from './checkoutMenu.scss';

type PropsType = {
    phones: {
        default: string;
    };
};

const goBack = () => window.history.back();

/**
 * Component CheckoutMenu
 *
 * @returns {FC} returns func component
 */
export const CheckoutMenu: FC<PropsType> = ({phones}) => {
    /**
     * Render jsx to html
     *
     * @returns {Node} Rendered react component
     */
    return (
        <div className={s.container}>
            <div className={s.secureContainer}>
                <div className={s.arrowLeft} onClick={goBack}>
                    <Icon name="arrowLeft" />
                </div>
                <Link className={s.cartBtn} to={LINK_PAGE.CART}>
                    <CartBtnConnected className={s.stickyCartBtn} />
                </Link>
                <div className={s.title}>
                    <Icon name="simplelock" />
                    <p>Secure Checkout</p>
                </div>
            </div>

            <div className={s.buttons}>
                <span className={s.phoneIcon}>
                    <Icon name="phone" />
                </span>
                <span className={s.accessHours}>Call us (9 a.m. - 9 p.m. monday to friday)</span>
                <button className={s.phoneNumber}>
                    <a href={`tel:${phones.default}`}>{phones.default}</a>
                </button>
            </div>
        </div>
    );
};

export const mapStateToProps = (store: ApplicationStoreType) => ({
    phones: store.storeInfo.phones,
});

export const CheckoutMenuConnected = connect(mapStateToProps)(CheckoutMenu);
