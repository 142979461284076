import {createSelector} from 'reselect';

import {ApplicationStoreType, Maybe} from 'constants/typescript/types';
import {NON_PRESCRIPTION, PRESCRIPTION_UPLOAD} from 'constants/wizard/wizard';
import {
    getConfigure,
    ConfigureType,
    isConfigurePage,
} from 'features/wizard/store/reducers/configure/configure';
import {getCustomerConfiguration} from 'features/wizard/store/reducers/customerConfiguration/customerConfiguration';
import {CustomerConfigurationType} from 'features/wizard/store/reducers/customerConfiguration/customerConfigurationType';
import {PrescriptionType} from 'features/wizard/store/reducers/prescription/prescriptionTypes';
import {getActiveUsage, UsageItemType} from 'features/wizard/store/reducers/usages/usages';
import {getPrescription} from 'features/wizard/store/selectors/prescription/prescription';
import {AddToCartPrescription, AddToCartType} from 'reducers/cart/addToCart/addToCartType';
import {ProductChildrenType} from 'reducers/product';
import {getActiveProduct, isPlanoSunglasses} from 'selectors/product/product';

/**
 * Returns quote id item to update cart
 */
export const getQuoteItemId = createSelector<
    ApplicationStoreType,
    boolean,
    ConfigureType,
    Maybe<{quoteItemId: number}>
>(isConfigurePage, getConfigure, (isConfigure, configureData) =>
    isConfigure && configureData.item ? {quoteItemId: configureData.item.id} : null,
);

/**
 * Provides data from product section needs for add to cart
 */
export const getProductData = createSelector<
    ApplicationStoreType,
    ProductChildrenType,
    Maybe<{quoteItemId: number}>,
    {productId: number; coupon: string; quoteItemId?: number}
>(getActiveProduct, getQuoteItemId, (activeProduct, quoteItemData) => ({
    ...quoteItemData,
    productId: activeProduct.id,
    coupon: '',
}));

/**
 * Provides all data related to wizard
 */
export const getWizardData = createSelector<
    ApplicationStoreType,
    CustomerConfigurationType,
    boolean,
    Omit<AddToCartType, 'productId'>
>(getCustomerConfiguration, isPlanoSunglasses, (customerConfiguration, isSunglassesFrame) => ({
    ...(isSunglassesFrame ? {} : {options: customerConfiguration.options}),
    quantity: customerConfiguration.quantity,
}));

/**
 * Determines when the "prescription" field is needed to add to cart
 */
export const isPrescriptionFieldNeeded = createSelector<
    ApplicationStoreType,
    Maybe<UsageItemType>,
    boolean,
    boolean
>(
    getActiveUsage,
    isPlanoSunglasses,
    (usage, isPlanoSunglasses) =>
        Boolean(usage && usage.sku !== NON_PRESCRIPTION) && !isPlanoSunglasses,
);

/**
 * Returns:
 * for fill it online: prescription values in prescription fields
 * for upload : 3 fields with file src, name and something else
 * for send later: nothing
 */
export const getPreparedPrescriptionForAddToCart = createSelector<
    ApplicationStoreType,
    {values: PrescriptionType},
    Maybe<UsageItemType>,
    boolean,
    Maybe<{prescription: AddToCartPrescription}>
>(
    getPrescription,
    getActiveUsage,
    isPrescriptionFieldNeeded,
    ({values: prescription}, selectedUsage, isPrescriptionNeeded) => {
        if (
            !selectedUsage ||
            (selectedUsage && selectedUsage.sku === NON_PRESCRIPTION) ||
            !isPrescriptionNeeded
        )
            return null;
        return {
            prescription: {
                ...prescription,
                file: prescription.method === PRESCRIPTION_UPLOAD ? prescription.file : '',
                pd: {
                    ...(prescription.isMultiPD
                        ? {
                              left: prescription.pd.left,
                              right: prescription.pd.right,
                          }
                        : {
                              singular: prescription.pd.singular,
                          }),
                    near: prescription.pd.near,
                },
            },
        };
    },
);

/**
 * Returns the final result of combine data to add or update cart
 */
export const getAddAndUpdateCartData = createSelector<
    ApplicationStoreType,
    {productId: number; coupon: string},
    Omit<AddToCartType, 'productId'>,
    Maybe<{prescription: AddToCartPrescription}>,
    AddToCartType
>(
    getProductData,
    getWizardData,
    getPreparedPrescriptionForAddToCart,
    (productData, wizardData, prescription) => ({
        ...productData,
        ...wizardData,
        ...prescription,
    }),
);
