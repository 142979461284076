import React from 'react';

export const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="39" fill="none">
        <path
            fill="#032677"
            d="m15.156 37.236-.355.935.355.135.354-.134-.354-.935Zm14-28.804h1v-.64l-.582-.268-.418.908ZM15.156 2l.418-.909L15.156.9l-.417.192.417.909Zm-14 6.432L.74 7.524l-.583.267v.641h1Zm0 9.791h-1v.003l1-.003Zm20.353-2.277a1 1 0 0 0-1.532-1.286l1.532 1.286Zm-7.237 7.07-.643.766a1 1 0 0 0 1.409-.124l-.766-.643Zm-3.214-4.002a1 1 0 0 0-1.285 1.532l1.285-1.532Zm4.098 18.223c.354.935.355.935.355.934h.001l.004-.001.01-.005.037-.014.13-.052c.112-.046.272-.114.474-.204.403-.181.972-.453 1.653-.821a28.117 28.117 0 0 0 4.992-3.446c3.638-3.158 7.344-8.165 7.344-15.405h-2c0 6.476-3.293 10.976-6.655 13.895a26.115 26.115 0 0 1-4.634 3.197c-.631.342-1.156.592-1.518.755a14.178 14.178 0 0 1-.52.22l-.023.01-.004.001.354.935Zm15-19.014v-9.79h-2v9.79h2Zm-.582-10.7-14-6.432-.835 1.818 14 6.432.835-1.817ZM14.739 1.092l-14 6.433.835 1.817 14-6.432-.835-1.818ZM.156 8.432v9.791h2v-9.79h-2Zm0 9.794c.025 7.238 3.73 12.244 7.362 15.402a28.115 28.115 0 0 0 4.98 3.445c.679.369 1.247.64 1.648.821a16.58 16.58 0 0 0 .602.257l.037.014.01.004.004.002h.001l.356-.934.356-.935-.005-.002-.023-.009a11.461 11.461 0 0 1-.517-.22 22.797 22.797 0 0 1-1.514-.755 26.111 26.111 0 0 1-4.623-3.197c-3.356-2.92-6.651-7.421-6.674-13.9l-2 .007Zm19.82-3.566-6.47 7.713 1.532 1.285 6.471-7.712-1.532-1.286Zm-5.062 7.59-3.856-3.236-1.285 1.532 3.856 3.236 1.285-1.533Z"
        />
    </svg>
);
