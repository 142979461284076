import {PROGRESSIVE, BIFOCAL} from 'constants/wizard/wizard';
import {FormFieldType, FormType} from 'libs/validation/validation';

/**
 * Checks current usage available for add field
 *
 * @param {string} usage current usage
 * @returns {boolean} - flag
 */
export const isUsageWithAddField = (usage: string): boolean =>
    [PROGRESSIVE, BIFOCAL].includes(usage);

export type PrescriptionValidationRulesType = {
    sph_od: () => string;
    sph_os: () => string;
    cyl_od: () => string;
    cyl_os: () => string;
    axi_od: (
        value: string,
        fields: FormType<Record<keyof PrescriptionValidationRulesType, FormFieldType>>,
    ) => string;
    axi_os: (
        value: string,
        fields: FormType<Record<keyof PrescriptionValidationRulesType, FormFieldType>>,
    ) => string;
    add_od: (
        value: string,
        fields: FormType<Record<keyof PrescriptionValidationRulesType, FormFieldType>>,
    ) => string;
    add_os: (
        value: string,
        fields: FormType<Record<keyof PrescriptionValidationRulesType, FormFieldType>>,
    ) => string;
    usage: () => string;
};

export const rules: PrescriptionValidationRulesType = {
    axi_od: (
        value: string,
        fields: FormType<Record<keyof PrescriptionValidationRulesType, FormFieldType>>,
    ): string => {
        if (
            fields.cyl_od &&
            Boolean(parseFloat(fields.cyl_od.value)) &&
            (!value || value === 'None')
        ) {
            return 'Please select';
        }
        return '';
    },

    axi_os: (
        value: string,
        fields: FormType<Record<keyof PrescriptionValidationRulesType, FormFieldType>>,
    ): string => {
        if (
            fields.cyl_os &&
            Boolean(parseFloat(fields.cyl_os.value)) &&
            (!value || value === 'None')
        ) {
            return 'Please select';
        }
        return '';
    },
    cyl_od: () => '',
    cyl_os: () => '',
    add_od: (
        value: string,
        fields: FormType<Record<keyof PrescriptionValidationRulesType, FormFieldType>>,
    ): string => {
        if (isUsageWithAddField(fields.usage.value) && (!value || value === 'None')) {
            return 'Please select';
        }
        return '';
    },
    add_os: (
        value: string,
        fields: FormType<Record<keyof PrescriptionValidationRulesType, FormFieldType>>,
    ): string => {
        if (isUsageWithAddField(fields.usage.value) && (!value || value === 'None')) {
            return 'Please select';
        }
        return '';
    },
    usage: () => '',
    sph_od: () => '',
    sph_os: () => '',
};
