import {all} from 'redux-saga/effects';

import {addressSaga} from './address/address';
import {analyticsSaga, analyticsSecondSaga} from './analytics/analytics';
import {appSaga} from './app/app';
import {cartSaga} from './cart/cart';
import {categorySaga} from './category/category';
import {checkoutSaga} from './checkout/checkout';
import {contactUsSaga} from './contactUs/contactUs';
import {editAddressSaga} from './editAddress/editAddress';
import {newPasswordSaga} from './newPassword/newPassword';
import {oauth2Saga} from './oauth2/oauth2';
import {ordersSaga} from './orders/orders';
import {prescriptionSaga} from './prescription/prescription';
import {productSaga} from './product/product';
import {tryOnSaga} from './tryOn/tryOn';
import {wishListSaga} from './wishList/wishList';
import wizardSaga from './wizard/wizard';
import {prescriptionSagaMS} from '../features/wizard/sagas/prescription/prescription';

/**
 * RootSaga
 */
export default function* rootSaga() {
    yield all([
        appSaga(),
        analyticsSaga(),
        analyticsSecondSaga(),
        wizardSaga(),
        oauth2Saga(),
        categorySaga(),
        productSaga(),
        wishListSaga(),
        cartSaga(),
        checkoutSaga(),
        addressSaga(),
        ordersSaga(),
        prescriptionSaga(),
        prescriptionSagaMS(),
        contactUsSaga(),
        tryOnSaga(),
        newPasswordSaga(),
        editAddressSaga(),
    ]);
}
