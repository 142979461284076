import '@babel/polyfill';
import React, {useEffect} from 'react';

import focusWithin from 'focus-within';
import {init, reactRouterV6BrowserTracingIntegration, setUser} from '@sentry/react';
import 'url-search-params-polyfill';
import {SnackBarsContainer, Theme} from '@optimaxdev/design-system-u';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import createAuthRefreshInterceptor, {AxiosAuthRefreshRequestConfig} from 'axios-auth-refresh';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';

import {FeatureProvider} from 'components/feature/featureProvider';
import {API_URL_CUSTOMER} from 'constants/api';
import {CLIENT_ID_UHC, CLIENT_ID} from 'constants/clientId';
import {addAdaChat} from 'libs/chat';
import configureStore, {axiosClient} from 'libs/configureStore';
import {setCustomProperties} from 'libs/rolloutConfiguration/rolloutConfiguration';
import {setToken, logOut} from 'reducers/oauth2/oauth2Actions';
import {Router} from 'router/router';

import '../css/common.scss';
import {PopperContainer} from './components/popperContainer/popperContainer';

export const {store, persistor} = configureStore();

// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('es6-promise').polyfill();
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('array.prototype.find').shim();
require('custom-event-polyfill');

focusWithin(document);

createAuthRefreshInterceptor(
    axiosClient,
    () => {
        const {refresh_token: refreshToken} = store.getState().oauth2Data;
        if (refreshToken) {
            return axiosClient('token', {
                method: 'POST',
                baseURL: API_URL_CUSTOMER,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data: `grant_type=refresh_token&client_id=${CLIENT_ID_UHC}&refresh_token=${refreshToken}`,
                skipAuthRefresh: true,
            } as AxiosAuthRefreshRequestConfig)
                .then(({data}) => {
                    store.dispatch(setToken(data));
                    return Promise.resolve();
                })
                .catch(err => {
                    store.dispatch(logOut());
                    // eslint-disable-next-line no-console
                    console.error(err);
                });
        }
        return Promise.resolve();
    },
    {
        pauseInstanceWhileRefreshing: true,
    },
);

// set custom properties and call initRollout
setCustomProperties({
    origin: window.location.origin,
});

addAdaChat();

init({
    dsn: 'https://4b43375c3a594cb6958401c44f68667b@o1308828.ingest.sentry.io/6569885',
    ignoreErrors: ['The quota has been exceeded.'],
    sampleRate: 0.1,
    tracesSampleRate: 0.1,
    normalizeDepth: 6,
    environment: process.env.APP_ENV,
    release: process.env.RELEASE_VERSION,
    integrations: [
        reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
});

setUser({id: CLIENT_ID});

const queryClient = new QueryClient();

const container = document.getElementById('react-root') as HTMLElement;
const root = createRoot(container);

root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store as never}>
            <FeatureProvider>
                <PersistGate loading={null} persistor={persistor}>
                    <Theme theme={{project: 'uhc', platform: 'desktop'}}>
                        <Router />
                        <SnackBarsContainer />
                        <PopperContainer />
                    </Theme>
                </PersistGate>
            </FeatureProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
);
