import {useEffect} from 'react';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {isUserLoggedIn} from 'selectors/oauth2Data/oauth2Data';

/*
 * Navigate to 'redirectTo' value if enabled value isn't false
 * Return logged in status, if enabled is false return always true
 */
export const useRedirectUnauthorised = (redirectTo: string, enabled: boolean = true): boolean => {
    const isLoggedIn = useSelector(isUserLoggedIn);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn && enabled) navigate(redirectTo, {replace: true});
    }, [enabled, isLoggedIn, navigate, redirectTo]);

    return enabled ? isLoggedIn : true;
};
