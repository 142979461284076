import React, {FC} from 'react';

import classNames from 'classnames';

import {Price} from 'components/__helpers/price/price';
import flags from 'constants/flags/flags';
import {ALLOWANCE_TYPE} from 'constants/plan';
import {BenefitType} from 'libs/hooks/usePlanRequest';

import {PlanAllowanceItem} from './planAllowanceItem';
import {PlanAllowanceTitle} from './planAllowanceTitle';

import s from './planAllowanceRow.scss';

export type PropsType = {
    frameAllowance: BenefitType | null;
    frameAllowanceForCL?: BenefitType | null;
};
const getIsFrameShown = (
    frameAllowance: BenefitType | null,
    frameAllowanceForCL: BenefitType | null | undefined,
) =>
    frameAllowance &&
    ((frameAllowanceForCL && frameAllowanceForCL?.allowance >= 0 && frameAllowance.allowance > 0) ||
        (frameAllowance.allowance === 0 &&
            frameAllowanceForCL &&
            frameAllowanceForCL.allowance === 0) ||
        !frameAllowanceForCL);
const getIsContactsShown = (frameAllowanceForCL, frameAllowance) =>
    frameAllowanceForCL && (frameAllowanceForCL?.allowance > 0 || !frameAllowance);
const getIsAllowanceContacts = (isUhcCcl, flatAllowance, frameAllowanceForCL): boolean =>
    isUhcCcl && !flatAllowance && frameAllowanceForCL && frameAllowanceForCL.allowance > 0;

/**
 * Row for a user's plan
 */
export const PlanAllowanceRow: FC<PropsType> = ({frameAllowance, frameAllowanceForCL}) => {
    const isUHCCL = flags.dUHCCL.isEnabled();

    const isFrameShown = getIsFrameShown(frameAllowance, frameAllowanceForCL);

    const isContactsShown = getIsContactsShown(frameAllowanceForCL, frameAllowance);

    const flatAllowance = frameAllowance?.description === ALLOWANCE_TYPE.FLAT;
    const isAllowanceContacts = getIsAllowanceContacts(isUHCCL, flatAllowance, frameAllowanceForCL);
    return (
        <div className={s.wrapper}>
            <PlanAllowanceTitle
                flatAllowance={flatAllowance}
                frameAllowanceForCL={frameAllowanceForCL}
            />
            <div
                className={classNames(s.tableItem, {
                    [s.allowanceItem]: flatAllowance,
                    [s.itemContacts]: isAllowanceContacts,
                })}
            >
                {flatAllowance ? (
                    <>
                        <p className={s.flatRateTitle}>
                            {isUHCCL
                                ? 'Any frames, lenses or contact lenses'
                                : 'Any frame or lenses'}
                        </p>
                        <p className={s.flatRatePrice}>
                            <Price>{frameAllowance.allowance}</Price>
                        </p>
                    </>
                ) : (
                    frameAllowance &&
                    isFrameShown && (
                        <PlanAllowanceItem
                            copay={frameAllowance.copay}
                            allowance={frameAllowance.allowance}
                        />
                    )
                )}
            </div>
            {isUHCCL && isContactsShown && frameAllowanceForCL && (
                <div
                    className={classNames(s.tableItem, {
                        [s.itemContactsWithDivider]: isAllowanceContacts,
                    })}
                >
                    <PlanAllowanceItem
                        isFrameAllowance={Boolean(frameAllowance)}
                        isFrameAllowanceForCL={Boolean(frameAllowanceForCL)}
                        isFrameAllowanceZero={frameAllowance?.allowance === 0}
                        copay={frameAllowanceForCL.copay}
                        allowance={frameAllowanceForCL.allowance}
                        frameTypeTitle="contact lenses"
                    />
                </div>
            )}
        </div>
    );
};
