/* istanbul ignore file */
import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {selectedBoxOption} from './boxOption/boxOption';
import {prescription} from './prescription/prescription';
import {prescriptionValues} from './prescriptionValues/prescriptionValues';
import {savePrescriptionApproval} from './savePrescriptionApproval/savePrescriptionApproval';
import {successPage} from './successPage/successPage';
import {wizard} from './wizard/wizard';
import {wizardStep} from './wizardStep/wizardStep';

export const reducers = {
    wizardStep: persistReducer({key: 'wizardStep', storage}, wizardStep),
    prescription: persistReducer({key: 'prescriptionCL', storage}, prescription),
    prescriptionValues: persistReducer({key: 'prescriptionValues', storage}, prescriptionValues),
    savePrescriptionApproval: persistReducer(
        {key: 'savePrescriptionApproval', storage},
        savePrescriptionApproval,
    ),
    wizard,
    successPage,
    selectedBoxOption,
};

export const contacts = combineReducers(reducers);
