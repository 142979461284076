import {createEECCheckoutStepEvent, pushDataLayer} from '@optimaxdev/analytics/desktop';
import {SagaIterator} from 'redux-saga';
import {select, all, call} from 'redux-saga/effects';

import {prepareCheckoutProductData} from 'libs/analytics/eec';
import {CHECKOUT_STEPS} from 'reducers/order/order';
import {getCartItems} from 'selectors/cart/cart';
import {getCategoryMap, getOrderData} from 'selectors/order/order';

/**
 * Prepares and sends checkout data
 * into dataLayer
 * */
export function* checkoutEECSaga(): SagaIterator {
    const {
        order: {isPaymentStep, currentStep: step},
        cartItems,
        categoryMap,
    } = yield all({
        order: select(getOrderData),
        cartItems: select(getCartItems),
        categoryMap: select(getCategoryMap),
    });

    if (!cartItems.length) return;

    const products = yield call(prepareCheckoutProductData, cartItems, categoryMap);

    yield call(
        pushDataLayer,
        createEECCheckoutStepEvent({
            step,
            products,
        }),
    );

    // B/c review order is not standalone step
    // data about review order step
    // should be sent on paymentStep
    if (isPaymentStep) {
        yield call(
            pushDataLayer,
            createEECCheckoutStepEvent({
                step: CHECKOUT_STEPS.Review,
                products,
            }),
        );
    }
}
