import {isEmpty} from 'ramda';
import {SagaIterator} from 'redux-saga';
import {select, put, call, cancel, takeLatest, all} from 'redux-saga/effects';

import {PdErrorType, setErrors} from 'features/wizard/store/reducers/prescription/errors/errors';
import {validatePrescription} from 'features/wizard/store/reducers/prescription/prescription';
import {isFashionUsage} from 'features/wizard/store/reducers/usages/usages';
import {getAddAndUpdateCartData} from 'features/wizard/store/selectors/addToCart/addToCart';
import {
    getPrescription,
    getPrescriptionValidateResult,
} from 'features/wizard/store/selectors/prescription/prescription';
import {isEyePrescriptionEmpty} from 'features/wizard/utils/prescriptionValidation/prescriptionValidation';

import {PRODUCT_TYPES} from '../../constants/product/product';
import {FILL_IT_ONLINE} from '../../constants/wizard/wizard';
import {ProductChildrenType} from '../../reducers/product';
import {AddToCartType} from '../../reducers/product/productType';
import {getActiveProduct} from '../../selectors/product/product';

const CONFIRM_MESSAGE = 'You have not provided a prescription. Proceed to checkout anyway?';

/**
 * Scrolls to the prescription step and cancels adding to cart process if the prescription filled incorrectly
 *
 */
export function* validatePrescriptionStep() {
    const validation: ReturnType<typeof getPrescriptionValidateResult> = yield select(
        getPrescriptionValidateResult,
    );
    const prescriptionValues: ReturnType<typeof getPrescription> = yield select(getPrescription);
    if (validation.isPrescriptionValid || prescriptionValues.values.method !== FILL_IT_ONLINE)
        return;

    yield put(
        setErrors({
            od: {
                ...prescriptionValues.errors.od,
                axi: validation.errors.axi_od,
                add: validation.errors.add_od,
            },
            os: {
                ...prescriptionValues.errors.os,
                axi: validation.errors.axi_os,
                add: validation.errors.add_os,
            },
            pd: {...prescriptionValues.errors.pd} as PdErrorType,
        }),
    );
    yield cancel();
}

/**
 * if prescription empty we show alert
 * if user cancel this alert he will go to prescription step
 */
export function* confirmEmptyPrescription() {
    const [prescriptionValues, isFashion]: [ReturnType<typeof getPrescription>, boolean] =
        yield all([select(getPrescription), select(isFashionUsage)]);

    const isPrescriptionEmpty =
        isEyePrescriptionEmpty(prescriptionValues.values.od) &&
        isEyePrescriptionEmpty(prescriptionValues.values.os);

    if (isPrescriptionEmpty && prescriptionValues.values.method === FILL_IT_ONLINE && !isFashion) {
        const isConfirmed = window.confirm(CONFIRM_MESSAGE);
        if (!isConfirmed) {
            yield cancel();
        }
    }
}

/**
 * Validates customer's configuration before adding an item to cart
 *
 * @param {string} labelFromAddToCartButton - label from button
 * @param {boolean} isFromBottomButton - label from button
 */
export function* validateWizardConfiguration(
    labelFromAddToCartButton: string,
    isFromBottomButton: boolean,
) {
    const [product, {options = {}}]: [ProductChildrenType, AddToCartType] = yield all([
        select(getActiveProduct),
        select(getAddAndUpdateCartData),
    ]);
    if (isEmpty(options) && product.type === PRODUCT_TYPES.SUNGLASSES && !isFromBottomButton)
        return;
    yield call(validatePrescriptionStep);
    yield call(confirmEmptyPrescription);
}

/**
 * Root
 */
export function* wizardValidation(): SagaIterator {
    /**
     * This function is checking before set any option before customer sets usage
     * for example:
     * if we try to set prescription field sph - we should cancel this action, highlight the usage step with error
     */
    yield takeLatest(`${validatePrescription}`, validatePrescriptionStep);
}
