import {HARDCODED_PRISM_PRICE} from 'constants/wizard/wizard';

export const PrescriptionSchemeWithoutValuesFixture = {
    id: 100500,
    name: 'name',
    comment: 'comment',
    isPrism: false,
    pricePrism: HARDCODED_PRISM_PRICE,
    pd: {
        singular: '64',
        right: '',
        left: '',
        near: '60',
    },
    od: {
        sph: '-11.50',
        cyl: '+1.50',
        axi: '180',
        add: '',
    },
    os: {
        sph: '-7.25',
        cyl: '+1.25',
        axi: '4',
        add: '',
    },
};

export const PrescriptionSchemeFixture = {
    id: 100500,
    name: 'name',
    comment: 'comment',
    pd: {
        singular: {
            value: 'pd',
        },
        right: {
            value: 'right',
            isError: false,
        },
        left: {
            value: 'left',
            isError: false,
        },
        near: {
            value: 'value',
        },
        isTwoPd: {
            value: false,
        },
    },
    od: {
        sph: {value: '-11.50', isDisable: false},
        cyl: {value: '-1.50', isDisable: false},
        axi: {value: '180', isDisable: false},
        add: {value: '', isDisable: false},
    },
    os: {
        sph: {value: '-7.25', isDisable: false},
        cyl: {value: '-1.25', isDisable: false},
        axi: {value: '4', isDisable: false},
        add: {value: '', isDisable: false},
    },
};

export const SavedPrescriptionSchemeFixture = [PrescriptionSchemeFixture];
