/* eslint-disable max-statements,complexity,max-lines */
import React from 'react';

import {pushSnackSuccess, clearAllSnack, pushSnackAlert} from '@optimaxdev/design-system-u';
import {pathOr} from 'ramda';
import {SagaIterator, channel} from 'redux-saga';
import {select, put, takeEvery, all, call, putResolve, take} from 'redux-saga/effects';

import {CartItemSnackbar} from 'components/cart/cartItem/cartItemSnackbar/cartItemSnackbar';
import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {ActionType} from 'constants/typescript/types';
import {updateCartClItem} from 'features/contacts/reducers/addToCart/addToCart';
import {cancelActionRequest} from 'libs/middleware/cancellationMiddleware';
import {
    getCartItems,
    getCartTotals,
    removeCartItem,
    changeProductInCart,
    clearCart,
    setAllowance,
    addToCartFake,
    removeAllowance,
} from 'reducers/cart';
import {getMyPlan} from 'reducers/myPlan';
import {setPage} from 'reducers/route';
import {saveShippingMethod} from 'reducers/shipping';
import {getUserDiscount, getUser} from 'reducers/user';
import {getCartItemWithAppliedBenefit, getIsCartLoading} from 'selectors/cart/cart';
import {getPageData} from 'selectors/page/getPageData';
import {getShippingData} from 'selectors/shipping/shipping';

import {updateCartItem} from '../../reducers/cart/cart';
import {applyCoupon, removeCoupon} from '../../reducers/storeCredit';

const DEFAULT_ERROR_TEXT = 'Try again, or contact us at 1-844-244-1179, 9AM to 9AM, Mon-Fri';

/**
 * Fetch cart data
 */
export function* getData(): SagaIterator {
    const {page}: ReturnType<typeof getPageData> = yield select(getPageData);
    const isLoading: ReturnType<typeof getIsCartLoading> = yield select(getIsCartLoading);

    yield put(getCartItems(false));

    if (page !== 'cart') return;

    if (isLoading) {
        yield put(cancelActionRequest(String(getCartItems), String(getCartTotals)));
    }

    yield put(getCartTotals(false));
}

export function* updateUserDiscount(): SagaIterator {
    yield put(getMyPlan());
}

/**
 * Fetch user data & discounts
 */
export function* updateUserData(): SagaIterator {
    yield all([put(getUser()), put(getUserDiscount())]);
}

/**
 * Update shipping method
 */
export function* updateShippingMethod(): SagaIterator {
    const {page, prevPage}: ReturnType<typeof getPageData> = yield select(getPageData);
    const {selectedMethod}: ReturnType<typeof getShippingData> = yield select(getShippingData);

    if (prevPage.page === 'checkout' && page !== 'success') {
        yield putResolve(saveShippingMethod(selectedMethod));
    }
}

/**
 *  Show snackbar after successful setting allowance
 */
export function* showSnackbar(): SagaIterator {
    yield call(clearAllSnack);
    const prevCartItem = yield select(getCartItemWithAppliedBenefit);
    const {id: prevId = null, name: prevName = ''} = prevCartItem || {};

    // wait new data
    yield take(`${getCartItems}${FULFILLED}`);

    const currentItem = yield select(getCartItemWithAppliedBenefit);
    const {id: currId, name: currentName = ''} = currentItem || {};

    if (currId && prevId) {
        yield call(
            pushSnackSuccess,
            '',
            <CartItemSnackbar prevName={prevName} currentName={currentName} isRemove={false} />,
            prevId
                ? {
                      onUndo: () => {
                          allowanceChannel.put(setAllowance(prevId));
                      },
                  }
                : {},
        );
    }

    if (!prevId && currId) {
        yield call(
            pushSnackSuccess,
            '',
            <CartItemSnackbar prevName={prevName} currentName={currentName} isRemove={false} />,
            currId
                ? {
                      onUndo: () => {
                          allowanceChannel.put(removeAllowance(currId));
                      },
                  }
                : {},
        );
    }

    if (prevId && !currId) {
        yield call(
            pushSnackSuccess,
            '',
            <CartItemSnackbar prevName={prevName} currentName={currentName} isRemove />,
            prevId
                ? {
                      onUndo: () => {
                          allowanceChannel.put(setAllowance(prevId));
                      },
                  }
                : {},
        );
    }
}

/**
 *  Shows snackbar in case of an error
 *
 *  @param {ActionType} action action
 */
export function* showErrorSnackbar(action: ActionType): SagaIterator {
    yield call(clearAllSnack);
    const backendErrorMessage = pathOr(
        '',
        ['payload', 'error', 'response', 'data', 'messages', 'error', '0', 'message'],
        action,
    );

    const errorMessage = backendErrorMessage
        ? `${backendErrorMessage}. ${DEFAULT_ERROR_TEXT}`
        : DEFAULT_ERROR_TEXT;

    yield call(pushSnackAlert, '', `${errorMessage}`);
}

export const allowanceChannel = channel();

/**
 * Watcher for allowance channel
 *
 * @param {ActionType} action - action
 */
export function* watchAllowanceChannel(action): SagaIterator {
    yield put(action);
}

/**
 * Begin of saga
 */
export function* cartSaga(): SagaIterator {
    yield takeEvery(
        [
            setPage,
            `${addToCartFake}`,
            `${updateCartClItem}${FULFILLED}`,
            `${removeCartItem}${FULFILLED}`,
            `${removeCartItem}${REJECTED}`,
            `${changeProductInCart}${FULFILLED}`,
            `${saveShippingMethod}${FULFILLED}`,
            `${setAllowance}${FULFILLED}`,
            `${setAllowance}${REJECTED}`,
            `${removeAllowance}${FULFILLED}`,
            `${removeAllowance}${REJECTED}`,
            `${applyCoupon}${FULFILLED}`,
            `${removeCoupon}${FULFILLED}`,
            updateCartItem,
        ],
        getData,
    );
    yield takeEvery(
        [`${applyCoupon}${FULFILLED}`, `${removeCoupon}${FULFILLED}`],
        updateUserDiscount,
    );
    yield takeEvery(
        [
            `${addToCartFake}`,
            `${removeCartItem}${FULFILLED}`,
            `${removeCartItem}${REJECTED}`,
            `${clearCart}${FULFILLED}`,
        ],
        updateUserData,
    );
    yield takeEvery(setPage, updateShippingMethod);
    yield takeEvery(`${setAllowance}${FULFILLED}`, showSnackbar);
    yield takeEvery(`${removeAllowance}${FULFILLED}`, showSnackbar);
    yield takeEvery(`${setAllowance}${REJECTED}`, showErrorSnackbar);
    yield takeEvery(`${removeAllowance}${REJECTED}`, showErrorSnackbar);
    yield takeEvery(allowanceChannel, watchAllowanceChannel);
}
