import {createAction, createReducer} from 'redux-act';

import {UPLOAD_SCREEN} from 'features/contacts/reducers/successPage/successPage';

export const setScreen = createAction<string>('SET_SCREEN', (screen: string) => screen);

export const defaultState = {
    screen: UPLOAD_SCREEN,
};

/**
 * Screen reducer
 *
 * @returns {screen}
 */
export const screenReducer = createReducer(
    {
        [setScreen.toString()]: (state, screen: string) => ({
            ...state,
            screen,
        }),
    },
    defaultState,
);
