// @flow
import type {AxiosResponse} from 'axios';
import {createActions, handleActions, combineActions, type ActionType} from 'redux-actions';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {API_URL_CUSTOMER} from 'constants/api';
import {ENDPOINTS} from 'constants/endpoints';

import type {PrescriptionType, PrescriptionTypeReducerType} from './prescriptionType';
import {Status} from '../../constants/reducerStatus';

export const defaultState: PrescriptionTypeReducerType = {
    list: [],
    isLoading: false,
    status: Status.Idle,
};

export const {
    getPrescriptions,
    createPrescription,
    updatePrescription,
    deletePrescription,
    clearPrescriptions,
} = createActions({
    GET_PRESCRIPTIONS: (): ActionType<AxiosResponse> => ({
        request: {
            headers: {Authorization: ''},
            baseURL: API_URL_CUSTOMER,
            method: 'GET',
            url: ENDPOINTS.PRESCRIPTION,
        },
    }),
    CREATE_PRESCRIPTION: (data: PrescriptionType): ActionType<AxiosResponse> => ({
        request: {
            headers: {Authorization: ''},
            method: 'POST',
            baseURL: API_URL_CUSTOMER,
            url: ENDPOINTS.PRESCRIPTION,
            data,
        },
    }),
    UPDATE_PRESCRIPTION: (data: PrescriptionType): ActionType<AxiosResponse> => ({
        request: {
            headers: {Authorization: ''},
            method: 'PUT',
            baseURL: API_URL_CUSTOMER,
            url: `${ENDPOINTS.PRESCRIPTION}/${data.id}`,
            data,
        },
    }),
    DELETE_PRESCRIPTION: (id: number): ActionType<AxiosResponse> => ({
        request: {
            headers: {Authorization: ''},
            baseURL: API_URL_CUSTOMER,
            method: 'DELETE',
            url: `${ENDPOINTS.PRESCRIPTION}/${id}`,
        },
    }),
    CLEAR_PRESCRIPTIONS: (): ActionType<> => {},
});

export const prescriptionFulfilledActions = combineActions(
    `${createPrescription}${FULFILLED}`,
    `${updatePrescription}${FULFILLED}`,
    `${deletePrescription}${FULFILLED}`,
);

export const prescriptionPendingActions = combineActions(
    createPrescription,
    updatePrescription,
    deletePrescription,
);

export const prescriptionErrorActions = combineActions(
    `${createPrescription}${REJECTED}`,
    `${updatePrescription}${REJECTED}`,
    `${deletePrescription}${REJECTED}`,
);

/**
 * Prescription reducer
 *
 * @returns {PrescriptionTypeReducerType} state - next state
 */
export const prescription = handleActions(
    {
        [`${getPrescriptions}${FULFILLED}`]: (
            state: PrescriptionType,
            {payload}: ActionType<typeof getPrescriptions>,
        ): PrescriptionType => ({
            ...state,
            list: payload.data,
            isLoading: false,
            // The status is set to IDLE because the "getPrescription" usually gets dispatched after some action with the prescription (update, delete, add).
            status: Status.Idle,
        }),
        [clearPrescriptions]: (): PrescriptionTypeReducerType => defaultState,
        [prescriptionFulfilledActions]: (state: PrescriptionType): PrescriptionType => ({
            ...state,
            isLoading: false,
            status: Status.Fulfilled,
        }),
        [prescriptionPendingActions]: (state: PrescriptionType): PrescriptionType => ({
            ...state,
            isLoading: true,
            status: Status.Pending,
        }),
        [prescriptionErrorActions]: (state: PrescriptionType): PrescriptionType => ({
            ...state,
            isLoading: false,
            status: Status.Rejected,
        }),
    },
    defaultState,
);
