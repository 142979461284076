// @flow
/**
 * Convert string to number
 *
 * @param {string | number} number - string or number which should be converted
 * @returns {number} - converted number
 */
export const toNumber = (number: string | number): number =>
    typeof number === 'number' ? number : parseFloat(String(number).replace(/,/g, ''));

/**
 * Convert float number to string
 *
 * @example 1123.233 -> '1,123.233'
 * @param {number} number - number which should be converted
 * @returns {string} - converted value
 */
export const toFloatString = (number: number): string => {
    if (Number.isInteger(number)) return String(number);

    const fractions = number.toLocaleString('en-US').split('.');

    return `${fractions[0]}.${fractions[1].padEnd(2, '0')}`;
};

/**
 * convert float to double
 *
 * @example 123.23333 -> 123.23
 * @param {number} number - number which should be converted
 * @returns {number} - converted value
 */
export const toDouble = (number: number): number => Math.round(number * 100) / 100;

/**
 * Convert price
 *
 * @param {string} symbol - currency symbol
 * @param {string|number} price - Price
 * @returns {string} - formatted string with currency symbol, e.g. $123.50
 */
export function formatPrice(symbol: string = '', price: number | string = ''): string {
    let value = toNumber(price);
    if (Number.isNaN(value)) return String(price);
    value = toFloatString(toDouble(value));

    return `${symbol}${value}`;
}

/**
 * Convert price from well formatted to simple float (e.g. '1,222.45' => 1222.45)
 *
 * @param {string|number} price - Price
 * @returns {number} - float price
 */
export function floatPriceFromFormatted(price: number | string = 0): number {
    if (price === '') return 0;
    return typeof price === 'string' ? Number(price.replace(/,/g, '')) : price;
}

/**
 * Return 0 if value negative
 *
 * @param {number | null} value - passed value
 * @returns {number | null} - converted value
 */
export const toPositive = (value: number | null = 0): number | null =>
    typeof value === 'number' && value < 0 ? 0 : value;
