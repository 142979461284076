import {combineReducers} from 'redux';

import {configure} from 'features/wizard/store/reducers/configure/configure';
import {customerConfiguration} from 'features/wizard/store/reducers/customerConfiguration/customerConfiguration';
import {lensImages} from 'features/wizard/store/reducers/lensImages/lensImages';
import {prescription} from 'features/wizard/store/reducers/prescription';
import {upsells} from 'features/wizard/store/reducers/upsells/upsells';
import {usages} from 'features/wizard/store/reducers/usages/usages';
import {wizardData} from 'features/wizard/store/reducers/wizardData/wizardData';

export const reducers = {
    wizardData,
    usages,
    customerConfiguration,
    prescription,
    configure,
    upsells,
    lensImages,
};

export const wizardMs = combineReducers(reducers);

export type WizardMsType = ReturnType<typeof wizardMs>;
