export const FILL_IT_ONLINE_METHOD = 1;
export const SEND_EMAIL_OR_FAX_METHOD = 3;
export const PRESCRIPTION_UPLOAD_METHOD = 4;
export const NOT_SELECTED = 0;

export enum PrescriptionMethod {
    FillItOnline = 1,
    SendByEmailOrFax = 3,
    PrescriptionUpload = 4,
}

export const PROGRESSIVE = 'Progressive';
