import {createAction, createReducer} from 'redux-act';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';
import {RequestType, ResponseType} from 'constants/typescript/types';
import {AddToCartType, QuoteItemV2Type} from 'reducers/cart/addToCart/addToCartType';

import {setAllowance} from './addToCart/addToCart';
import {CartType, CartTotalsType} from './cartType';
import {defaultState} from './constants';
import {AddToCartResponseType} from '../../features/wizard/components/summaryBottomCta/summaryBottomCta';

export const addToCartFake = createAction<{data: AddToCartResponseType}>(
    'ADD_TO_CART_FULFILLED',
    (data: AddToCartResponseType) => ({data}),
);

export const getCartItems = createAction<RequestType>('GET_CART_ITEMS', collectTax => ({
    request: {
        method: 'GET',
        headers: {Authorization: ''},
        url: `/backend/${ENDPOINTS.CART_ITEMS}`,
        params: {
            collectTax: collectTax ? 1 : 0,
        },
    },
}));
export const getCartTotals = createAction<boolean, RequestType>('GET_CART_TOTALS', collectTax => ({
    request: {
        method: 'GET',
        headers: {Authorization: ''},
        url: `/backend/${ENDPOINTS.CART_TOTALS}`,
        params: {
            collectTax: collectTax ? 1 : 0,
        },
    },
}));
export const clearLocalCart = createAction('CLEAR_LOCAL_CART');
export const clearCart = createAction<RequestType>('CLEAR_CART', () => ({
    request: {
        method: 'DELETE',
        headers: {Authorization: ''},
        url: `/backend/${ENDPOINTS.CLEAR_CART}`,
    },
}));

export const updateCartItem = createAction('UPDATE_CART_ITEM');

export const removeCartItem = createAction<number, RequestType, number>(
    'REMOVE_CART_ITEM',
    (id: number) => ({
        request: {
            method: 'DELETE',
            headers: {Authorization: ''},
            url: `/backend/${ENDPOINTS.CART_ITEM_V2}/${id}`,
        },
    }),
    id => id,
);
export const changeProductInCart = createAction<number, AddToCartType, RequestType, unknown>(
    'CHANGE_PRODUCT_IN_CART',
    (itemId, data): RequestType => ({
        request: {
            method: 'PUT',
            headers: {Authorization: ''},
            url: `/backend/${ENDPOINTS.CART_ITEM_V2}/${itemId}`,
            data,
        },
    }),
);

/**
 * Cart reducer
 */
export const cart = createReducer<CartType>(
    {
        [`${getCartItems}${FULFILLED}`]: (
            state: CartType,
            {data}: ResponseType<QuoteItemV2Type[]>,
        ): CartType =>
            state.isRemovingItem
                ? state
                : {
                      ...state,
                      items: data,
                      isItemsLoading: false,
                  },
        [`${getCartTotals}${FULFILLED}`]: (
            state: CartType,
            {data}: ResponseType<CartTotalsType>,
        ): CartType => ({
            ...state,
            totals: data,
            isTotalsLoading: false,
        }),
        [`${getCartItems}`]: (state: CartType): CartType => ({
            ...state,
            isItemsLoading: true,
        }),
        [`${removeCartItem}`]: (state: CartType, payload, id): CartType => {
            return {
                ...state,
                items: state.items.filter(item => item.id !== id),
                isRemovingItem: true,
                removedItem: state.items.filter(item => item.id === id),
            };
        },
        [`${removeCartItem}${FULFILLED}`]: (state: CartType): CartType => ({
            ...state,
            isRemovingItem: false,
            isTotalsLoading: false,
            isItemsLoading: false,
        }),
        [`${removeCartItem}${REJECTED}`]: (state: CartType): CartType => ({
            ...state,
            isRemovingItem: false,
            isTotalsLoading: false,
            isItemsLoading: false,
        }),
        [`${clearLocalCart}`]: (): CartType => defaultState,
        [`${clearCart}`]: (state: CartType): CartType => ({
            ...state,
            isRemovingItem: true,
        }),
        [`${setAllowance}`]: (state: CartType): CartType => ({
            ...state,
            isTotalsLoading: true,
            isItemsLoading: true,
        }),
        [`${clearCart}${FULFILLED}`]: (): CartType => defaultState,
    },
    defaultState,
);
