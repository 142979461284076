// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';

import type {OffsetTopType} from './offsetTopType';

export const defaultState: OffsetTopType = {};

export const {setOffsetTop, clearOffsetTop} = createActions({
    SET_OFFSET_TOP: (
        fieldName: string,
        value: number,
    ): ActionType<{fieldName: string, value: string}> => ({
        fieldName,
        value,
    }),
    CLEAR_OFFSET_TOP: (fieldName: string): ActionType<string> => fieldName,
});

/**
 * Page info reducer
 *
 * @returns {RouteType} state New state
 */
export const offsetTop = handleActions(
    {
        [setOffsetTop]: (
            state: OffsetTopType,
            {payload: {fieldName, value}}: ActionType<typeof setOffsetTop>,
        ): OffsetTopType => ({
            ...state,
            [fieldName]: value,
        }),
        [clearOffsetTop]: (
            state: OffsetTopType,
            {payload}: ActionType<typeof clearOffsetTop>,
        ): OffsetTopType => ({
            ...state,
            [payload]: null,
        }),
    },
    defaultState,
);
