import {createReducer} from 'redux-act';

import {RMA_STATES} from '../../../constants/common';
import {fourthStepDataAdapter} from '../../../libs';
import {RmaResponseType, RmaDataType} from '../../../types';
import {changeRmaState} from '../../actions/actions';

export const initialState: Partial<RmaDataType['fourthStep']> = {};

/**
 * Reducer rma improved fourth step.
 */
export const fourthStep = createReducer({}, initialState).on(
    `${changeRmaState}_FULFILLED`,
    (state, {data: response}: RmaResponseType) => {
        switch (response.state) {
            case RMA_STATES.CONFIRMATION_EXCHANGE:
            case RMA_STATES.CONFIRMATION_STORE_CREDIT:
            case RMA_STATES.CONFIRMATION_REFUND: {
                return fourthStepDataAdapter(response.data);
            }
            default:
                return state;
        }
    },
);
