// @flow
/**
 * Returns card type or null
 *
 * @param {string} cardNumber - card number
 * @returns {string | null} card type or null
 */
export const isVisaCard = (cardNumber: string): string | null => {
    if (Number(cardNumber.slice(0, 1)) === 4) return 'VI';
    return null;
};

/**
 * Returns card type or null
 *
 * @param {string} cardNumber - card number
 * @returns {string | null} card type or null
 */
export const isAmericanExpressCard = (cardNumber: string): string | null => {
    if (Number(cardNumber.slice(0, 2)) === 34 || Number(cardNumber.slice(0, 2)) === 37) return 'AE';
    return null;
};

/**
 * Returns card type or null
 *
 * @param {string} cardNumber - card number
 * @returns {string | null} card type or null
 */
export const isMasterCard = (cardNumber: string): string | null => {
    if (Number(cardNumber.slice(0, 2)) >= 51 && Number(cardNumber.slice(0, 2)) <= 55) return 'MC';
    return null;
};

/**
 * Returns card type or null
 *
 * @param {string} cardNumber - card number
 * @returns {string | null} card type or null
 */
export const isDiscoverCard = (cardNumber: string): string | null => {
    if (
        Number(cardNumber.slice(0, 2)) === 65 ||
        Number(cardNumber.slice(0, 4)) === 6011 ||
        (Number(cardNumber.slice(0, 6)) >= 622126 && Number(cardNumber.slice(0, 6)) <= 622925) ||
        (Number(cardNumber.slice(0, 3)) >= 644 && Number(cardNumber.slice(0, 3)) <= 649)
    )
        return 'DI';
    return null;
};

/**
 * Returns card type or null
 *
 * @param {string} cardNumber - card number
 * @returns {string | null} card type or null
 */
export const isJCBCard = (cardNumber: string): string | null => {
    if (Number(cardNumber.slice(0, 4)) >= 3528 && Number(cardNumber.slice(0, 4)) <= 3589)
        return 'JCB';
    return null;
};

/**
 * Returns card type
 *
 * @param {string} cardNumber - card number
 * @returns {string} card type
 */
export const checkCardType = (cardNumber: string): string => {
    const cardTypeArr = [
        isVisaCard(cardNumber),
        isAmericanExpressCard(cardNumber),
        isMasterCard(cardNumber),
        isDiscoverCard(cardNumber),
        isJCBCard(cardNumber),
    ];

    return cardTypeArr.find(item => Boolean(item)) || 'other';
};

// Card Spacing Patterns
const cardPatterns = {
    VI: [4, 4, 4, 4],
    AE: [4, 6, 5],
    MC: [4, 4, 4, 4],
    DI: [4, 4, 4, 4],
    JCB: [4, 4, 4, 4],
    other: [4, 4, 4, 4, 4],
};

/**
 * Return max card length
 *
 * @param {string} cardType - card type
 * @returns {number} max card length
 */
export const maxCardLength = (cardType: string): string => {
    const pattern = cardPatterns[cardType] || cardPatterns.other;
    return pattern.reduce((sum, item) => sum + item, 0) + (pattern.length - 1);
};

/**
 * Returns card type
 *
 * @param {string} cardNumber - card number
 * @param {string} cardType - card type
 * @returns {string} card type
 */
export const formatCardNumber = (cardNumber: string, cardType: string): string => {
    const pattern = cardPatterns[cardType] || cardPatterns.other;
    const cardNumberNormalize = cardNumber.slice(
        0,
        pattern.reduce((sum, item) => sum + item, 0),
    );
    const cardNumberArr = Array.from(cardNumberNormalize);

    const formattedArr = pattern.reduce(
        (arr, count) => [...arr, ' ', ...cardNumberArr.splice(0, count)],
        [],
    );

    return formattedArr.join('').trim();
};
