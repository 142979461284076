import React, {PureComponent, ReactElement} from 'react';

import ClassNames from 'classnames';
import {connect} from 'react-redux';

import {Icon} from 'components/__helpers/icon/icon';
import {ApplicationStoreType} from 'constants/typescript/types';
import {MenuItemsLoginPopUpShownEvent} from 'libs/analytics/loginPopUp/GeneralInteractionEvents';
import {isUserLoggedIn} from 'selectors/oauth2Data/oauth2Data';
import {getWishListLength} from 'selectors/wishList/wishList';

import s from './wishListBtn.scss';

export type PropsType = {
    page: string;
    isLoggedIn: boolean;
    wishListLength: number;
    className?: string;
};

type StateType = {
    count: number;
    adding: boolean;
};

/**
 * Default class for header's Wish list component
 *
 * @param {page} page current page
 * @param {isLoggedIn} isLoggedIn log status
 * @param {wishListLength} wishListLength length
 */
export class WishListBtn extends PureComponent<PropsType, StateType> {
    /**
     * Method is invoked after a component is instantiated as well as when it receives new props.
     *
     * @param {PropsType} nextProps - next props
     * @param {StateType} prevState - previous state
     * @returns {StateType} new state
     */
    static getDerivedStateFromProps(nextProps: PropsType, prevState: StateType): StateType | null {
        const {wishListLength: nextCount} = nextProps;
        return nextCount > prevState.count
            ? {adding: true, count: nextCount}
            : {adding: false, count: nextCount};
    }

    state = {
        adding: false,
        count: this.props.wishListLength,
    };

    /**
     * Reset state to default values
     */
    resetState = () => {
        this.setState({adding: false});
    };

    clickHandlerAnalytics = (page: string, isLoggedIn: boolean): void => {
        if (isLoggedIn) return;
        MenuItemsLoginPopUpShownEvent(page === 'category', 'WishlistClick');
    };

    /**
     * Render jsx to html
     *
     * @returns {ReactElement} Rendered react component
     */
    render(): ReactElement {
        const {adding} = this.state;
        const {wishListLength, page, isLoggedIn, className = ''} = this.props;
        const classForAnimation = ClassNames(s.animation, {[s.adding]: adding});

        return (
            <div
                className={ClassNames(s.wishList, className)}
                title="wishList"
                onClick={() => this.clickHandlerAnalytics(page, isLoggedIn)}
            >
                <div className={s.heart}>
                    <Icon name="heartIcon" />
                    <div className={classForAnimation} onAnimationEnd={this.resetState}>
                        <Icon name="heartIcon" />
                    </div>
                </div>
                {wishListLength > 0 && (
                    <div className={s.amount}>
                        <span>{wishListLength > 9 ? '9+' : wishListLength}</span>
                    </div>
                )}
            </div>
        );
    }
}

export const mapStateToProps = (store: ApplicationStoreType) => ({
    wishListLength: getWishListLength(store),
    page: store.route.page,
    isLoggedIn: isUserLoggedIn(store),
});

export const WishListBtnConnected = connect(mapStateToProps)(WishListBtn);
