// @flow
import {createSelector} from 'reselect';

import type {ApplicationStoreType} from 'constants/flow/flowTypes';
import type {WishListType} from 'reducers/wishList';
import type {WishListItemsType} from 'reducers/wishListItems';

/**
 * Get wish list data from store
 *
 * @param {ApplicationStoreType} store - current store
 * @returns {WishListType} - wish list data
 */
export const getWishListData = (store: ApplicationStoreType): WishListType => store.wishList;

/**
 * Get wish list items data from store
 *
 * @param {ApplicationStoreType} store - current store
 * @returns {{wishListItems: WishListItemsType}} - wish list data
 */
export const getWishListItemsData = (store: ApplicationStoreType): WishListItemsType =>
    store.wishListItems;

/**
 * Get wish list length
 *
 * @param {ApplicationStoreType} store - current store
 * @returns {number} - wish list items count
 */
export const getWishListLength = createSelector(
    getWishListData,
    (wishList: WishListType) => Object.keys(wishList.ids).length,
);

/**
 * Get wish IDs list
 *
 * @param {ApplicationStoreType} store - current store
 * @returns {Array} - wish list items array
 */
export const getWishListIds = createSelector(getWishListData, (wishList: WishListType) =>
    Object.keys(wishList.ids),
);
