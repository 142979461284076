import {createSelector} from 'reselect';

import {Status} from 'constants/reducerStatus';
import {ApplicationStoreType} from 'constants/typescript/types';
import {CustomerType} from 'reducers/customer';
import {UserReducerType, UserDiscountType} from 'reducers/user';

/**
 * Get currency symbol
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {string} - currency symbol
 */
export const getCurrency = (state: ApplicationStoreType): string => state.customer.currency?.symbol;

/**
 * Get customer data
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {CustomerType} - customer data
 */
export const getCustomerData = (state: ApplicationStoreType): CustomerType => state.customer;

/**
 * Get user data
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {UserReducerType} - customer data
 */
export const getUserData = (state: ApplicationStoreType): UserReducerType => state.user;

/**
 * Is user have saved prescription
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {boolean} - is user have saved prescriptions
 */
export const isUserHaveSavedPrescriptions = (state: ApplicationStoreType): boolean =>
    Boolean(state.user.prescriptions.length);

/**
 * Get user discount
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {UserDiscountType} - discount data
 */
export const getUserDiscount = (state: ApplicationStoreType): UserDiscountType =>
    state.user.discount;

/**
 * Get is fulfilled pending status
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {UserDiscountType} - is fulfilled
 */
export const isDiscountFulfilled = createSelector(
    getUserDiscount,
    (discount): boolean => discount.status === Status.Fulfilled,
);

/**
 * Returns plan id for current customer
 *
 * @returns {number} plan id
 */
export const getCustomerPlanId = createSelector<ApplicationStoreType, UserReducerType, number>(
    getUserData,
    customerData => Number(customerData.uvp && customerData.uvp.planId),
);

/**
 * Returns IsAppliedBenefits
 *
 * @returns {boolean} plan id
 */
export const getIsAppliedBenefits = createSelector<ApplicationStoreType, UserReducerType, boolean>(
    getUserData,
    customerData => Boolean(customerData.insuranceCompany && customerData.isEligible),
);

/**
 * Returns plan id for current user
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {number} plan id
 */
export const getUserPlanId = (state: ApplicationStoreType): number => Number(state.user.planId);
