import {createAction, createReducer} from 'redux-act';

import {FULFILLED} from 'constants/actionSuffix';
import {RequestType} from 'constants/typescript/types';

import {StoreInfoType} from './storeInfoType';

export const defaultState: StoreInfoType = {
    address: '',
    email: '',
    fax: '',
    phones: {
        cart: '',
        default: '',
        homepage: '',
        uhc: '',
    },
    prescription_email: '',
    working_hours: '',
};

export const getStoreInfo = createAction<RequestType>('GET_STORE_INFO', () => ({
    request: {
        method: 'get',
        url: '/backend/api/rest/store_info',
    },
}));

/**
 * Store info reducer
 */
export const storeInfo = createReducer<StoreInfoType>(
    {
        [`${getStoreInfo}${FULFILLED}`]: (state: StoreInfoType, {data}) => ({
            ...state,
            ...data,
        }),
    },
    defaultState,
);
