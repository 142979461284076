import request, {AxiosPromise} from 'axios';
import {createAction} from 'redux-act';

import {ENDPOINTS} from 'constants/endpoints';
import {RequestType} from 'constants/typescript/types';

import {AddToCartType} from './addToCartType';

export const addToCart = createAction<AxiosPromise<AddToCartType>, AddToCartType>(
    'ADD_TO_CART',
    (data: AddToCartType) => {
        return request({
            method: 'POST',
            headers: {Authorization: '', 'Content-Type': 'application/json'},
            url: ENDPOINTS.ADD_TO_CART_V2,
            data,
        });
    },
    (meta: AddToCartType) => meta,
);

export const setAllowance = createAction<number, RequestType>(
    'SET_ALLOWANCE',
    (itemId: number): RequestType => ({
        request: {
            method: 'PUT',
            headers: {
                Authorization: '',
                'Content-Type': 'application/json',
            },
            url: `/backend/${ENDPOINTS.SET_ALLOWANCE}`,
            data: {
                id: itemId,
            },
        },
    }),
);

export const removeAllowance = createAction<number, RequestType>(
    'REMOVE_ALLOWANCE',
    (itemId: number): RequestType => ({
        request: {
            method: 'DELETE',
            headers: {
                Authorization: '',
                'Content-Type': 'application/json',
            },
            url: `/backend/${ENDPOINTS.REMOVE_ALLOWANCE}/${itemId}`,
            data: {
                id: itemId,
            },
        },
    }),
);
