import {pushDataLayer} from '@optimaxdev/analytics/desktop';

export const sendLoginStatusEvent = (email = false) => {
    pushDataLayer({
        event: 'GeneralNonInteraction',
        eventAction: 'Login Status',
        eventCategory: 'Login',
        eventLabel: `Registered ${email ? '- Account' : '- UHC Member'}`,
    });
};

export const sendLoginAsUHCSuccessEvent = (byAlternativeForm: boolean) => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: `Login As UHC Member${byAlternativeForm ? ' - Alternative' : ''}`,
        eventCategory: 'Login - D',
        eventLabel: 'Success',
    });
};

export const sendLoginAsEmailSuccessEvent = () => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: 'Login As Account',
        eventCategory: 'Login - D',
        eventLabel: 'Success',
    });
};

export const sendLoginAsUHCFailedEvent = (byAlternativeForm: boolean, error: string) => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: `Login As UHC Member${byAlternativeForm ? ' - Alternative' : ''}`,
        eventCategory: 'Login - D',
        eventLabel: `Error - ${error}`,
    });
};

export const sendLoginTypeEvent = (isFullNameFrom: boolean) => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: 'Pop-Up Shown',
        eventCategory: 'Login - D',
        eventLabel: isFullNameFrom ? 'Log in with your member ID' : 'Log in without member ID',
    });
};

export const sendSignInOrCreateAccountEvent = (isSignIn: boolean) => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: 'Pop-Up Shown',
        eventCategory: 'Login - D',
        eventLabel: isSignIn ? 'Sign in' : 'Create UHCG Account',
    });
};

export const sendCreateNonUhcAccountEvent = () => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: 'Pop-Up Shown',
        eventCategory: 'Login - D',
        eventLabel: 'Create Non-UHC Account',
    });
};

export const sendSignInNonUhcEvent = () => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: 'Pop-Up Shown',
        eventCategory: 'Login - D',
        eventLabel: 'Sign in as Non-UHC Account',
    });
};

export const sendAccessLogEvent = () => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: 'Pop-Up Shown',
        eventCategory: 'Login - D',
        eventLabel: 'Access Log in as UHC Member',
    });
};
