// istanbul ignore file
import React from 'react';

import {TopStripMenuConnected} from 'components/__helpers/header/topStripMenu/topStripMenu';

import s from './topStrip.scss';

/**
 * Component TopStrip
 *
 * @returns {React.FC} top strip content
 */
export const TopStrip: React.FC = () => (
    <div className={s.wrap}>
        <div className={s.container}>
            <p className={s.leftSide}>Free Shipping & Returns + 100% Money Back Guarantee</p>
            <TopStripMenuConnected />
        </div>
    </div>
);
