import {
    PurchaseActionFieldType,
    PurchaseProductType,
    pushDataLayer,
} from '@optimaxdev/analytics/desktop';
import SessionStore from 'store/storages/sessionStorage';

import {ACCOUNT, LOGIN_STATUS} from 'constants/loginStatus/loginStatus';

/**
 * Send login analytics data
 *
 * @param {string} action - action field
 * @param {string} label - label field
 */
export const sendLoginEvent = (action: string, label = '') => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventCategory: 'Login',
        eventAction: action,
        eventLabel: label,
    });
};

/**
 * Send purchase analytics data
 *
 * @param {{actionField: PurchaseActionFieldType, products: Array<PurchaseProductType>}} purchase - prepared data for the event
 */
export const sendEECPurchaseEvent = (purchase: {
    actionField: PurchaseActionFieldType;
    products: PurchaseProductType[];
}) => {
    pushDataLayer({
        event: 'EEC_purchase',
        ecommerce: {
            purchase,
        },
    });
};

/**
 * Send sign up analytic
 */
export const sendSignUpAnalytic = () => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: 'SignUp As Account',
        eventCategory: 'Login - D',
        eventLabel: 'Success',
    });
    if (SessionStore.read('login_status') !== 'account') {
        pushDataLayer({
            event: 'GeneralNonInteraction',
            eventAction: 'Login Status',
            eventCategory: 'Login',
            eventLabel: 'Registered - Account',
        });
        SessionStore.write(LOGIN_STATUS, ACCOUNT);
    }
};
/**
 * Send Store Credit click analytic
 */
export const sendStoreCreditClickAnalytic = () => {
    pushDataLayer({
        event: 'CartInteraction',
        eventAction: 'Do you have store credit?',
        eventCategory: 'Cart - D',
        eventLabel: 'Click',
    });
};
/**
 * Send Store Credit apply analytic
 *
 * @param {boolean} isCodeApplied - successfully applied
 * @param {string} code - store credit or code"
 * @param {string} error - error message"
 */
export const sendStoreCreditApplyAnalytic = (
    isCodeApplied: boolean,
    code: string,
    error: string,
) => {
    pushDataLayer({
        event: 'CartInteraction',
        eventAction: `Apply Coupon - ${isCodeApplied ? 'Success' : 'Error'}`,
        eventCategory: 'Cart - D',
        eventLabel: isCodeApplied ? code : `${code} - ${error}`,
    });
};

export const sendRemoveCouponAnalytic = (code: string) => {
    pushDataLayer({
        event: 'CartInteraction',
        eventAction: 'Remove Coupon',
        eventCategory: 'Cart - D',
        eventLabel: code,
    });
};

/**
 * Send on click Claim Now in try on
 */
export const sendOnClickClaimNow = () => {
    pushDataLayer({
        event: 'CategoryInteraction',
        eventAction: 'Try On',
        eventCategory: 'Category - D',
        eventLabel: 'CTA - Claim Now',
    });
};
