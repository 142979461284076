/* istanbul ignore file */
import {lazy} from 'react';

export const CategoryConnected = lazy(
    () => import(/* webpackChunkName: "Category" */ 'pages/category/category'),
);
export const MyAccountConnected = lazy(
    () => import(/* webpackChunkName: "MyAccount" */ 'pages/myAccount/myAccount'),
);
export const HomeConnected = lazy(
    () => import(/* webpackChunkName: "HomePage" */ 'pages/home/home'),
);
export const WishListConnected = lazy(
    () => import(/* webpackChunkName: "WishList" */ 'pages/wishList/wishList'),
);
export const CheckoutConnected = lazy(
    () => import(/* webpackChunkName: "Checkout" */ 'pages/checkout/checkout'),
);
export const ThankYouConnected = lazy(
    () => import(/* webpackChunkName: "ThankYou" */ 'pages/thankYou/thankYou'),
);
export const TrackingConnected = lazy(
    () => import(/* webpackChunkName: "Tracking" */ 'pages/tracking/tracking'),
);
export const OrderPrintConnected = lazy(
    () => import(/* webpackChunkName: "OrderPrint" */ 'pages/orderPrint/orderPrint'),
);
export const CartConnected = lazy(() => import(/* webpackChunkName: "Cart" */ 'pages/cart/cart'));
export const Page404Component = lazy(
    () => import(/* webpackChunkName: "Page404" */ 'pages/404/404'),
);
export const CmsConnected = lazy(() => import(/* webpackChunkName: "CmsPage" */ 'pages/cms/cms'));

export const Privacy = lazy(
    () => import(/* webpackChunkName: "Privacy" */ 'pages/privacy/privacy'),
);
export const TryOnNotice = lazy(
    () => import(/* webpackChunkName: "TryOnNotice" */ 'pages/tryOnNotice/tryOnNotice'),
);
export const TermsOfUse = lazy(
    () => import(/* webpackChunkName: "TermsOfUse" */ 'pages/termsOfUse/termsOfUse'),
);
export const AdditionalPurchase = lazy(
    () =>
        import(
            /* webpackChunkName: "AdditionalPurchase" */ 'pages/additionalPurchase/additionalPurchase'
        ),
);
export const ContactUsConnected = lazy(
    () => import(/* webpackChunkName: "ContactUsPage" */ 'pages/contactUs/contactUs'),
);
export const AccessibilityConnected = lazy(
    () => import(/* webpackChunkName: "Accessibility" */ 'pages/accessibility/accessibilityPage'),
);
export const FaqConnected = lazy(() => import(/* webpackChunkName: "Faq" */ 'pages/faq/faq'));

export const RmaConnected = lazy(() => import(/* webpackChunkName: "RMA" */ 'pages/rma/rma'));

export const CustomerSupportLogin = lazy(
    () =>
        import(
            /* webpackChunkName: "CustomerSupportLogin" */ 'pages/customerSupportLogin/customerSupportLogin'
        ),
);
