import {pushDataLayer} from '@optimaxdev/analytics/desktop';

const InteractionPlaces = {
    LogIn: 'Account Log in',
    AccessBenefit: 'HP - Banner Access Benefit',
    AccessBenefitHowItWorks: 'HP - How it works - Access Benefit',
    CreateAccount: 'HP - Create Account',
    PopularFrames: 'HP - Popular Frames',
    // named with _ means need to pass brand or name in event
    HP_Brand: `HP - `,
    DesignerGlasses_Brand: 'HP - Designer glasses - ',
    Category_Name: 'HP - ',
    CategoryProduct_Name: 'CTP - Product Click - ',
    VirtualMirror_Gender: 'HP - Virtual Mirror - ',
};

const MenuInteractionPlaces = {
    CartClick: 'Cart',
    WishlistClick: 'Wishlist',
    SearchClick: 'Search',
};

export const LoginPopUpShownEvent = (
    placeOfInteraction: Array<keyof typeof InteractionPlaces>,
    brandOrName = '',
) => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventAction: 'Pop-Up Shown',
        eventCategory: 'Login - D',
        eventLabel: `${InteractionPlaces[`${placeOfInteraction}`]}${brandOrName}`,
    });
};

export const MenuItemsLoginPopUpShownEvent = (
    isPageCategory = false,
    placeOfInteraction: keyof typeof MenuInteractionPlaces,
) => {
    pushDataLayer({
        event: isPageCategory ? 'CategoryInteraction' : 'GeneralInteraction',
        eventAction: 'Pop-Up Shown',
        eventCategory: 'Login - D',
        eventLabel: `${isPageCategory ? 'CTP - ' : 'HP - '}${
            MenuInteractionPlaces[placeOfInteraction]
        }`,
    });
};
