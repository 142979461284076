export const deleteLogoutCookie = () =>
    (document.cookie = 'needLogout=0; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/');

export const setLogoutCookie = () => {
    const date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    const expires = `; expires=${date.toUTCString()}`;
    document.cookie = `needLogout=1; expires=${expires}; path=/`;
};

export const getLogoutCookie = () =>
    document.cookie
        .split('; ')
        .find(row => row.startsWith('needLogout='))
        ?.split('=')[1] || null;
