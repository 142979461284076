import React, {FC} from 'react';

import classNames from 'classnames';

import s from './uhcLoader.scss';

type PropsType = {
    className?: string;
    isSmall?: boolean;
};

export const UhcLoader: FC<PropsType> = ({className = '', isSmall = false}) => {
    // istanbul ignore next
    return (
        <span
            aria-label="loading"
            className={classNames(s.loader, className, {[s.small]: isSmall})}
        />
    );
};
