import {AxiosResponse} from 'axios';
import {createActions, handleActions, ActionType} from 'redux-actions';

import {FULFILLED} from 'constants/actionSuffix';
import {API_URL_ELASTICSEARCH} from 'constants/api';
import {ENDPOINTS} from 'constants/endpoints';
import {Status} from 'constants/reducerStatus';
import {removeFromWishList, clearWishListAction} from 'reducers/wishList';

import {WishListItemsType} from './wishListItemsType';

export const defaultState = {
    status: Status.Fulfilled,
    items: [],
};
// TODO need to specify incoming data.
export const {getWishListItems} = createActions({
    GET_WISH_LIST_ITEMS: (ids: Array<number>): ActionType<AxiosResponse> => ({
        request: {
            method: 'get',
            baseURL: API_URL_ELASTICSEARCH,
            url: ENDPOINTS.GET_WISH_LIST_ITEMS,
            params: {
                ids: ids.join(','),
                isSalable: 0,
                limit: ids.length,
            },
        },
    }),
});

/**
 * Wish list items reducer
 *
 * @returns {WishListItemsType} state - next state
 */
export const wishListItems = handleActions(
    {
        [`${getWishListItems}${FULFILLED}`]: (
            state: WishListItemsType,
            {payload}: ActionType<typeof getWishListItems>,
        ): WishListItemsType => ({
            items: payload.data
                ? payload.data.items
                : [
                      ...state.items.filter(
                          item => item.children[0].id !== payload.data[0].children[0].id,
                      ),
                      ...payload.data.items,
                  ],
            status: Status.Fulfilled,
        }),
        [getWishListItems]: (state: WishListItemsType): WishListItemsType => ({
            ...state,
            status: Status.Pending,
        }),
        [removeFromWishList]: (
            state: WishListItemsType,
            {payload: productId}: ActionType<typeof removeFromWishList>,
        ): WishListItemsType => ({
            ...state,
            items: state.items.filter(item => item.children[0].id !== productId),
        }),
        [clearWishListAction]: (): WishListItemsType => defaultState,
    },
    defaultState,
);
