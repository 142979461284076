import React, {FC} from 'react';

import s from './cartItemSnackbar.scss';

export type PropsType = {
    prevName: string;
    currentName: string;
    isRemove: boolean;
};

export const CartItemSnackbar: FC<PropsType> = ({prevName, currentName, isRemove}) => {
    return (
        <>
            {isRemove ? (
                <>
                    <span className={s.firstLine}>
                        Your allowance benefits have been removed from {prevName}
                    </span>
                </>
            ) : (
                <>
                    <span className={s.firstLine}>Your allowance benefit is now applied to</span>
                    <span className={s.secondLine}>
                        <span className={s.ellipsis}>{currentName}</span>
                        {prevName && (
                            <>
                                <span className={s.instead}>instead of</span>
                                <span className={s.ellipsis}>{prevName}</span>
                            </>
                        )}
                    </span>
                </>
            )}
        </>
    );
};
