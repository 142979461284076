import {
    EEC_RECOMMENDATION,
    getProductType,
    prepareBaseImpressionData,
    pushDataLayer,
} from '@optimaxdev/analytics/desktop';
import {convertPriceEEC} from '@optimaxdev/utils';
import {pick, path} from 'ramda';

import {ProductChildrenType} from 'reducers/product';

export const CTP_SIMILAR_FRAMES = 'Category - Recommendation - Similar Frames';

export const prepareImpressionDataForRecommendationProducts = (
    products: ProductChildrenType[],
    options,
) =>
    products.map((product, index) => ({
        ...prepareBaseImpressionData(
            {
                ...pick(['name', 'sku', 'price', 'category'], product),
                brand: path(['label'], product.brand),
                size: path(['frame', 'size', 'shortLabel'], product),
                id: product.sku,
            },
            4,
        ),
        variant: `${path(['frame', 'size', 'shortLabel'], product)}|${path(
            ['color', 'label'],
            product,
        )}`,
        product_id: product.id,
        position: index + 1,
        list: options.list || '',
        dimension36: convertPriceEEC(product.price),
        dimension42: '',
        dimension43: '',
        dimension60: getProductType(product.type, product.isEligibleForRx),
        category: CTP_SIMILAR_FRAMES,
    }));

export const sendAnalyticsForSimilarFrames = (
    locationPath: string,
    products: ProductChildrenType[],
) => {
    const impressions = prepareImpressionDataForRecommendationProducts(products, {
        list: locationPath,
    });

    const event = {
        event: EEC_RECOMMENDATION.VIEWED_IMPRESSION,
        ecommerce: {
            impressions,
        },
    };
    pushDataLayer(event);
};
