import React from 'react';

import {LINK_PAGE} from 'constants/links';

import s from './waitForPhoneNumber.scss';

export const PolicyText = () => (
    <p className={s.policyText}>
        By providing your phone number, you agree to receive calls and/or text messages from United
        Vision Plans at the phone number you provided for non-marketing purposes, including, but not
        limited to, the security of your account. For example, we will send you a text message to
        help us verify your identity each time you login to access your vision benefits. Message &
        data rates may apply. View our{' '}
        <a target="_blank" rel="noopener noreferrer" className={s.link} href={LINK_PAGE.TERMS}>
            Terms of Use
        </a>{' '}
        and{' '}
        <a target="_blank" rel="noopener noreferrer" className={s.link} href={LINK_PAGE.POLICY}>
            Privacy Policy
        </a>
        .
    </p>
);
