import React, {FC, Fragment} from 'react';

import {connect} from 'react-redux';

import {UhcLoader} from 'components/__helpers/uhcLoader/uhcLoader';
import {Button} from 'components/common/button/button';
import {Plan} from 'components/plan';
import {LINK_EYEGLASSES_COLLECTION} from 'constants/links';
import {ApplicationStoreType} from 'constants/typescript/types';
import {useFilteredPlan} from 'libs/hooks/useFilteredPlan';
import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';
import {isUHCMember} from 'selectors/user/user';

import s from './welcomePopup.scss';

type PropsType = {
    isWelcomeText?: boolean;
    closePopup: () => void;
    isUHCMember: boolean;
};

export const WelcomePopup: FC<PropsType> = ({isWelcomeText = false, closePopup, isUHCMember}) => {
    const planData = useFilteredPlan();

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <h2 className={s.title}>Welcome, {!planData.isFetching && planData.firstName}</h2>
                {isWelcomeText ? (
                    <p className={s.subtitle}>
                        Here is a short summary of your plan and your eligibility status.
                    </p>
                ) : (
                    <Fragment>
                        <p className={s.subtitle}>
                            {isUHCMember
                                ? 'You can start using your vision benefits'
                                : 'You can start enjoying everything we have to offer'}
                        </p>
                        <h3 className={s.description}>You&apos;re eligible for:</h3>
                    </Fragment>
                )}
                {planData.isFetching ? (
                    <UhcLoader />
                ) : (
                    <>
                        <div className={s.table}>
                            <Plan planData={planData} />
                        </div>

                        <p className={s.aboutBenefits}>
                            {isUHCMember
                                ? 'Prices are shown according to your benefits'
                                : 'Prices shown include your special discount. No coupon required.'}
                        </p>

                        <Button
                            className={s.getStartedLink}
                            btnType="link"
                            color="portGore"
                            link={LINK_EYEGLASSES_COLLECTION}
                            onClick={closePopup}
                        >
                            {isUHCMember ? 'Use Benefits' : 'Get Started'}
                        </Button>
                    </>
                )}
            </div>
            <div className={s.phoneBlock}>
                <span>Question about your benefits? Call</span>
                <a className={s.phoneNumber} href="tel: 1-844-244-1179">
                    +1(844)-244-1179
                </a>
            </div>
        </div>
    );
};

export const mapStateToProps = (store: ApplicationStoreType) => ({
    isUHCMember: isUHCMember(store),
});

export const mapDispatchToProps = {
    closePopup: () => setCmsPopupVisibility(false, null),
};

export const WelcomePopupConnected = connect(mapStateToProps, mapDispatchToProps)(WelcomePopup);
