import React from 'react';

import classNames from 'classnames';

import {sendTopStripMenuAnalytics} from '../analytics/sendTopStripMenuAnalytics';
import {TopStripMenuDropdown} from '../topStripMenuDropdown/topStripMenuDropdown';

import s from './topStripPhoneNumber.scss';

export const TopStripPhoneNumber = ({phone}) => {
    const screenWidthRange = () =>
        matchMedia('(min-width: 1280px)').matches && matchMedia('(max-width: 1440px)').matches;

    return (
        <div
            className={classNames(s.topStripPhoneNumber, s.topStripPhoneNumberCall, {
                [s.screenWithRange]: screenWidthRange(),
            })}
        >
            <>
                {screenWidthRange() ? (
                    <a
                        className={s.phoneNumber}
                        href={`tel:${phone}`}
                        onClick={() => sendTopStripMenuAnalytics('Call us')}
                    >
                        <p className={s.phoneWeek}>
                            <span>{phone}</span> (9 a.m. - 9 p.m. Monday to Friday)
                        </p>
                    </a>
                ) : (
                    <>
                        <a
                            className={s.phoneNumber}
                            href={`tel:${phone}`}
                            onClick={() => sendTopStripMenuAnalytics('Call us')}
                        >
                            {phone}
                        </a>
                        <TopStripMenuDropdown activeClass={s.activeClass}>
                            <a className={s.phoneNumber} href={`tel:${phone}`}>
                                9 a.m. - 9 p.m. Monday to Friday{' '}
                            </a>
                        </TopStripMenuDropdown>
                    </>
                )}
            </>
        </div>
    );
};
