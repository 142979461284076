import React, {Context, FC, ReactElement, useEffect, useState} from 'react';

import Rox from 'rox-browser';

import {FETCH_EVENT, FETCH_STATUSES} from 'libs/rolloutConfiguration/fetcher';

export const FeaturesContext: Context<string> = React.createContext('loading');

/**
 * Feature context provider
 *
 * @returns {React.ReactNode} rendered component
 */
export const FeatureProvider: FC<{children: ReactElement | string}> = ({children}) => {
    const [isLoaded, setIsLoaded] = useState(false);

    /**
     * Update Flags when configuration fetched from network
     *
     * @param {CustomEvent} event - fetch event, detail contain fetcherResult https://support.rollout.io/docs/javascript-browser-api#section-configurationfetchedhandler
     */
    const onConfigurationFetched = (event: CustomEvent): void => {
        if (event.detail.fetcherStatus === FETCH_STATUSES.APPLIED_FROM_NETWORK) {
            Rox.unfreeze();
            setIsLoaded(true);
        }
    };

    /**
     * Setup event fetch-event listener and
     * cleanup event fetch-event listener based on lifecycle
     */
    useEffect(() => {
        window.addEventListener(FETCH_EVENT, onConfigurationFetched as EventListener);
        return () => {
            window.removeEventListener(FETCH_EVENT, onConfigurationFetched as EventListener);
        };
    }, []);

    return (
        <FeaturesContext.Provider value={isLoaded ? 'loaded' : 'loading'}>
            {children}
        </FeaturesContext.Provider>
    );
};
