import React, {FC, useMemo} from 'react';

import {BenefitType, DiscountType} from 'libs/hooks/usePlanRequest';

import s from './planTableRow.scss';

export type PropsType = Partial<BenefitType & DiscountType>;

const discountHandler = (discount: number, action: 'by_percent' | 'by_fixed') => {
    if (action === 'by_percent') return discount === 100 ? 'FREE' : `${discount}% Off`;
    if (action === 'by_fixed') return `$${discount} Off`;
    return '';
};

export const PlanTableRow: FC<PropsType> = ({
    description,
    allowance,
    copay,
    priceProtection,
    discount,
    action,
}) => {
    const price = useMemo(() => {
        if (allowance)
            return (
                <>
                    Up to ${allowance}
                    <br />
                    (co-pay ${copay})
                </>
            );
        if (priceProtection === 0) return `FREE`;
        if (priceProtection) return `$${priceProtection}`;
        if (discount && action) {
            return discountHandler(discount, action);
        }
        return '';
    }, [action, allowance, copay, discount, priceProtection]);

    return description && price ? (
        <tr>
            <th className={s.key}>{description}</th>
            <td className={s.value}>{price}</td>
        </tr>
    ) : null;
};
