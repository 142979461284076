import React, {useState, useCallback, useEffect} from 'react';

import {connect} from 'react-redux';

import {MemberDataType} from 'components/home/signInHomePage/hooks';
import {ApplicationStoreType} from 'constants/typescript/types';
import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';
import {isPopupVisible} from 'selectors/popup/popup';

import {LoginPopup, LoginPopupNames} from './loginPopup';
import flags from '../../../constants/flags/flags';
import {
    closeMFAPopup as closeMFAPopupAction,
    setCanOpenWP as setCanOpenWPAction,
} from '../../../reducers/oauth2';
import {LogInType} from '../api/customerMs/customerMsType';

type LoginPopupWrapperPropsType = {
    isOpen?: boolean;
    currentPopupName?: LoginPopupNames;
    onRequestClose?: () => void;
    closeMFAPopup: () => void;
    setCanOpenWP: (canOpen: boolean) => void;
    memberLoginPhoneDataHP?: LogInType;
    memberLoginDataHP?: MemberDataType;
};

/**
 * Login popup wrapper, for rendering the login popup with all props as optional
 *
 * @param {LoginPopupWrapperPropsType} props - component props
 * @returns {React.ReactElement} - login popup
 */
export function LoginPopupWrapper({
    isOpen = true,
    currentPopupName,
    onRequestClose,
    closeMFAPopup,
    setCanOpenWP,
    memberLoginDataHP,
    memberLoginPhoneDataHP,
}: LoginPopupWrapperPropsType): React.ReactElement {
    const [isPopupOpenState, setPopupIsOpenState] = useState(Boolean(isOpen));
    const closeLoginPopup = useCallback(() => {
        setPopupIsOpenState(false);
        if (onRequestClose) {
            onRequestClose();
        }
        if (flags.mfaUHCGD.isEnabled()) {
            setCanOpenWP(true);
            closeMFAPopup();
        }
    }, [onRequestClose, closeMFAPopup, setCanOpenWP]);
    useEffect(() => {
        setPopupIsOpenState(isOpen);
    }, [isOpen]);
    return (
        <LoginPopup
            isOpen={isPopupOpenState}
            currentPopupName={currentPopupName}
            onRequestClose={closeLoginPopup}
            memberLoginDataHP={memberLoginDataHP}
            memberLoginPhoneDataHP={memberLoginPhoneDataHP}
        />
    );
}

/**
 * Map state to props
 */
export const mapStateToProps = (
    store: ApplicationStoreType,
): {isOpen: boolean; currentPage: string} => ({
    isOpen: isPopupVisible(store),
    currentPage: store.route.page,
});

export const mapDispatchToProps: Pick<
    LoginPopupWrapperPropsType,
    'onRequestClose' | 'closeMFAPopup' | 'setCanOpenWP'
> = {
    onRequestClose: () => setCmsPopupVisibility(false, null, {}),
    closeMFAPopup: closeMFAPopupAction,
    setCanOpenWP: setCanOpenWPAction,
};

export const LoginPopupWrapperConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginPopupWrapper);
