import React, {FC} from 'react';

import {Icon} from 'components/__helpers/icon/icon';

import {LINK_UHC_SUPPORT} from '../../../../../constants/links';
import {AUTH_STEPS} from '../twoFactorAuth';

import s from './waitForCode.scss';

type PropsType = {
    phoneNumber: string;
    canEditPhone: boolean;
    setStep: (step) => void;
};

export const PhoneNumberSection: FC<PropsType> = ({phoneNumber, canEditPhone, setStep}) => {
    const onClickEdit = () => {
        setStep(AUTH_STEPS.WAIT_FOR_PHONE_NUMBER);
    };
    return (
        <>
            <h3 className={s.title}>
                {phoneNumber}
                {canEditPhone && (
                    <button
                        className={s.iconWrapper}
                        onClick={onClickEdit}
                        type="button"
                        aria-label="Edit phone"
                    >
                        <Icon name="edit" />
                    </button>
                )}
            </h3>
            <div className={s.description}>
                {canEditPhone ? (
                    "Enter the 6-digit code we've sent you."
                ) : (
                    <p>
                        Enter the 6-digit code we&apos;ve sent you. Not your phone number? Contact{' '}
                        <a
                            className={s.support}
                            href={LINK_UHC_SUPPORT}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            customer support
                        </a>
                        .
                    </p>
                )}
            </div>
        </>
    );
};
