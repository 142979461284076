import {ApplicationStoreType} from 'constants/typescript/types';
import {RouteType} from 'reducers/route/routeType';

/**
 * Get current page info
 *
 * @param {ApplicationStoreType} store - Current store
 * @returns {PageType} - current page info
 */
export const getPageData = (store: ApplicationStoreType): RouteType => store.route;

/**
 * Get current page info
 *
 * @param {ApplicationStoreType} store - Current store
 * @returns {string} - current page name
 */
export const getPageName = (store: ApplicationStoreType): string => store.route.page;
