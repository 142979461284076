import React, {memo, useState, ReactNode, useEffect, useCallback} from 'react';

import {useDispatch} from 'react-redux';

import {Modal} from 'components/__helpers/modal/modal';
import {MemberDataType} from 'components/home/signInHomePage/hooks';

import {BottomBlock} from './bottomBlock/bottomBlock';
import {CreateAccountBlockConnected} from './createAccountForm/createAccountForm';
import {EmailLoginFormConnected} from './emailLoginForm/emailLoginForm';
import {ForgotPasswordPopUp} from './forgotPassword/forgotPassword';
import {MemberLoginConnected} from './memberLogin/memberLogin';
import flags from '../../../constants/flags/flags';
import {setCanOpenWP} from '../../../reducers/oauth2';
import {LoginPopupProvider} from '../context/loginPopupProvider';
import {sendMFAAnalytic} from './twoFactorAuth/analytics';
import {TwoFactorAuth} from './twoFactorAuth/twoFactorAuth';
import {LogInType} from '../api/customerMs/customerMsType';

import s from './loginPopup.scss';

export enum LoginPopupNames {
    member = 'member',
    email = 'email',
    create = 'create',
    forgot = 'forgot',
    mfa = 'mfa',
}

export type PropsType = {
    isOpen: boolean;
    onRequestClose: () => void;
    currentPopupName?: LoginPopupNames;
    memberLoginPhoneDataHP?: LogInType;
    memberLoginDataHP?: MemberDataType;
};

const MODAL_CONTAINER_CONTENT_STYLE_PARTIAL = {
    top: '65px',
    transform: 'translateX(-50%)',
};

export const LoginPopup = memo<PropsType>(
    ({
        isOpen,
        currentPopupName = LoginPopupNames.member,
        onRequestClose,
        memberLoginDataHP,
        memberLoginPhoneDataHP,
    }) => {
        const [popup, setPopup] = useState<LoginPopupNames>(currentPopupName);
        const [isError, setIsError] = useState(false);
        const [analyticsStep, setAnalyticsStep] = useState('');
        const isShowBottomBlock = [LoginPopupNames.email, LoginPopupNames.member].includes(popup);

        const onCloseWithAnalytics = useCallback(() => {
            onRequestClose();
            if (analyticsStep) {
                sendMFAAnalytic('Closed', analyticsStep);
            }
        }, [analyticsStep, onRequestClose]);

        const dispatch = useDispatch();
        useEffect(() => {
            /* istanbul ignore next */
            if (isError && popup !== 'member' && popup !== 'create') setIsError(false);
        }, [isError, popup]);
        useEffect(() => {
            if (flags.mfaUHCGD.isEnabled()) dispatch(setCanOpenWP(false));
        }, [dispatch]);

        const FORM: Record<LoginPopupNames, ReactNode> = {
            email: <EmailLoginFormConnected onRequestClose={onRequestClose} setPopup={setPopup} />,
            member: (
                <MemberLoginConnected
                    onRequestClose={onRequestClose}
                    setIsError={setIsError}
                    setPopup={setPopup}
                />
            ),
            create: (
                <CreateAccountBlockConnected
                    setPopup={setPopup}
                    isError={isError}
                    onRequestClose={onRequestClose}
                />
            ),
            forgot: <ForgotPasswordPopUp onRequestClose={onRequestClose} />,
            mfa: (
                <TwoFactorAuth
                    setPopup={setPopup}
                    onRequestClose={onRequestClose}
                    setAnalyticsStep={setAnalyticsStep}
                    memberLoginDataHP={memberLoginDataHP}
                    memberLoginPhoneDataHP={memberLoginPhoneDataHP}
                />
            ),
        };

        return (
            <LoginPopupProvider>
                <Modal
                    contentStyles={MODAL_CONTAINER_CONTENT_STYLE_PARTIAL}
                    isOpen={isOpen}
                    onRequestClose={onCloseWithAnalytics}
                >
                    <div className={s.wrap} data-fs-mask-form>
                        {FORM[popup]}
                        {isShowBottomBlock && <BottomBlock setPopup={setPopup} popup={popup} />}
                    </div>
                </Modal>
            </LoginPopupProvider>
        );
    },
);

LoginPopup.displayName = 'LoginPopup';
