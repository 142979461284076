import React, {ReactNode, useEffect} from 'react';

import {wrapCreateBrowserRouter} from '@sentry/react';
import {useDispatch} from 'react-redux';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import {isTokenExist} from 'reducers/oauth2';
import {getStoreInfo} from 'reducers/store';

import {dynamicPageLoader} from './api/dynamicPageLoader/dynamicPageLoader';
import {Authorisation} from './components/authorisation/authorisation';
import {CatchError} from './components/catchError/catchError';
import {DynamicPage} from './components/dynamicPage/dynamicPage';
import {PageWrapper} from './components/pageWrapper/pageWrapper';
import {Root} from './components/root/root';
import {StaticRoutes, StaticRoutesAuth} from './constants/routes';

// Fix type error only in integrations
// @ts-ignore
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const routesScheme = sentryCreateBrowserRouter([
    {
        element: <Root />,
        errorElement: <CatchError />,
        children: [
            {
                errorElement: <CatchError />,
                children: StaticRoutes.map(route => ({
                    path: route.path,
                    element: (
                        <PageWrapper
                            page={route.page}
                            component={route.component}
                            event={route.event}
                            page_type_param2={route.page_type_param2}
                        />
                    ),
                })),
            },
            {
                element: <Authorisation />,
                errorElement: <CatchError />,
                children: StaticRoutesAuth.map(route => ({
                    path: route.path,
                    element: (
                        <PageWrapper
                            page={route.page}
                            component={route.component}
                            event={route.event}
                            page_type_param2={route.page_type_param2}
                        />
                    ),
                })),
            },
            {
                path: '*',
                loader: dynamicPageLoader,
                element: <DynamicPage />,
                errorElement: <CatchError />,
            },
        ],
    },
]);

export const Router = (): ReactNode => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(isTokenExist());
        dispatch(getStoreInfo());
    }, [dispatch]);

    return <RouterProvider router={routesScheme} />;
};
