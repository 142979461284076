// @flow
import React, {type Node, PureComponent} from 'react';
import {connect} from 'react-redux';
import onClickOutside from 'react-onclickoutside';

import type {ApplicationStoreType, VoidFunctionType} from 'constants/flow/flowTypes';
import {NEW_PASSWORD} from 'constants/formNames';
import {Button} from 'components/common/button/button';
import {InputForm} from 'components/__helpers/inputForm/inputForm';
import {updateFieldForm, clearFieldForm} from 'reducers/formData';
import {resetPassword, setValidationError, clearErrorMessage} from 'reducers/newPassword';
import {getSearchParams} from 'libs/url';

import s from './newPassword.scss';

type PropsType = {
    formFields: {password: string, confirmation: string},
    resetPassword: VoidFunctionType<string, string, string>,
    updateField: VoidFunctionType<string, string>,
    clearField: VoidFunctionType<string>,
    setValidationError: VoidFunctionType<string>,
    clearErrorMessage: VoidFunctionType<>,
    newPassword: {error: string},
};

/**
 * Class for NewPassword Component
 */
export class NewPasswordPopup extends PureComponent<PropsType> {
    /**
     * Clear password error messages
     */
    componentDidMount() {
        this.props.clearErrorMessage();
    }

    /**
     * Send form to backend
     *
     * @param {SyntheticEvent<HTMLFormElement>} event - submit event
     */
    submitForm = (event: SyntheticEvent<HTMLFormElement>) => {
        const {formFields} = this.props;
        event.preventDefault();
        const {token, email} = getSearchParams();

        if (formFields.password === formFields.confirmation) {
            this.props.resetPassword(formFields.password, token, email);
            return;
        }

        this.props.setValidationError('Form fields must be the same');
    };

    /**
     * Change field state
     *
     * @param {SyntheticInputEvent<HTMLInputElement>} event - change event
     */
    handleChangeInput = (event: SyntheticInputEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        this.props.updateField(name, value);
        this.props.clearErrorMessage();
    };

    /**
     * Clear field
     *
     * @param {string} fieldName - field name
     */
    clearInput = (fieldName: string) => {
        this.props.clearField(fieldName);
        this.props.clearErrorMessage();
    };

    /**
     * Render jsx to html
     *
     * @returns {Node} Rendered react component
     */
    render(): Node {
        const {formFields, newPassword} = this.props;

        return (
            <div className={s.wrapper}>
                <p className={s.title}>Please, set new password</p>
                <p className={s.error}>{newPassword.error}</p>

                <form className={s.form} onSubmit={this.submitForm}>
                    <div className={s.wrapInput}>
                        <InputForm
                            key="password"
                            name="password"
                            type="password"
                            placeholder="New password"
                            value={formFields.password}
                            onChange={this.handleChangeInput}
                            clear={this.clearInput}
                            icon
                        />
                    </div>

                    <div className={s.wrapInput}>
                        <InputForm
                            key="confirmation"
                            name="confirmation"
                            type="password"
                            placeholder="Verify password"
                            value={formFields.confirmation}
                            onChange={this.handleChangeInput}
                            clear={this.clearInput}
                            icon
                        />
                    </div>
                    <Button className={s.btn} color="portGore" value="Set new password" />
                </form>
            </div>
        );
    }
}

export const mapStateToProps = (store: ApplicationStoreType) => ({
    formFields: store.formData.newPassword,
    newPassword: store.newPassword,
});

export const mapDispatchToProps = {
    updateField: (name: string, value: string) => updateFieldForm(name, value, NEW_PASSWORD),
    clearField: (name: string) => clearFieldForm(name, NEW_PASSWORD),
    resetPassword,
    setValidationError,
    clearErrorMessage,
};

export const NewPasswordPopupConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(onClickOutside(NewPasswordPopup));
