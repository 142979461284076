import {useEffect, RefObject} from 'react';

/**
 * Provides calling the handler when the customer clicks outside of container (ref)
 *
 * @param {RefObject<HTMLDivElement>} ref - reference to container
 * @param {VoidFunction} handler - any handler to make reaction on outside click
 */
export const useOnClickOutside = (
    ref: RefObject<HTMLDivElement>,
    handler: (event: Event) => void,
) => {
    useEffect(() => {
        const listener = (event: Event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }

            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};
