import {PrescriptionType} from 'features/wizard/store/reducers/prescription/prescriptionTypes';
import {
    PrescriptionStep,
    WizardFeatureReducerType,
} from 'reducers/wizard/wizardFeature/wizardFeature';

import {PropsType} from '../../components/prescriptionFillItOnline/constants';

/**
 * Prepares the prescription to save in appropriate format for the backend
 *
 * @param {PrescriptionType} prescription - prescription before formatting
 * @returns {PrescriptionType} - prescription after formatting
 */
export const preparePrescriptionToSave = (prescription: PrescriptionType): PrescriptionType => {
    return {
        ...prescription,
        ...(prescription.isMultiPD
            ? {
                  pd: {
                      left: prescription.pd.left,
                      right: prescription.pd.right,
                      near: 'None',
                      singular: 'None',
                  },
              }
            : {
                  pd: {
                      near: prescription.pd.near,
                      singular: prescription.pd.singular,
                      left: 'None',
                      right: 'None',
                  },
              }),
    };
};

const CONFIRM_MESSAGE = 'You have not provided a prescription. Proceed anyway?';

/**
 * Checks if the prescription is empty
 */
export const handleEmptyPrescription = ({
    isPrescriptionEmpty,
    setActiveStep,
}: Pick<PropsType, 'isPrescriptionEmpty' | 'setActiveStep'>) => {
    if (!isPrescriptionEmpty) {
        setActiveStep('lens');
        return;
    }

    if (window.confirm(CONFIRM_MESSAGE)) {
        setActiveStep('lens');
    }
};

/**
 * Saves prescription if needed
 *
 * @param {boolean} isSavePrescription - whether a prescription should be saved
 * @param {string} prescriptionStep - prescription step
 * @param {PrescriptionType} prescription - prescription to save
 * @param {Function} savePrescription - save prescription handler
 */
export const checkAndSavePrescription = ({
    isSavePrescription,
    prescriptionStep,
    prescription,
    savePrescription,
}: {
    isSavePrescription: boolean;
    prescriptionStep: WizardFeatureReducerType['prescriptionStep'];
    prescription: PrescriptionType;
    savePrescription: (arg: {prescription: PrescriptionType; isUpdate: boolean}) => void;
}) => {
    if (isSavePrescription && prescriptionStep === PrescriptionStep.FillOnline) {
        savePrescription({
            prescription: preparePrescriptionToSave(prescription),
            isUpdate: false,
        });
    }
};

export const adapterPrescriptionMs = prescription => ({
    isMultiPD: Boolean(prescription.pd_left && prescription.pd_right),
    pd: {
        singular: prescription.pd,
        left: prescription.pd_left,
        right: prescription.pd_right,
    },
    od: {
        add: prescription.add_od,
        axi: prescription.axi_od,
        cyl: prescription.cyl_od,
        sph: prescription.sph_od,
    },
    os: {
        axi: prescription.axi_os,
        cyl: prescription.cyl_os,
        sph: prescription.sph_os,
    },
});
