import {ApplicationStoreType} from 'constants/flow/flowTypes';
import {WizardMsType} from 'features/wizard/store/reducers';

/**
 * Selector for wizardMs state
 *
 * @param {ApplicationStoreType} store - redux store
 * @returns {*} wizardMs state
 */
export const getWizardMs = (store: ApplicationStoreType): WizardMsType => store.wizardMs;

/**
 * Selector for planId on Configure page
 *
 * @param {ApplicationStoreType} store - redux store
 * @returns {number} planId for configure page
 */
export const getConfigurePlanId = (store: ApplicationStoreType): number =>
    store.wizardMs.configure.planId;
