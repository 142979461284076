import {AxiosResponse} from 'axios';
import {omit, equals, pathOr} from 'ramda';
import {createAction, createReducer} from 'redux-act';

import {
    CODE_FILE_UNDEFINED_ERROR,
    CODE_FILE_SIZE_ERROR,
    CODE_FILE_TYPE_ERROR,
} from 'constants/file/fileCode';
import {DOC, DOCX, PDF} from 'constants/file/fileTypes';
import {resetDataFromLocalStorage} from 'features/contacts/reducers/resetData/resetData';
import {
    SEND_EMAIL_OR_FAX_METHOD,
    PRESCRIPTION_UPLOAD_METHOD,
    FILL_IT_ONLINE_METHOD,
    NOT_SELECTED,
} from 'features/contacts/wizard/constants/wizard';
import {fetchQuoteItemV2} from 'features/wizard/store/reducers/configure/configure';
import {QuoteItemV2Type} from 'reducers/cart/addToCart/addToCartType';

export type MethodType =
    | typeof SEND_EMAIL_OR_FAX_METHOD
    | typeof PRESCRIPTION_UPLOAD_METHOD
    | typeof FILL_IT_ONLINE_METHOD
    | typeof NOT_SELECTED;

export type ErrorType =
    | typeof CODE_FILE_UNDEFINED_ERROR
    | typeof CODE_FILE_SIZE_ERROR
    | typeof CODE_FILE_TYPE_ERROR;

export type UploadedDataType = {
    type: 'success' | 'error';
    fileSrc?: string;
    fileName?: string;
    errorType?: ErrorType;
    fileType?: typeof PDF | typeof DOCX | typeof DOC;
};

export type PrescriptionType = {
    method: MethodType;
    isSamePrescriptionForBothEyes: boolean;
    uploadedData: UploadedDataType;
};

export const initialState: PrescriptionType = {
    method: NOT_SELECTED,
    isSamePrescriptionForBothEyes: false,
    uploadedData: {
        type: 'success',
    },
};

/**
 * it takes file name from url
 *
 * @param {string} url - url
 * @returns {string} file name
 * getFileNameFromUrl("/media/mobile_uploads/2020-09-23/6719cdb92623db12d8ea5588af2e4014.png") => "6719cdb92623db12d8ea5588af2e4014"
 * getFileNameFromUrl("") => ""
 */
const getFileNameFromUrl = (url: string) => {
    const splitUrl = url.split('/');
    return splitUrl[splitUrl.length - 1].split('.')[0];
};

/**
 * List of actions
 */
export const changePrescriptionMethod = createAction<MethodType>(
    'CONTACTS_WIZARD_SET_PRESCRIPTION_METHOD',
);

export const toggleSamePrescriptionForBothEyes = createAction(
    'CONTACTS_WIZARD_SET_SAME_PRESCRIPTION',
);

export const uploadFile = createAction<UploadedDataType>('CONTACTS_WIZARD_UPLOAD_FILE');

/**
 * Prescription reducer
 */
export const prescription = createReducer<PrescriptionType>({}, initialState)
    /**
     * Subscribe actions
     * https://www.npmjs.com/package/redux-act#typescript
     */
    .on(
        changePrescriptionMethod,
        (state: PrescriptionType, payload: MethodType): PrescriptionType => ({
            ...state,
            method: payload,
            uploadedData: initialState.uploadedData,
        }),
    )
    .on(
        uploadFile,
        (state: PrescriptionType, payload: UploadedDataType): PrescriptionType => ({
            ...state,
            uploadedData: payload,
            method: PRESCRIPTION_UPLOAD_METHOD,
        }),
    )
    .on(
        toggleSamePrescriptionForBothEyes,
        (state: PrescriptionType): PrescriptionType => ({
            ...state,
            isSamePrescriptionForBothEyes: !state.isSamePrescriptionForBothEyes,
        }),
    )
    .on(
        `${fetchQuoteItemV2}_FULFILLED`,
        (state: PrescriptionType, payload: AxiosResponse<QuoteItemV2Type>): PrescriptionType => {
            return {
                ...state,
                method: pathOr(
                    PRESCRIPTION_UPLOAD_METHOD,
                    ['prescription', 'method'],
                    payload.data,
                ),
                uploadedData: {
                    ...state.uploadedData,
                    fileSrc: pathOr('', ['prescription', 'link'], payload.data),
                    fileName: getFileNameFromUrl(
                        pathOr('', ['prescription', 'link'], payload.data),
                    ),
                },
                isSamePrescriptionForBothEyes: equals(
                    omit(['boxes'], pathOr({}, ['prescription', 'od'], payload.data)),
                    omit(['boxes'], pathOr({}, ['prescription', 'os'], payload.data)),
                ),
            };
        },
    )
    .on(resetDataFromLocalStorage, () => initialState);
