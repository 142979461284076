/* istanbul ignore file */
import React, {ChangeEvent, FC, FormEvent, useContext, useEffect, useState} from 'react';

import {RoundButton} from '@optimaxdev/design-system-u';
import {useMutation} from '@tanstack/react-query';
import cn from 'classnames';
import {useDispatch} from 'react-redux';
import InputMask from 'react-text-mask';

import {USER_MEMBER_TYPE} from 'constants/authorization';
import {CODE_MASK} from 'constants/masks/masks';
import {setToken} from 'reducers/oauth2';

import {sendCode} from '../../../api/customerMs/customerMs';
import {LoginPopupContext, LoginPopupContextType} from '../../../context/loginPopupContext';
import {sendMFAAnalytic} from '../analytics';
import {AUTH_STEPS} from '../twoFactorAuth';
import {WaitForCodePropsType} from '../types';
import {BottomCodeSection} from './bottomCodeSection';
import {PhoneNumberSection} from './phoneNumberSection';
import {sendAnalytics} from './sendAnalytics';

import s from './waitForCode.scss';

const CODE_LENGTH = 6;

export const WaitForCode: FC<WaitForCodePropsType> = ({
    setStep,
    onRequestClose,
    setAnalyticsStep,
}) => {
    const {
        phoneNumber,
        accountUuid,
        canEditPhone,
        isMember,
        isFullNameFrom,
        emailLoginData,
        memberLoginData,
    } = useContext(LoginPopupContext) as LoginPopupContextType;

    useEffect(() => {
        setAnalyticsStep(canEditPhone ? '2' : '2 - Verified Phone Number');
    }, [canEditPhone, setAnalyticsStep]);
    useEffect(() => {
        if (!canEditPhone) sendMFAAnalytic('Shown', '2 - Verified Phone Number', false);
    }, [canEditPhone]);

    const [state, setState] = useState({
        code: '',
        isDisabled: true,
        errorMessage: '',
    });
    const handleChange = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            ...(value.length === CODE_LENGTH ? {isDisabled: false} : {isDisabled: true}),
            code: value,
            errorMessage: '',
        });
    };
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setState({
            ...state,
            isDisabled: true,
        });
        mutate();
    };
    const dispatchAction = useDispatch();

    const {mutate, isLoading} = useMutation(() => sendCode(state.code, accountUuid), {
        onSuccess(data) {
            dispatchAction(
                setToken({
                    ...data,
                    memberType: isMember
                        ? USER_MEMBER_TYPE.UHC_MEMBER
                        : USER_MEMBER_TYPE.NOT_UHC_MEMBER,
                    settingsSafetyMode: !isMember,
                }),
            );
            sendMFAAnalytic('Success', canEditPhone ? 2 : '2 - Verified Phone Number');
            sendAnalytics(isMember, isFullNameFrom);
            if (canEditPhone) {
                setStep(AUTH_STEPS.SUCCESS_LOGIN);
            } else {
                onRequestClose();
            }
        },
        onError({response}) {
            const errorMessage = response.data.message;
            setState({...state, errorMessage});
            sendMFAAnalytic(
                `Error - ${errorMessage}`,
                canEditPhone ? 2 : '2 - Verified Phone Number',
            );
        },
    });
    return (
        <section className={s.wrap}>
            <PhoneNumberSection
                phoneNumber={phoneNumber}
                canEditPhone={canEditPhone}
                setStep={setStep}
            />
            <form onSubmit={handleSubmit}>
                <label
                    className={cn(s.inputWrap, {
                        [s.inputActive]: state.isDisabled && state.code,
                        [s.inputValid]: !state.isDisabled,
                        [s.inputError]: state.errorMessage,
                    })}
                >
                    <InputMask
                        mask={CODE_MASK}
                        guide={false}
                        onChange={handleChange}
                        value={state.code}
                        render={(ref, props) => <input {...props} placeholder="000000" ref={ref} />}
                    />
                </label>
                {state.errorMessage && <p className={s.errorText}>{state.errorMessage}</p>}

                <RoundButton
                    size="regular"
                    isDisabled={state.isDisabled || isLoading}
                    isLoading={isLoading}
                    type="submit"
                    className={s.verify}
                >
                    Verify
                </RoundButton>
            </form>
            <BottomCodeSection
                memberLoginData={memberLoginData}
                emailLoginData={emailLoginData}
                isMember={isMember}
                canEditPhone={canEditPhone}
                phoneNumber={phoneNumber}
            />
        </section>
    );
};
