// @flow
import React, {PureComponent, type Node} from 'react';
import {connect} from 'react-redux';
import RCPopup from 'rc-dialog';
import classNames from 'classnames';

import type {ApplicationStoreType, VoidFunctionType} from 'constants/flow/flowTypes';
import {type CmsPopupType, setCmsPopupVisibility} from 'reducers/cms/cmsPopup';

import s from './popup.scss';

type PropsType = {
    className?: string,
    zIndex?: number,
    wrapClassName?: string,
    visible: boolean,
    style?: {},
    onClose?: VoidFunctionType<> | null,
    isFull?: boolean,
    isShowCloseBtn?: boolean,
    cmsPopup: CmsPopupType,
    closePopup: VoidFunctionType<>,
    withWrapper?: boolean,
};

/**
 * Show content with blue close button
 */
export class Popup extends PureComponent<PropsType> {
    static defaultProps = {
        isFull: false,
        isShowCloseBtn: true,
        zIndex: '',
        wrapClassName: '',
        className: '',
        style: {},
        onClose: null,
        withWrapper: true,
    };

    /**
     * Returns category popup content depends by props
     *
     * @returns {Node} - Returned category popup content
     */
    getPopupContent = (): Node => {
        const {
            cmsPopup: {content},
        } = this.props;
        return content && content.html ? (
            <div dangerouslySetInnerHTML={{__html: content.html}} />
        ) : (
            content
        );
    };

    /**
     * Return popup content only without wrapper
     *
     * @memberof Popup
     * @returns {React.Node} - content without wrapper
     */
    getPopupContentWithoutWrapper = (): Node => {
        const {
            cmsPopup: {content},
        } = this.props;
        return content;
    };

    /**
     * Render jsx to html
     *
     * @returns {Node} Rendered react component
     */
    render(): Node {
        const {
            cmsPopup,
            isFull,
            isShowCloseBtn,
            closePopup,
            onClose,
            className,
            withWrapper,
        } = this.props;
        const {visibility, params} = cmsPopup;

        if (withWrapper === false) {
            return visibility ? this.getPopupContentWithoutWrapper() : null;
        }
        return (
            <RCPopup
                {...this.props}
                {...params}
                visible={visibility}
                onClose={onClose || closePopup}
                className={classNames(className, {
                    [s.full]: isFull,
                    [s.hideClose]: !isShowCloseBtn,
                })}
            >
                {this.getPopupContent()}
            </RCPopup>
        );
    }
}

export const mapStateToProps = (store: ApplicationStoreType) => ({
    ...store.cmsPopup.params,
    cmsPopup: store.cmsPopup,
});

export const mapDispatchToProps = {
    closePopup: () => setCmsPopupVisibility(false, null, {}),
};

export const PopupConnected = connect(mapStateToProps, mapDispatchToProps)(Popup);
