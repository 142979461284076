import {useEffect, useRef, useState} from 'react';

const RESEND_TIME = 60;
const SECONDS = 1000;

const countDownTime = counter => (counter > 0 ? counter - 1 : counter);

export const useCountDown: () => [
    number,
    (value: ((prevState: number) => number) | number) => void,
] = () => {
    const [counter, setCountDown] = useState(RESEND_TIME);
    const intervalId = useRef<number>();

    useEffect(() => {
        intervalId.current = window.setInterval(() => {
            setCountDown(countDownTime);
        }, SECONDS);
        return () => {
            if (intervalId.current) clearInterval(intervalId.current);
        };
    }, []);

    return [counter, setCountDown];
};
