import {ApplicationStoreType} from 'constants/typescript/types';
import {ShippingType} from 'reducers/shipping';

/**
 * Get shipping data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {ShippingType} - shipping data
 */
export const getShippingData = (store: ApplicationStoreType): ShippingType => store.shipping;

export const getShippingSelectedMethod = (store: ApplicationStoreType): string => {
    const shippingTitle = store.shipping.list.find(
        item => item.code === store.shipping.selectedMethod,
    );

    return shippingTitle?.title || '';
};

export const getShippingPrice = (store: ApplicationStoreType): number => {
    const shippingPrice = store.shipping.list.find(
        item => item.code === store.shipping.selectedMethod,
    )?.price;

    return shippingPrice || 0;
};
