// @flow
/**
 * Update history search value
 *
 * @param {object} searchParams - search params object
 */
export const updateUrlParams = (searchParams: {}) => {
    const {origin, pathname, hash} = window.location;

    const newSearch = searchParams.toString() === '' ? '' : `?${searchParams.toString()}`;
    const newUrl = `${origin}${pathname}${newSearch}${hash}`;

    window.history.replaceState(window.history.state, '', newUrl);
};
