import {AxiosResponse} from 'axios';
import {pathOr} from 'ramda';
import {createAction, createReducer} from 'redux-act';

import {toggleSamePrescriptionForBothEyes} from 'features/contacts/reducers/prescription/prescription';
import {resetDataFromLocalStorage} from 'features/contacts/reducers/resetData/resetData';
import {fetchQuoteItemV2} from 'features/wizard/store/reducers/configure/configure';
import {QuoteItemV2Type} from 'reducers/cart/addToCart/addToCartType';

import {
    PrescriptionSideStatesType,
    PrescriptionValuesType,
    PrescriptionSideValuesType,
} from './prescriptionValuesTypes';

export const initialState = {
    od: {
        sph: '',
        bc: '',
        dia: '',
        add: '',
        cyl: '',
        axi: '',
        color: '',
        boxes: 0,
    },
    os: {
        sph: '',
        bc: '',
        dia: '',
        add: '',
        cyl: '',
        axi: '',
        color: '',
        boxes: 0,
    },
    state_od: {
        sph: {disabled: false, error: ''},
        bc: {disabled: false, error: ''},
        dia: {disabled: false, error: ''},
        add: {disabled: false, error: ''},
        cyl: {disabled: false, error: ''},
        axi: {disabled: false, error: ''},
        color: {disabled: false, error: ''},
        boxes: {disabled: false, error: ''},
    },
    state_os: {
        sph: {disabled: false, error: ''},
        bc: {disabled: false, error: ''},
        dia: {disabled: false, error: ''},
        add: {disabled: false, error: ''},
        cyl: {disabled: false, error: ''},
        axi: {disabled: false, error: ''},
        color: {disabled: false, error: ''},
        boxes: {disabled: false, error: ''},
    },
    isQuantityTouched: false,
};

const getResetedErrors = (stateSide: PrescriptionSideStatesType) =>
    (Object.keys(stateSide) as (keyof PrescriptionSideStatesType)[]).reduce<{
        [key: string]: {disabled: boolean; error: string};
    }>(
        (acc, name) => ({
            ...acc,
            [name]: {
                disabled: stateSide[name].disabled,
                error: '',
            },
        }),
        {},
    );

/**
 * List of actions
 */
export const setFields = createAction<Partial<PrescriptionValuesType>>(
    'CONTACTS_WIZARD_SET_FIELDS',
);
export const resetFieldsState = createAction('CONTACTS_WIZARD_RESET_STATES');
export const fullResetFields = createAction('CONTACTS_WIZARD_FULL_RESET_FIELDS');

/**
 * Prescription values reducer
 */
export const prescriptionValues = createReducer<PrescriptionValuesType>({}, initialState)
    /**
     * Subscribe actions
     * https://www.npmjs.com/package/redux-act#typescript
     */
    .on(
        setFields,
        (
            state: PrescriptionValuesType,
            payload: {
                os?: Partial<PrescriptionSideValuesType>;
                od?: Partial<PrescriptionSideValuesType>;
                state_os?: Partial<PrescriptionSideStatesType>;
                state_od?: Partial<PrescriptionSideStatesType>;
                isQuantityTouched?: boolean;
            },
        ): PrescriptionValuesType => ({
            os: {...state.os, ...payload.os},
            od: {...state.od, ...payload.od},
            state_od: {...state.state_od, ...payload.state_od},
            state_os: {...state.state_os, ...payload.state_os},
            isQuantityTouched: payload.isQuantityTouched ?? state.isQuantityTouched,
        }),
    )
    .on(
        `${fetchQuoteItemV2}_FULFILLED`,
        (
            state: PrescriptionValuesType,
            payload: AxiosResponse<QuoteItemV2Type>,
        ): PrescriptionValuesType => {
            if (!('prescription' in payload.data) || !('od' in payload.data.prescription)) {
                return state;
            }
            return {
                ...state,
                od: {
                    ...(payload.data.prescription.od as PrescriptionSideValuesType),
                    color: pathOr('', ['color_code'], payload.data.prescription.od),
                },
                os: {
                    ...(payload.data.prescription.os as PrescriptionSideValuesType),
                    color: pathOr('', ['color_code'], payload.data.prescription.os),
                },
            };
        },
    )
    .on(
        resetFieldsState,
        (state: PrescriptionValuesType): PrescriptionValuesType => ({
            ...state,
            state_od: initialState.state_od,
            state_os: initialState.state_os,
        }),
    )
    .on(fullResetFields, (): PrescriptionValuesType => initialState)
    .on(toggleSamePrescriptionForBothEyes, state => ({
        ...state,
        os: {
            ...state.od,
            boxes: state.os.boxes,
        },
        state_od: {
            ...state.state_od,
            ...getResetedErrors(state.state_od as PrescriptionSideStatesType),
        },
        state_os: {
            ...state.state_os,
            ...getResetedErrors(state.state_os as PrescriptionSideStatesType),
        },
    }))
    .on(resetDataFromLocalStorage, () => initialState);
