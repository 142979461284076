// @flow
import {type Node} from 'react';

import {Reducer} from 'redux';
import {createActions, handleActions} from 'redux-actions';

import {type ActionType} from 'constants/flow/flowTypes';

import {type DialogType, type DialogOptionType} from './dialogType';

export const defaultState: DialogType = {
    content: null,
    options: {
        title: '',
        visibility: false,
        isFull: false,
    },
};

const defaultOptions = {
    title: '',
    visibility: true,
    isFull: false,
    isWelcomePopup: false,
    isStickyHeader: true,
};

export const {setDialog, closeDialog} = createActions({
    SET_DIALOG: (
        content: Node,
        {title, visibility, isFull, isStickyHeader = true}: DialogOptionType = defaultOptions,
    ): DialogType => ({
        content,
        options: {
            title: title || '',
            visibility: visibility || true,
            isFull: isFull || false,
            isStickyHeader,
        },
    }),
    CLOSE_DIALOG: () => {},
});

/**
 * Dialog
 *
 * @returns {Reducer<DialogType>} dialog data
 */
export const dialog: Reducer<DialogType> = handleActions(
    {
        [setDialog]: (state: DialogType, {payload}: ActionType<DialogType>): DialogType => ({
            ...payload,
        }),
        [closeDialog]: (): DialogType => defaultState,
    },
    defaultState,
);
