import {
    WizardOptionType,
    getOptions,
    WizardValueType,
    getIndependedOptions,
    getColors,
    getPreviousUpsells,
    getCurrentUpsells,
} from '@optimaxdev/wizard-ms';
import {createAction, createReducer} from 'redux-act';

import {FULFILLED} from 'constants/actionSuffix';
import {Maybe} from 'constants/typescript/types';
import {UsageItemType} from 'features/wizard/store/reducers/usages/usages';
import {fetchWizard} from 'features/wizard/store/reducers/wizardData/wizardData';

export type FormattedColorType = {
    items: Array<{
        colors: WizardValueType[];
        title: 'Colorized' | 'Mirrored' | 'Polarized';
        description: string;
    }>;
    title: string;
    parentNodeId: number;
};

export type ColorsType = {
    parentNodeId: number;
    title: string;
    items: WizardValueType[];
};

export type UpsellsType = {
    previousItems: Array<WizardOptionType>;
    items: Array<WizardOptionType>;
    independentOptions: Array<WizardOptionType>;
    colors: Maybe<FormattedColorType> | unknown;
};

export const initialState: UpsellsType = {
    previousItems: [],
    items: [],
    independentOptions: [],
    colors: null,
};

/**
 * Set all upsells by selected usage and active package
 */
export const setUpsells = createAction<{
    selectedUsage: UsageItemType;
    activePackage: WizardValueType;
}>('GET_WIZARD_UPSELLS', ({selectedUsage, activePackage}) => ({
    selectedUsage,
    activePackage,
}));

/**
 * Set sunglasses colors when usage was changed
 */
export const setColors = createAction<{
    selectedUsage: UsageItemType;
}>('SET_WIZARD_SUNGLASSES_COLORS', ({selectedUsage}) => ({
    selectedUsage,
}));

/**
 * Replace upsell which has depends on option
 */
export const replaceUpsell = createAction<{newItems: Array<WizardOptionType>; isActive: boolean}>(
    'REPLACE_WIZARD_UPSELL',
    ({newItems, isActive}) => ({
        newItems,
        isActive,
    }),
);

/**
 * Upsells reducer
 */
export const upsells = createReducer<UpsellsType>(
    /**
     * Subscribe actions
     * https://www.npmjs.com/package/redux-act#typescript
     */
    {
        [`${fetchWizard}_${FULFILLED}`]: (state, {data: {tree}}) => ({
            ...state,
            independentOptions: getIndependedOptions(tree),
        }),
        [`${setUpsells}`]: (state: UpsellsType, {activePackage, selectedUsage}) => ({
            ...state,
            items: getOptions(selectedUsage, activePackage),
        }),
        [`${setColors}`]: (state: UpsellsType, {selectedUsage}) => ({
            ...state,
            colors: getColors(selectedUsage),
        }),
        [`${replaceUpsell}`]: (state: UpsellsType, {newItems, isActive}) => {
            const items = {
                newItems,
                currentItems: state.items,
                previousItems: state.previousItems,
            };

            return {
                ...state,
                previousItems: getPreviousUpsells(items, isActive),
                items: getCurrentUpsells(items, isActive),
            };
        },
    },
    initialState,
);
