import {createActions, handleActions, ActionType} from 'redux-actions';

import {FORGOT_EMAIL, NEW_PASSWORD, LOGIN} from 'constants/formNames';

import {FieldType, ClearFieldType, FormDataType} from './index';

export const defaultState: FormDataType = {
    [FORGOT_EMAIL]: {email: ''},
    [NEW_PASSWORD]: {
        password: '',
        confirmation: '',
    },
    [LOGIN]: {
        email: '',
        password: '',
    },
};

export const {updateFieldForm, setFieldForm, clearFieldForm, clearFormData, clearAllFormData} =
    createActions({
        UPDATE_FIELD_FORM: (
            name: string,
            value: string | number,
            formName: string,
        ): ActionType<FieldType> => ({
            name,
            value,
            formName,
        }),
        SET_FIELD_FORM: (data, formName: string): ActionType<FieldType> => ({
            data,
            formName,
        }),
        CLEAR_FIELD_FORM: (name: string, formName: string): ActionType<ClearFieldType> => ({
            name,
            formName,
        }),
        CLEAR_FORM_DATA: (formName: string): ActionType<ClearFieldType> => ({
            formName,
        }),
        CLEAR_ALL_FORM_DATA: () => {},
    });

export const formData = handleActions(
    {
        [updateFieldForm]: (
            state: FormDataType,
            {payload: {name, value, formName}}: ActionType<typeof updateFieldForm>,
        ): FormDataType => ({
            ...state,
            [formName]: {
                ...state[formName],
                [name]: value,
            },
        }),
        [setFieldForm]: (
            state: FormDataType,
            {payload: {data, formName}}: ActionType<typeof updateFieldForm>,
        ): FormDataType => ({
            ...state,
            [formName]: {
                data,
            },
        }),
        [clearFieldForm]: (
            state: FormDataType,
            {payload: {name, formName}}: ActionType<typeof updateFieldForm>,
        ): FormDataType => ({
            ...state,
            [formName]: {
                ...state[formName],
                [name]: '',
            },
        }),
        [clearFormData]: (
            state: FormDataType,
            {payload: {formName}}: ActionType<typeof updateFieldForm>,
        ): FormDataType => ({
            ...state,
            [formName]: defaultState[formName],
        }),
        [clearAllFormData]: (): FormDataType => defaultState,
    },
    defaultState,
);
