import {useEffect} from 'react';

import {pushDataLayer, REACTPV} from '@optimaxdev/analytics/desktop';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {setPage} from 'reducers/route/route';

import {PAGE_PARAM2} from '../../../constants/analytics';

type PropsType = {
    page: string;
    params: Record<string, string | number>;
    event?: string;
    page_type_param2?: string;
};
export const useSetPage = ({
    page,
    params,
    event = REACTPV.OTHER,
    page_type_param2 = PAGE_PARAM2.OTHER,
}: PropsType): void => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        dispatch(
            setPage({
                page,
                params,
            }),
        );
        pushDataLayer({event});
        pushDataLayer({page_type_param2});
        // searchParams in deps to imitate works of searchParamIsUpdated action to trigger saga with data loading
    }, [dispatch, event, page, params, searchParams, page_type_param2]);
};
