// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';

import type {PopupParamsType} from 'constants/flow/flowTypes';

import type {CmsPopupType} from './cmsPopupType';

export const defaultState = {
    visibility: false,
    content: null,
    params: {},
};

export const {setCmsPopupVisibility, closeCmsPopup} = createActions({
    SET_CMS_POPUP_VISIBILITY: (
        visibility: boolean,
        content: Node,
        params: PopupParamsType = {},
    ): CmsPopupType => ({
        visibility,
        content,
        params,
    }),
    CLOSE_CMS_POPUP: () => {},
});

/**
 * CMS popup
 *
 * @returns {CmsPopupType} popup data
 */
export const cmsPopup = handleActions(
    {
        [setCmsPopupVisibility]: (
            state: CmsPopupType,
            {payload}: ActionType<typeof setCmsPopupVisibility>,
        ): CmsPopupType => ({...payload}),
        [closeCmsPopup]: (): CmsPopupType => defaultState,
    },
    defaultState,
);
