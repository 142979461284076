/* eslint-disable no-bitwise */
import {range, map, curry, reduce, toPairs, replace} from 'ramda/src';
import {ActionType} from 'redux-actions';

import {ApplicationStoreType} from 'constants/typescript/types';
import {CartType} from 'reducers/cart';
import {CartProductsType} from 'reducers/cartProducts';
import {MyPlanType} from 'reducers/myPlan';
import {OrderType} from 'reducers/order';
import {OrdersType} from 'reducers/ordersInfo';
import {ProductChildrenType, ProductReducerType, ProductType} from 'reducers/product';
import {UserReducerType} from 'reducers/user';

/**
 * Range step
 *
 * @param {number} start - Starting position
 * @param {number} step - Number step
 * @param {number} stop - Final position
 * @returns {Array<number>} - Returns an array of numbers
 * @example
 *
 *      rangeStep(1, 2, 10);    //=> [1, 3, 5, 7, 9]
 */
export const rangeStep = (start: number, step: number, stop: number) =>
    map(n => start + step * n, range(0, (1 + (stop - start) / step) >>> 0));

export type AutoFillFieldsType = {[key: string]: string}[];

/**
 * Fill form field with user data
 *
 * @param {AutoFillFieldsType} data - fields and values to autofill
 * @param {ActionType} formUpdater - passed action
 */
export const formFiller = (data: AutoFillFieldsType, formUpdater: ActionType) => {
    data.forEach(field => formUpdater(field.fieldName, field.value));
};

/**
 * Format template string by passed object.
 *
 * @param {string} template - Template string.
 * @param {MapType<string, any>} - Source object where key is name of replacement template {{key}} and value is what should be placed.
 * @returns {string} - Formatted string.
 *
 * @example
 * formatString(
 *     '{{murderer}} knocked out {{victim}} with {{weapon}}',
 *     {murderer: 'Favoreet', victim: 'Khvmkh', weapon: 'Pan'},
 * ); //=> 'Favoreet knocked out Khvmkh with Pan'
 */
export const formatString = curry((template: string, source: {[key: string]: string}) =>
    reduce((acc, [key, value]) => replace(`{{${key}}}`, value, acc), template, toPairs(source)),
);

/**
 
 * Function for open link in new tab
 *
 * @param {string} url - Link for opening in new tab
 * @returns {Window|null} Opened window
 */
export function openLinkInNewTab(url: string): Window | null {
    const tab = window.open(url, '_blank');
    if (tab !== null) {
        tab.focus();
    }
    return tab;
}

/**
 
 * Depending on value make a word plural or singular
 *
 * @param {string} word for pluralising
 * @param {number} value for word
 * @returns {string} plural or singular form of a word
 */
export const pluralise = (word: string, value: number): string => {
    if (value === 1) return word;
    const trimmedWord = word.trim();
    if (
        ['s', 'x', 'z'].includes(trimmedWord.slice(-1)) ||
        ['sh', 'ch'].includes(trimmedWord.slice(-2))
    ) {
        return `${trimmedWord}es`;
    }
    return `${trimmedWord}s`;
};
type UpdatedMyPlanType = MyPlanType & {
    package: {
        title: string;
        data: null;
    };
};
type SentryStateType = {
    cartProducts: CartProductsType;
    user: UserReducerType;
    myPlan: UpdatedMyPlanType;
    ordersInfo: OrdersType;
    order: OrderType;
    product: ProductReducerType;
    cart: CartType;
};
export const filterStateForSentry = (state: ApplicationStoreType): SentryStateType => {
    const {cartProducts, user, myPlan, ordersInfo, order, product, cart} = state;

    return {
        cartProducts,
        user,
        myPlan: {
            ...myPlan,
            package: {
                ...myPlan?.discounts?.package,
                data: null,
            },
        },
        ordersInfo,
        order,
        product: {
            ...product,
            item: {
                ...product?.item,
                children: [],
            },
        },
        cart,
    };
};

export const convertObjectToFormData = (data: Record<string, string>) => {
    const formData = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return formData;
};

export const getProductChildren = (data: ProductType[]): ProductChildrenType[] => {
    const res: ProductChildrenType[] = [];

    data.forEach(item => {
        if (item.children.length > 0) {
            item.children.forEach(childItem => {
                res.push(childItem);
            });
        }
    });

    return res;
};

export const filterEmail = (email: string): string =>
    email.includes('@uhcglasses.com') ? '' : email;

/**
 
 * isExist
 *
 * @param {string} value - value
 * @returns {boolean} return true if value exist
 */
export const isExist = value => value && value !== '' && value !== 0 && !value.isNaN;
