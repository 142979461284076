export const PHONE_MASK = [
    '+',
    '1',
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
export const PHONE_MASK_FORMAT = /^(1|)?(\d{3})(\d{3})(\d{4})$/;
export const CODE_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
