export const PRODUCT_TYPE = {
    OPTICAL: 225,
    RX_SUNGLASSES: 227,
    SUNGLASSES: 226,
    CONTACT_LENSES: 9483,
} as const;

export const PRODUCT_TYPES = {
    [PRODUCT_TYPE.OPTICAL]: {
        title: 'Optical',
        icon: 'opticalType',
    },
    [PRODUCT_TYPE.RX_SUNGLASSES]: {
        title: 'Rx.Sunglasses',
        icon: 'rxSunglassesType',
    },
    [PRODUCT_TYPE.SUNGLASSES]: {
        title: 'Sunglasses',
        icon: 'sunglassesType',
    },
} as const;

export const PRODUCT_TYPES_NEW: {
    READERS: 'readers';
    SUNGLASSES: 'sunglasses';
    ACCESSORY: 'accessories';
    EYEGLASSES: 'eyeglasses';
    CONTACT_LENSES: 'contact_lenses';
    UPGRADE: 'upgrade';
} = {
    READERS: 'readers',
    SUNGLASSES: 'sunglasses',
    ACCESSORY: 'accessories',
    EYEGLASSES: 'eyeglasses',
    CONTACT_LENSES: 'contact_lenses',
    UPGRADE: 'upgrade',
};

export const PRODUCT_VIEW_TYPE = {
    FRONT: 'front',
    FRAME: 'frame',
    ROTATION_180: '180rotation',
    SUNGLASSES_180: '180sunglasses',
    MEASUREMENT: 'measurement',
    MEN_180: '180men',
    WOMEN_180: '180women',
    ROTATION_360: '360rotation',
    TRY_ON: 'tryon',
    PERFECT_TRY_ON: 'perfectTryOn',
} as const;

export const PRODUCT_VIEW_TYPES = {
    [PRODUCT_VIEW_TYPE.ROTATION_360]: {
        title: 'Rotation',
        icon: 'rotation360',
    },
    [PRODUCT_VIEW_TYPE.MEN_180]: {
        title: 'M. Model',
    },
    [PRODUCT_VIEW_TYPE.WOMEN_180]: {
        title: 'F. Model',
    },
};
