// @flow
import type {ApplicationStoreType} from 'constants/flow/flowTypes';
import type {CreditCardType} from 'reducers/creditCard';

/**
 * Get credit card data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {CreditCardType} - credit card data
 */
export const getCreditCardData = (store: ApplicationStoreType): CreditCardType => store.creditCard;
