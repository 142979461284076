/* eslint-disable complexity */

/**
 * Set authorization header
 *
 * @param {{getState: object}} data - event
 * @param {object} request - axios error object
 *
 * @returns {object} error or dispatch action
 */
export function setAuthorizationHeaderInterceptor({getState}, request) {
    const {access_token: accessToken} = getState().oauth2Data;
    const isUserAuthorized = accessToken !== '';
    if (Object.keys(request.headers).includes('Authorization') && isUserAuthorized) {
        request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
}
