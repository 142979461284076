import {PrescriptionType} from '../../reducers/prescription';

export type PrescriptionAdapterV2Type = {
    id: number;
    name: string;
    comment: string;
    method: number;
    data: {
        sph_os: string;
        sph_od: string;
        cyl_os: string;
        cyl_od: string;
        axi_os: string;
        axi_od: string;
        add_os: string;
        add_od: string;
        pd: string;
        pd_right: string;
        pd_left: string;
        pd_near: string;
    };
};

/**
 * Adapter to flat prescription
 *
 * @returns {PrescriptionAdapterV2Type} PrescriptionAdapterV2Type
 * @param {PrescriptionType} data - prescription data
 */
export const mapToFlatV2EndpointPrescription = (
    data: PrescriptionType,
): PrescriptionAdapterV2Type => {
    return {
        data: {
            sph_os: data.os.sph,
            sph_od: data.od.sph,
            cyl_os: data.os.cyl,
            cyl_od: data.od.cyl,
            axi_os: data.os.axi,
            axi_od: data.od.axi,
            add_os: data.os.add,
            add_od: data.od.add,
            pd: data.pd.singular,
            pd_right: data.pd.right,
            pd_left: data.pd.left,
            pd_near: data.pd.near,
        },
        id: data.id,
        name: data.name,
        comment: data.comment,
        method: data.method,
    };
};
