// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';

import {getSortParams} from 'libs/url';

import type {SortType} from './categorySortType';

export const defaultState = getSortParams();

export const {setSortOrder, setDefaultSortOrder, setSortOrderFromUrl} = createActions({
    SET_SORT_ORDER: (dir: string, order: string): ActionType<SortType> => ({dir, order}),
    SET_DEFAULT_SORT_ORDER: (): ActionType<> => {},
    SET_SORT_ORDER_FROM_URL: (): ActionType<> => {},
});

/**
 * Category sort order reducer
 *
 * @returns {SortType} state - next state
 */
export const categorySort = handleActions(
    {
        [setSortOrder]: (
            state: SortType,
            {payload: {dir, order}}: ActionType<typeof setSortOrder>,
        ): SortType => ({dir, order}),
        [setDefaultSortOrder]: (): SortType => ({dir: '', order: ''}),
        [setSortOrderFromUrl]: (): SortType => getSortParams(),
    },
    defaultState,
);
