import React, {FC} from 'react';

import flags from 'constants/flags/flags';
import {ALLOWANCE_TYPE, PLAN_FRAMES_TYPE} from 'constants/plan';
import {FilteredPlanType} from 'libs/hooks/useFilteredPlan';

import {PlanAllowanceRow} from './planAllowanceRow';
import {PlanTable} from './planTable';

import s from './plan.scss';

type PropsType = {
    planData: FilteredPlanType;
};

/**
 * User plan displaying
 */
export const Plan: FC<PropsType> = ({
    planData: {frameAllowance, frameAllowanceForCL, frames, lenses, upgrades, coatings},
}) => {
    const isUHCCL = flags.dUHCCL.isEnabled();
    const planFrame = frames.find(frame => frame.description === PLAN_FRAMES_TYPE.contacts);
    const isClTitle = isUHCCL && planFrame?.discount && planFrame.discount > 0;
    const titleFrames = isClTitle ? 'Frames or Contact Lenses' : 'Frames';

    return (
        <div className={s.columns}>
            {frameAllowance?.description === ALLOWANCE_TYPE.FLAT ? (
                <div>
                    <PlanAllowanceRow frameAllowance={frameAllowance} />
                </div>
            ) : (
                <>
                    <div>
                        {(frameAllowance || frameAllowanceForCL) && (
                            <PlanAllowanceRow
                                frameAllowance={frameAllowance}
                                frameAllowanceForCL={frameAllowanceForCL}
                            />
                        )}
                        <PlanTable title={titleFrames} rows={frames} />
                        <PlanTable title="Lenses" rows={lenses} />
                    </div>
                    <div>
                        <PlanTable title="Upgrades" rows={upgrades} />
                        <PlanTable title="Coatings" rows={coatings} />
                    </div>
                </>
            )}
        </div>
    );
};
