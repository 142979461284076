import React from 'react';

import flags from 'constants/flags/flags';

import {FeaturesContext} from './featureProvider';

type FeatureFlagsKey = keyof typeof flags;
type FeaturesType = {
    [key: string]: () => boolean;
};

type PropsType = {
    flag: FeatureFlagsKey;
    children?: React.ReactNode;
    fallback?: React.ReactNode;
};

const features: FeaturesType = {
    ...Object.keys(flags).reduce<FeaturesType>((acc, key) => {
        return {
            ...acc,
            [key]: () => flags[key].isEnabled(),
        };
    }, {}),
};

/**
 * Feature component, accept name of the flag name,
 * feature component as a child and callback if needed
 *
 * @param {PropsType} component props
 * @returns {React.ReactNode} Feature component
 */
export const Feature: React.FC<PropsType> = ({children, fallback, flag}: PropsType) => {
    if (!features[flag]) return <>{fallback}</>;

    return (
        <FeaturesContext.Consumer>
            {() => (features[flag]() ? children : fallback)}
        </FeaturesContext.Consumer>
    );
};

Feature.defaultProps = {children: null, fallback: null};
