import {createAction, createReducer} from 'redux-act';

/**
 * List of actions
 */
export const setBoxOption = createAction<string>('CONTACTS_WIZARD_SET_BOX_OPTION');

/**
 * Box option reducer
 */
export const selectedBoxOption = createReducer({}, 'Customize Quantity').on(
    setBoxOption,
    (_, payload) => payload,
);
