import {COMMON_INPUTS_REGEX, InputFieldDescriptionType} from 'libs/validation/validateForm';
import {setToken as setTokenAction} from 'reducers/oauth2';
import {setUser as setUserAction} from 'reducers/user';

import {LoginPopupNames} from '../loginPopup';

export type PropsType = {
    isError: boolean;
    setPopup: (popup: LoginPopupNames) => void;
    onRequestClose: () => void;
    setToken: typeof setTokenAction;
    setUser: typeof setUserAction;
};
export const INPUTS: InputFieldDescriptionType[] = [
    {
        name: 'firstName',
        type: 'text',
        placeholder: 'First Name',
        autoComplete: 'given-name',
        error: 'First name is not valid',
        mask: false,
        regex: COMMON_INPUTS_REGEX.userInitials,
    },
    {
        name: 'lastName',
        type: 'text',
        placeholder: 'Last Name',
        autoComplete: 'family-name',
        error: 'Last name is not valid',
        mask: false,
        regex: COMMON_INPUTS_REGEX.userInitials,
    },
    {
        name: 'email',
        type: 'email',
        placeholder: 'Email',
        autoComplete: 'email',
        error: 'Email is not valid',
        mask: false,
        regex: COMMON_INPUTS_REGEX.email,
    },
    {
        name: 'password',
        type: 'password',
        placeholder: 'Password',
        autoComplete: 'new-password',
        error: 'Password is not valid',
        mask: false,
        regex: COMMON_INPUTS_REGEX.password,
    },
];
