import axios from 'axios';
import {LoaderFunctionArgs, redirect} from 'react-router-dom';

import {API_URL_ROUTE} from 'constants/api';
import {STORE_ID} from 'constants/store';
import {axiosClient} from 'libs/configureStore';

export type routeMsResponseType = {
    id: number;
    type: string;
    path: string;
    redirect?: string;
};

export const dynamicPageLoader = async ({
    params,
}: LoaderFunctionArgs): Promise<routeMsResponseType | Response> => {
    const {'*': url = ''} = params;
    return await axiosClient
        .get<routeMsResponseType>(`${API_URL_ROUTE}${STORE_ID}/${url}`)
        .then(res => (res.data.redirect ? redirect(res.data.redirect) : res.data))
        .catch(err => {
            if (axios.isAxiosError(err) && err.response?.status === 404)
                return {
                    id: 0,
                    type: '404',
                    path: url,
                } as routeMsResponseType;
            throw err;
        });
};
