import {AxiosResponse} from 'axios';
import {createActions, handleActions, ActionType} from 'redux-actions';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';

import {Status} from '../../constants/reducerStatus';

type StoreCreditType = {errorMessage: string; status: string};
export const defaultState = {errorMessage: '', status: ''};

export const {applyCoupon, removeCoupon, clearStoreCreditData} = createActions({
    APPLY_COUPON: (coupon: string): ActionType<AxiosResponse> => {
        return {
            request: {
                method: 'POST',
                url: `${ENDPOINTS.APPLY_COUPON}?couponCode=${coupon}`,
                headers: {Authorization: ''},
            },
        };
    },
    REMOVE_COUPON: (coupon: string): ActionType<AxiosResponse> => {
        return {
            request: {
                method: 'POST',
                url: `${ENDPOINTS.REMOVE_COUPON}?couponCode=${coupon}`,
                headers: {Authorization: ''},
            },
        };
    },
    CLEAR_STORE_CREDIT_DATA: () => ({}),
});

export const storeCredit = handleActions(
    {
        [`${applyCoupon}${REJECTED}`]: (
            state: StoreCreditType,
            {
                payload: {
                    error: {response},
                },
            },
        ): StoreCreditType => ({
            ...state,
            errorMessage: response.data.messages.filter(el => el.type === 'error').reverse()[0]
                .message,
            status: Status.Rejected,
        }),
        [`${applyCoupon}${FULFILLED}`]: (state: StoreCreditType): StoreCreditType => ({
            ...state,
            errorMessage: '',
            status: Status.Fulfilled,
        }),
        [applyCoupon]: (state: StoreCreditType): StoreCreditType => ({
            ...state,
            errorMessage: '',
            status: Status.Pending,
        }),
        [clearStoreCreditData]: (state: StoreCreditType): StoreCreditType => ({
            ...state,
            ...defaultState,
        }),
        [`${removeCoupon}${FULFILLED}`]: (state: StoreCreditType): StoreCreditType => ({
            ...state,
        }),
    },
    defaultState,
);
