import {pick} from 'ramda';
import {createAction, createReducer} from 'redux-act';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {API_URL_CUSTOMER} from 'constants/api';
import {Status} from 'constants/reducerStatus';
import {RequestType, ResponseType} from 'constants/typescript/types';

import {prescriptionAdapter} from './savedPrescriptionsAdapter';
import {UserReducerType, UserDiscountType, UserResponseType} from './userType';

export const defaultState: UserReducerType = {
    id: 0,
    email: '',
    firstname: '',
    lastname: '',
    billingAddressId: null,
    shippingAddressId: null,
    addresses: [],
    prescriptions: [],
    newFormatPrescriptions: [],
    discount: {
        group_id: 0,
        frame_discount: {},
        status: Status.Idle,
    },
    uvp: null,
    planId: 0,
    status: Status.Idle,
    insuranceCompany: null,
    isEligible: false,
};

export const getUser = createAction<RequestType>('GET_USER', () => ({
    request: {
        baseURL: API_URL_CUSTOMER,
        headers: {Authorization: ''},
        method: 'GET',
        url: 'me',
    },
}));
export const getUserDiscount = createAction<RequestType>('GET_USER_DISCOUNT', () => ({
    request: {
        headers: {Authorization: ''},
        method: 'GET',
        url: '/backend/api/rest/customer/group/current',
    },
}));

export const clearUser = createAction('CLEAR_USER');

export const setUser = createAction<UserResponseType>('SET_USER');

/**
 * User data reducer
 */
export const user = createReducer<UserReducerType>({}, defaultState)
    .on(
        getUser,
        (state): UserReducerType => ({
            ...state,
            status: Status.Pending,
        }),
    )
    .on(
        `${getUser}${FULFILLED}`,
        (state, {data}: ResponseType<UserResponseType>): UserReducerType => ({
            ...state,
            ...pick(
                [
                    'id',
                    'firstname',
                    'lastname',
                    'billingAddressId',
                    'shippingAddressId',
                    'addresses',
                    'prescriptions',
                ],
                data,
            ),
            email: data.email,
            uvp: data.uvp
                ? {
                      ...data.uvp,
                      originalEmail: (data.uvp as {original_email?: string}).original_email || null,
                  }
                : null,
            newFormatPrescriptions: prescriptionAdapter(data.prescriptions),
            status: Status.Fulfilled,
        }),
    )
    .on(
        getUserDiscount,
        (state): UserReducerType => ({
            ...state,
            discount: {
                ...state.discount,
                status: Status.Pending,
            },
        }),
    )
    .on(
        `${getUserDiscount}${REJECTED}`,
        (state): UserReducerType => ({
            ...state,
            discount: {
                ...state.discount,
                status: Status.Rejected,
            },
        }),
    )
    .on(
        `${getUserDiscount}${FULFILLED}`,
        (
            state,
            {
                data,
            }: ResponseType<
                UserDiscountType & {
                    plan_id: number;
                    insuranceCompany: string | null;
                    isEligible: boolean;
                }
            >,
        ) => ({
            ...state,
            discount: {
                ...pick(['group_id', 'frame_discount'], data),
                status: Status.Fulfilled,
            },
            planId: data.plan_id,
            insuranceCompany: data.insuranceCompany,
            isEligible: data.isEligible,
        }),
    )
    .on(clearUser, () => defaultState)
    .on(setUser, (state, userDetails: UserResponseType) => ({
        ...state,
        ...userDetails,
    }));
