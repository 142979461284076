import {createAction, createReducer} from 'redux-act';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';
import {RequestType} from 'constants/typescript/types';

import {ShippingType} from './shippingType';

export const defaultState: ShippingType = {
    list: [],
    countryCode: '',
    selectedMethod: '',
    isLoading: false,
};
export const saveShippingMethod = createAction<string, RequestType>(
    'SAVE_SHIPPING_METHOD',
    (code: string) => ({
        request: {
            method: 'PUT',
            headers: {
                Authorization: '',
            },
            url: ENDPOINTS.SAVE_SHIPPING_METHOD,
            data: {shipping_method: code},
        },
    }),
);
export const getShippingMethods = createAction<string, RequestType, string>(
    'GET_SHIPPING_METHODS',
    (id: string) => ({
        request: {
            method: 'GET',
            headers: {
                Authorization: '',
            },
            url: ENDPOINTS.GET_SHIPPING_METHODS,
            params: {
                country: id,
            },
        },
    }),
    (id: string) => id,
);
export const setShippingMethod = createAction<string, string>(
    'SET_SHIPPING_METHOD',
    (code: string) => code,
);

export const resetShippingMethod = createAction('RESET_SHIPPING_METHOD');

/**
 * Shipping reducer
 *
 * @returns {Reducer<ShippingType>} state - next state
 */
export const shipping = createReducer<ShippingType>(
    {
        [`${getShippingMethods}${FULFILLED}`]: (state, {data}, meta): ShippingType => ({
            ...state,
            list: data,
            countryCode: meta,
            selectedMethod: data.length > 0 ? state.selectedMethod || data[0].code : '',
            isLoading: false,
        }),
        [getShippingMethods.toString()]: (state): ShippingType => ({
            ...state,
            isLoading: true,
        }),
        [`${getShippingMethods}${REJECTED}`]: (state): ShippingType => ({
            ...state,
            isLoading: false,
        }),
        [setShippingMethod.toString()]: (state: ShippingType, code: string): ShippingType => ({
            ...state,
            selectedMethod: code,
        }),
        [resetShippingMethod.toString()]: () => defaultState,
    },
    defaultState,
);
