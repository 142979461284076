// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';

import type {CategoryColorsType} from './categoryColorsType';

export const defaultState = {};

export const {setActiveColor} = createActions({
    SET_ACTIVE_COLOR: (parentId: number, id: number): ActionType<CategoryColorsType> => ({
        parentId,
        id,
    }),
});

/**
 * Category colors reducer
 *
 * @returns {CategoryColorsType} state - next state
 */
export const categoryColors = handleActions(
    {
        [setActiveColor]: (
            state: CategoryColorsType,
            {payload}: ActionType<typeof setActiveColor>,
        ): CategoryColorsType => ({
            ...state,
            [payload.parentId]: payload.id,
        }),
    },
    defaultState,
);
