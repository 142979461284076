// @flow
import type {ApplicationStoreType} from 'constants/flow/flowTypes';

/**
 * Get password error message
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {string} - Password error message
 */
export const getPasswordErrorMessage = (store: ApplicationStoreType): string =>
    store.newPassword.error;
