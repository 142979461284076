/* eslint-disable complexity */
import {
    ALLOWANCE_TYPE,
    lensesProperties,
    upgradesProperties,
    PropertiesType,
    framesProperties,
    coatingsProperties,
} from 'constants/plan';

import {usePlanRequest, BenefitType, DiscountDataObjType, DiscountType} from '../usePlanRequest';

export type FilteredPlanType = {
    firstName: string;
    lastName: string;
    frameAllowance: BenefitType | null;
    frameAllowanceForCL?: BenefitType | null;
    frames: FilteredPlanItemType;
    lenses: FilteredPlanItemType;
    upgrades: FilteredPlanItemType;
    coatings: FilteredPlanItemType;
    isFetching: boolean;
};

export type FilteredPlanItemType = Array<BenefitType | DiscountType>;

// Temporary solution - havent data from api
const coatingsBenefits = [
    {
        description: 'Anti-scratch coating',
        copay: 0,
        allowance: 0,
        priceProtection: 0,
    },
    {
        description: 'Anti-glare coating',
        copay: 0,
        allowance: 0,
        priceProtection: 0,
    },
    {
        description: 'UV protection',
        copay: 0,
        allowance: 0,
        priceProtection: 0,
    },
];

const defaultState: FilteredPlanType = {
    firstName: '',
    lastName: '',
    frameAllowance: null,
    frameAllowanceForCL: null,
    frames: [],
    lenses: [],
    upgrades: [],
    coatings: [],
    isFetching: false,
};

export const discountHandler = (discount?: DiscountType) =>
    !discount || discount.discount === 0 ? null : discount;

export const getAllowance = (benefits: Array<BenefitType>) =>
    benefits.find(
        ({description}) =>
            description === ALLOWANCE_TYPE.FRAME || description === ALLOWANCE_TYPE.FLAT,
    ) || null;
const getAllowanceForCL = (benefits: Array<BenefitType>) =>
    benefits.find(({description}) => description === ALLOWANCE_TYPE.CONTACT_LENSES) || null;

export const getFilteredItem = (
    benefits: Array<BenefitType>,
    discounts: DiscountDataObjType,
    properties: PropertiesType,
) =>
    properties.reduce((acc, {benefit, discount, viewName}) => {
        const result =
            discountHandler(discounts[discount]) ||
            benefits.find(({description}) => description === benefit) ||
            null;

        return result ? [...acc, {...result, description: viewName}] : acc;
    }, [] as FilteredPlanItemType);

export const useFilteredPlan = () => {
    const {data, isFetching} = usePlanRequest();

    return data?.data
        ? {
              firstName: data.data.first_name,
              lastName: data.data.last_name,
              frameAllowanceForCL: getAllowanceForCL(data.data.benefits || []),
              frameAllowance: getAllowance(data.data.benefits || []),
              // TODO: Optimize getFilteredItem function's params
              frames: getFilteredItem(
                  data.data.benefits || [],
                  data.data.discounts?.frame?.data || {},
                  framesProperties,
              ),
              lenses: getFilteredItem(
                  data.data.benefits || [],
                  data.data.discounts?.package?.data || {},
                  lensesProperties,
              ),
              upgrades: getFilteredItem(
                  [],
                  data.data.discounts?.packageOption?.data || {},
                  upgradesProperties,
              ),
              coatings: getFilteredItem(
                  data.data.benefits ? coatingsBenefits : [],
                  {},
                  coatingsProperties,
              ),
              isFetching,
          }
        : {...defaultState, isFetching};
};
