// @flow
import {type SagaIterator} from 'redux-saga';
import {select, call, put, takeEvery, all} from 'redux-saga/effects';

import {FULFILLED} from 'constants/actionSuffix';
import {getSearchParams} from 'libs/url';
import {inputValue, sendFormData, clearForm, clearMessage} from 'reducers/contactUsForm';
import {setPage} from 'reducers/route';
import {getUserData} from 'selectors/customer/customer';
import {getPageData} from 'selectors/page/getPageData';

/**
 * Fetch order data
 */
export function* setFormData(): SagaIterator {
    const {page} = yield select(getPageData);

    if (page !== 'contact-us') return;

    const {firstname, email} = yield select(getUserData);
    const {action, orderNumber = ''} = yield call(getSearchParams);

    if (action === 'uploadPrescription') {
        const message = `Hi. I want to upload the prescription to order #${orderNumber}`;

        yield all([
            put(inputValue('name', firstname)),
            put(inputValue('email', email)),
            put(inputValue('message', message)),
        ]);
    } else {
        yield put(clearMessage());
    }
}

/**
 * Reset form after send data to backend
 */
export function* clearFields(): SagaIterator {
    yield put(clearForm());
}

/**
 * Begin of saga
 */
export function* contactUsSaga(): SagaIterator {
    yield takeEvery(setPage, setFormData);
    yield takeEvery(`${sendFormData}${FULFILLED}`, clearFields);
}
