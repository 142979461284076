export const ACCOUNT_FIELDS = [
    {name: 'firstname', placeholder: 'First name'},
    {name: 'lastname', placeholder: 'Last Name'},
    {name: 'email', placeholder: 'Email Address'},
];

export const PASSWORD_FIELDS = [
    {name: 'currentPassword', placeholder: 'Current password'},
    {name: 'password', placeholder: 'New password'},
    {name: 'confirmation', placeholder: 'Verify password'},
];
