import {WizardOptionType} from '@optimaxdev/wizard-ms';

export function sortUpsells(items: WizardOptionType[]): WizardOptionType[] {
    const correctOrderAliases = {
        sunglasses_options: 1,
        light_adjusting: 2,
        digital_protection: 3,
        hydrophobic: 4,
        clear: 5,
    };

    return items.sort(
        (firstItem, secondItem) =>
            correctOrderAliases[firstItem.alias] - correctOrderAliases[secondItem.alias],
    );
}
