export const ForgotEmailInputFixture = {
    email: 'test@email.com',
};

export const NewPasswordFixture = {
    password: '123',
    confirmation: '123',
};

export const ReimbursementInputFixture = {
    reason: 'Out-of-network',
    firstname: 'John',
    lastname: 'Swon',
    email: 'basic@unitedvisionplan.com',
    phone: '132456789',
    comment: 'comment',
    invoice: new Blob(['file'], {type: 'text/plain'}),
};

export const FormDataFixture = {
    forgotEmail: ForgotEmailInputFixture,
    reimbursementForm: ReimbursementInputFixture,
};

export const LoginFixture = {
    email: '111@gmail.com',
    password: '111',
};
