import {ApplicationStoreType} from '../../constants/typescript/types';
import {EligibilityWidgetType} from '../../features/store';

/**
 * Get customer's firstname
 *
 * @param {ApplicationStoreType} store - store
 * @returns {string} - customer firstname
 */
export const getFirstName = (store: ApplicationStoreType): string => store.user.firstname;

/**
 * Get eligibilityWidget obj
 *
 * @param {ApplicationStoreType} store - store
 * @returns {EligibilityWidgetType} - widget obj.
 */
export const getEligibilityWidget = (store: ApplicationStoreType): EligibilityWidgetType =>
    store.widgets.eligibilityWidget;
