import {createAction, createReducer} from 'redux-act';

import {FULFILLED} from 'constants/actionSuffix';
import {RequestType, ResponseType} from 'constants/typescript/types';

import {CategoryType} from './categoryType';

export const defaultState: CategoryType = {
    id: 0,
    description: '',
    gender: 0,
    image: '',
    title: '',
    url: '',
};
export const getCategory = createAction<number, RequestType>('GET_CATEGORY', id => ({
    request: {
        method: 'get',
        url: `/backend/api/rest/catalog/category/${id}`,
    },
}));

/**
 * Category reducer
 */
export const category = createReducer<CategoryType>(
    {
        [`${getCategory}${FULFILLED}`]: (state, {data}: ResponseType<CategoryType>) => ({
            ...data,
        }),
    },
    defaultState,
);
