export type PropertiesType = {
    benefit: string;
    discount: string;
    viewName: string;
}[];

export const framesProperties = [
    {
        benefit: 'basic',
        discount: 'basic',
        viewName: '1000’s of Stylish Frames',
    },
    {
        benefit: 'brand',
        discount: 'brand',
        viewName: 'Designer Brands',
    },
    {
        benefit: 'contacts',
        discount: 'contacts',
        viewName: 'Contact Lenses',
    },
];

export const lensesProperties = [
    {
        benefit: 'Lenses:All SV Lenses',
        discount: 'svregpak-Value-Index1.5-HC-AS',
        viewName: 'Standard 1.5 Index Distance/Near lenses',
    },
    {
        benefit: 'Lenses:All BF Lenses',
        discount: 'bifpak-Value-FT1.5-HC-AS',
        viewName: 'Standard 1.5 Index Lined Bifocal',
    },
    {
        benefit: 'Lenses:All Progressive Lenses',
        discount: 'progregpak-Value-Vpro1.5-HMC',
        viewName: 'Digital Progressive 1.5 Index',
    },
];

export const upgradesProperties = [
    {
        benefit: '',
        discount: 'digital-block',
        viewName: 'Blue Light',
    },
    {
        benefit: '',
        discount: 'photo-gray',
        viewName: 'Transitions',
    },
];

export const coatingsProperties = [
    {
        benefit: 'Anti-scratch coating',
        discount: '',
        viewName: 'Anti-scratch coating',
    },
    {
        benefit: 'Anti-glare coating',
        discount: '',
        viewName: 'Anti-glare coating',
    },
    {
        benefit: 'UV protection',
        discount: '',
        viewName: 'UV protection',
    },
];

export const ALLOWANCE_TYPE = {
    FLAT: 'Plan Wide Allowance:All Materials',
    FRAME: 'Frames:Frame',
    CONTACT_LENSES: 'Contact Lenses:Non-Selection Contact Lenses',
    SAFETY_GLASSES: 'Frames:Safety Eyewear (Employee Only): Frame',
};

export const PLAN_FRAMES_TYPE = {
    basic: '3000 + Private Labels',
    brand: 'Designer Brands',
    contacts: 'Contact Lenses',
};
