export const ELIGIBLE_MESSAGE = {
    withEligible: 'All prices have been calculated according to your plan.',
    // eslint-disable-next-line no-template-curly-in-string
    withFlatBenefit: 'Your allowance is ${{allowance}}',
    withoutEligible: 'Please enjoy exclusive UHCGlasses.com discounts.',
    withoutEligibleUhcMember:
        'You’re not eligible for insurance benefits.\n But don’t worry, you can still enjoy other discounts for your next pair.',
    nonUHCMember: 'All prices shown include your special discount.',
    noNonRxGlasses: 'Your plan’s benefits do not apply to non-prescription glasses.',
} as const;
