import React, {ReactElement, Suspense} from 'react';

import {Outlet} from 'react-router-dom';
import {StickyContainer} from 'react-sticky';

import {DialogConnected} from 'components/__helpers/dialog/dialog';
import {FooterConnected} from 'components/__helpers/footer/footer';
import {HeaderConnected} from 'components/__helpers/header/header';
import {PopupConnected} from 'components/__helpers/popup/popup';
import {UhcLoader} from 'components/__helpers/uhcLoader/uhcLoader';
import {EligibilityMessagesConnected} from 'features/eligibilityMessages/eligibilityMessages';

import s from './root.scss';

export const Root = (): ReactElement => (
    <StickyContainer>
        <HeaderConnected />
        <main className={s.pageWrapper} id="pageRouter__pageWrapper">
            <Suspense fallback={<UhcLoader />}>
                <div>
                    <Outlet />
                </div>
            </Suspense>
        </main>
        <EligibilityMessagesConnected />
        <FooterConnected />
        <PopupConnected />
        <DialogConnected />
    </StickyContainer>
);
