import {last} from 'ramda';
import {createSelector} from 'reselect';

import {ApplicationStoreType, Maybe} from 'constants/typescript/types';
import {CartType} from 'reducers/cart';
import {QuoteItemV2Type} from 'reducers/cart/addToCart/addToCartType';
import {CartCertificates} from 'reducers/cart/cartType';

/**
 * Get cart data
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {CartType} - cart data
 */
export const getCartData = (state: ApplicationStoreType): CartType => state?.cart;

/**
 * Get cart items length
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {CartType} - cart items length
 */
export const getCartItemLength = (state: ApplicationStoreType): number => state.cart.items.length;

/**
 * Get cart list array
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {CartType} - cart QuoteItemV2Type list array
 */
export const getCartItemsList = (state: ApplicationStoreType): Array<QuoteItemV2Type> =>
    state.cart.items;

export const getRemovedProductId = (state: ApplicationStoreType) => state.cart.removedItem[0];
/**
 * Get cart items
 *
 * @returns {Array<QuoteItemV2Type>} - cart data
 */
export const getCartItems = createSelector(
    getCartData,
    ({items}: CartType): Array<QuoteItemV2Type> => items,
);

/**
 * Checking every item in the cart is in stock
 *
 * @returns {boolean} - cart not contains out of stock item
 */
export const isEveryItemInStock = createSelector(
    getCartItems,
    (items: Array<QuoteItemV2Type>): boolean => items.every(({product: {isInStock}}) => isInStock),
);

export const hasCartGlasses = createSelector(
    getCartItems,
    (items: Array<QuoteItemV2Type>): boolean =>
        items.some(item => item.product.type !== 'contact_lenses'),
);
/**
 * Get last cart item
 *
 * @returns {QuoteItemV2Type | Record<string, unknown>} - Returns the last element from a cart item list.
 */
export const getLastCartItem = createSelector(
    getCartItems,
    (items: Array<QuoteItemV2Type>): QuoteItemV2Type | Record<string, unknown> => last(items) || {},
);

/**
 * Get cart totals without special price
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {number} - subtotal without special
 */
export const getSubtotalWithoutSpecial = createSelector(getCartData, ({totals}) => {
    const subtotalWithoutSpecial = totals.totals.find(total => total.code === 'discount');
    return subtotalWithoutSpecial?.price || 0;
});

/**
 * Detect a neediness of displaying an allowance choose on cart
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {boolean} - if true should display, false - not
 */
export const hasAllowanceChoose = createSelector(
    getCartItems,
    items =>
        items.filter(({isEligibleForApplyBenefits}) => isEligibleForApplyBenefits).length !== 0,
);

/**
 * Getting cart item that has applied benefit
 */
export const getCartItemWithAppliedBenefit = createSelector<
    ApplicationStoreType,
    Array<QuoteItemV2Type>,
    Maybe<QuoteItemV2Type>
>(getCartItems, items => items.find(({isAppliedBenefits}) => isAppliedBenefits) || null);

/**
 * Gets the loading state of the cart
 */
export const getIsCartLoading = createSelector(
    getCartData,
    ({isTotalsLoading, isItemsLoading}) => isTotalsLoading || isItemsLoading,
);

export const isCartRemovingItem = (state: ApplicationStoreType) => state.cart?.isRemovingItem;

export const getCartCertificates = createSelector(
    getCartData,
    ({totals: {discount}}: CartType): string[] => discount.certificates,
);

export const getCartCertificatesWithBalance = createSelector(
    getCartData,
    ({totals: {certificates}}: CartType): CartCertificates[] => certificates,
);

export const getCartAllCertificates = createSelector(
    [getCartCertificates, getCartCertificatesWithBalance],
    (certificates, certificatesWithBalance): CartCertificates[] => {
        const data = certificates.map(code => {
            const item = certificatesWithBalance.find(certificate => certificate.code === code);
            if (item) {
                return item;
            }
            return {
                code,
                balance: 0,
                base_balance: 0,
            };
        });

        return data.sort((a, b) => b.balance - a.balance);
    },
);

export const getCartCoupon = createSelector(
    getCartData,
    ({totals: {planAlias}}: CartType): string => planAlias,
);

export const getStoreCreditDiscount = createSelector(getCartData, ({totals}) => {
    const subtotalWithoutSpecial = totals.totals.find(total => total.code === 'ugiftcert');
    return subtotalWithoutSpecial?.price || 0;
});
