import {SagaIterator} from 'redux-saga';
import {call, put, putResolve, takeLatest} from 'redux-saga/effects';

import {PrescriptionType} from 'features/wizard/store/reducers/prescription/prescriptionTypes';
import {
    mapToFlatV2EndpointPrescription,
    PrescriptionAdapterV2Type,
} from 'sagas/prescription/prescriptionMappers';

import {
    fetchSavedCustomerPrescriptionsMS,
    saveCustomerPrescriptionMS,
    startSavePrescriptionSagaMS,
    updateCustomerPrescriptionMS,
} from '../../store/reducers/prescription/savedCustomerPrescription';

/**
 * Save prescription saga
 *
 * @param {object} action - prescription to save
 */
export function* savePrescription(action: {
    payload: {
        prescription: PrescriptionType;
        isUpdate: boolean;
    };
}): SagaIterator {
    try {
        const {prescription, isUpdate} = action.payload;
        const mappedData: PrescriptionAdapterV2Type = yield call(
            mapToFlatV2EndpointPrescription,
            prescription,
        );
        if (isUpdate) {
            yield putResolve(updateCustomerPrescriptionMS(mappedData));
        } else {
            yield putResolve(saveCustomerPrescriptionMS(mappedData));
        }
        yield put(fetchSavedCustomerPrescriptionsMS());
    } catch (e) {
        console.error(e); // eslint-disable-line no-console
    }
}

/**
 * Begin of saga
 */
export function* prescriptionSagaMS(): SagaIterator {
    yield takeLatest(startSavePrescriptionSagaMS, savePrescription);
}
