import {PrescriptionAdapterV2Type} from 'sagas/prescription/prescriptionMappers';

import {PrescriptionType} from './prescriptionTypes';

export const PrescriptionFixture: PrescriptionType = {
    id: 336312,
    name: 'Prescription scanner "1"',
    comment: 'comment',
    method: 3,
    activeTab: 1,
    strength: '',
    file: 'fileSrc.jpg',
    fileName: 'filename',
    isPrism: false,
    type: 'email',
    od: {
        sph: '-7.50',
        cyl: '-1.50',
        axi: '180',
        add: '+1.00',
        boxes: 1,
    },
    os: {
        sph: '-7.25',
        cyl: '-7.25',
        axi: '4',
        add: '+3.00',
        boxes: 1,
    },
    pd: {
        right: '0',
        left: '0',
        near: '0',
        singular: '63',
    },
    isMultiPD: false,
};

export const MappedPrescriptionFixture: PrescriptionAdapterV2Type = {
    data: {
        sph_os: '-7.25',
        sph_od: '-7.50',
        cyl_os: '-7.25',
        cyl_od: '-1.50',
        axi_os: '4',
        axi_od: '180',
        add_os: '+3.00',
        add_od: '+1.00',
        pd: '63',
        pd_right: '0',
        pd_left: '0',
        pd_near: '0',
    },
    id: 336312,
    name: 'Prescription scanner "1"',
    comment: 'comment',
    method: 1,
};
