export enum RMA_STATES {
    IDLE = 'idle',
    ERROR_ORDER_RETURNED = 'error.order_returned',
    ERROR_ITEM_RETURNED = 'error.item_returned',
    ERROR_ITEMS_HAS_DIFFERENT_TYPES = 'error.items_has_different_types',
    ORDER_ITEMS = 'order_items',
    RETURNS_REASONS = 'returns.reasons',
    CONTACTS_RETURNS_REASONS = 'contacts.returns.reasons',
    CANCELLATION_REASONS = 'cancellation.reasons',
    CONTACTS_CANCELLATION_REASONS = 'contacts.cancellation.reasons',
    RETURNS_TYPES = 'returns.types',
    RETURNS_UHC_TYPES = 'returns.uhc.types',
    RETURNS_NO_REFUND_TYPES = 'returns.no_refund.types',
    CANCELLATION_TYPES = 'cancellation.types',
    CANCELLATION_UHC_TYPES = 'cancellation.uhc.types',
    CANCELLATION_NO_REFUND_TYPES = 'cancellation.no_refund.types',
    RESOLUTION = 'resolution',
    OFFER_MULTIFOCAL = 'offer.multifocal',
    ERROR_ORDER_NOT_FOUND = 'error.order_not_found',
    ERROR_STATUS_HAVE_PROBLEM = 'error.status_have_problem',
    ERROR_STORECREDIT_NO_RMA = 'error.storecredit_no_rma',
    CONFIRMATION_EXCHANGE = 'confirmation.exchange',
    CONFIRMATION_STORE_CREDIT = 'confirmation.storecredit',
    CONFIRMATION_REFUND = 'confirmation.refund',
    OFFER_REFUND = 'offer.refund',
    OFFER_EXCHANGE = 'offer.exchange',
    SUMMARY_RETURNS = 'summary.returns',
    SUMMARY_CANCELLATION = 'summary.cancellation',
}

export const RMA_ERROR_STATUSES = [
    RMA_STATES.ERROR_ITEMS_HAS_DIFFERENT_TYPES,
    RMA_STATES.ERROR_ORDER_RETURNED,
    RMA_STATES.ERROR_ITEM_RETURNED,
    RMA_STATES.ERROR_ORDER_NOT_FOUND,
    RMA_STATES.ERROR_STATUS_HAVE_PROBLEM,
    RMA_STATES.ERROR_STORECREDIT_NO_RMA,
];

export enum RMA_PAGES {
    MAIN = 'main',
    ORDER_ITEMS = 'order_items',
    REASONS = 'reasons',
    TYPES = 'types',
    CONFIRMATION = 'confirmation',
    RESOLUTION = 'resolution',
    SUMMARY = 'summary',
}

export const RMA_PAGES_NAME = {
    [RMA_PAGES.MAIN]: 'main',
    [RMA_PAGES.ORDER_ITEMS]: 'Item selection',
    [RMA_PAGES.REASONS]: 'Return reason',
    [RMA_PAGES.TYPES]: 'Request options',
    [RMA_PAGES.CONFIRMATION]: 'Confirmation',
    [RMA_PAGES.RESOLUTION]: 'Resolution',
    [RMA_PAGES.SUMMARY]: 'Summary',
};

export const RMA_NOT_NUMERIC_STEPS = [RMA_PAGES.MAIN, RMA_PAGES.RESOLUTION, RMA_PAGES.SUMMARY];

export const RMA_FAQ_ANCHOR = '#faq';
export const RMA_RETURN_OPTIONS_ANCHOR = '#returnOptions';
export const RMA_RETURN_STATUS_ANCHOR = '#returnStatus';
export const RMA_CANCELLATION_STATUS_ANCHOR = '#cancellationStatus';
export const RMA_RETURN_GLASSES_ANCHOR = '#returnGlasses';
export const RMA_EXCHANGE_GLASSES_ANCHOR = '#exchangeGlasses';
export const RMA_WHEN_REFUND_ANCHOR = '#whenRefound';
export const RMA_WHERE_RETURN_GLASSES_ANCHOR = '#whereReturnGlasses';
export const RMA_RECEIVE_LABEL_ANCHOR = '#receiveLabel';
export const RMA_PRODUCT_ITEMS_ANCHOR = '#productItems';
export const RMA_RESOLUTION_PAGE_ANCHOR = '#resolutionPage';
export const RMA_SUMMARY_PAGE_ANCHOR = '#summaryPage';
export const RMA_RESEIVE_STORE_CREDIT_ANCHOR = '#reseiveStoreCredit';
export const RMA_GIFT_CARD_INSTEAD_EXCHANGE_ANCHOR = '#giftCardInsteadExchange';
export const RMA_LOST_TRACKING_NUMBER_ANCHOR = '#lostTrackingNumber';
export const RMA_WHERE_RETURN_PACKAGE_ANCHOR = '#whereReturnPackage';
export const RMA_WHEN_EXCHANGE_ANCHOR = '#whenExchange';
export const RMA_RETURN_MULTIPLE_PRODUCTS_ANCHOR = '#returnMultipleProducts';
export const RMA_RETURN_REASONS_ANCHOR = '#returnReasons';

export enum RMA_REQUEST_STATUSES {
    IDLE = 'idle',
    PENDING = 'pending',
    FULFILLED = 'fulfilled',
    REJECTED = 'rejected',
}

export enum RMA_ORDER_ITEMS_TYPE {
    CONTACT_LENSES = 'contactLenses',
    GLASSES_AND_ACCESSORIES = 'glassesAndAccessories',
}

export enum RETURN_TYPE {
    EXCHANGE = 'Exchange',
    REFUND = 'Refund',
}

export const RETURN_OPTIONS = {
    exchange: {
        key: 'exchange',
        title: RETURN_TYPE.EXCHANGE,
        description:
            "Choose an exchange for the same item (we'll produce a new one) or a different style. Price differences may include added cost or refund as applicable.",
    },
    refund: {
        key: 'refund',
        title: RETURN_TYPE.REFUND,
        description:
            'You can return the items for a full refund. The refund will be made to the payment method used to place the order.',
    },
    refund_eligibility: {
        key: 'refund',
        title: RETURN_TYPE.REFUND,
        description:
            'You can return the items for a partial refund. The amount that was paid with store credit will be fulfilled in store credit form.',
    },
} as const;

export const CONFIRMATION_REASONS = ['wrong_prescription', 'defective_product'];

export const DIFFERENT_ITEM = 'different_item';
export const SAME_ITEM = 'same_item';
export const DAYS_FOR_RETURNS = 30;

export const LABELS_EXCHANGE = {
    [DIFFERENT_ITEM]: 'Different',
    [SAME_ITEM]: 'Same',
};
