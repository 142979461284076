import {COMMON_INPUTS_REGEX, InputFieldDescriptionMapType} from 'libs/validation/validateForm';

export const INPUT_FIELDS: InputFieldDescriptionMapType = {
    email: {
        placeholder: 'Email Address',
        type: 'email',
        autoComplete: 'email',
        mask: false,
        regex: COMMON_INPUTS_REGEX.email,
        error: 'The email is not valid',
    },
};
