import {CartType} from './cartType';

export const defaultState: CartType = {
    isTotalsLoading: false,
    isItemsLoading: false,
    isRemovingItem: false,
    id: 0,
    items: [],
    totals: {
        discount: {
            shipping: 0,
            withoutShipping: 0,
            certificates: [],
            coupons: [],
            price: 0,
        },
        items: [],
        certificateTotal: 0,
        certificates: [],
        planAlias: '',
        copay: 0,
        totalTax: 0,
        totals: [],
        currency: {
            code: '',
            symbol: '',
        },
        shipping: {
            description: '',
            shippingTax: 0,
            сopayTax: 0,
            title: '',
            price: 0,
            discount: 0,
        },
        count: 0,
        subtotal: 0,
        grandTotal: 0,
        subtotalWithoutSpecial: 0,
    },
    removedItem: [],
    productId: 0,
};
