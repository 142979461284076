import React, {ChangeEvent, useCallback, useState} from 'react';

import {pushDataLayer} from '@optimaxdev/analytics/desktop';
import {CtaButton, Input} from '@optimaxdev/design-system-u';
import {useMutation} from '@tanstack/react-query';
import {connect} from 'react-redux';

import {sendSignUpAnalytic} from 'libs/analytics/analytics';
import {
    AxiosErrorWithApiResponseErrorDetails,
    getErrorMessageByAxiosErrorWithErrorDetails,
} from 'libs/axiosResponceHandling';
import {InitialValidateFormType, validateForm} from 'libs/validation/validateForm';
import {setToken as setTokenAction} from 'reducers/oauth2';
import {setUser as setUserAction} from 'reducers/user/user';

import {ICustomerMSUserSignUpCredentials, signUpCustomer} from '../../api/customerMs/customerMs';
import {NonMemberRegistrationResponse} from '../../api/customerMs/customerMsType';
import {LoginPopupNames} from '../loginPopup';
import {AccessBenefits} from './accessBenefits';
import {INPUTS, PropsType} from './createAccountInputs';
import {sendSignInNonUhcEvent} from '../memberLogin/analytics';
import {ErrorBlockConnected} from './errorBlock/errorBlock';

import s from '../loginPopup.scss';

import createAccountFormStyles from './createAccountForm.scss';

export const CreateAccountBlock: React.FC<PropsType> = ({
    isError,
    setPopup,
    onRequestClose,
    setToken,
    setUser,
}) => {
    const [responseErrorMessage, setResponseErrorMessage] = useState('');
    const [input, setInput] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [fieldsValidationResult, setFieldsValidationResult] = useState(
        {} as Partial<InitialValidateFormType>,
    );

    const {isLoading, mutate} = useMutation(
        (data: ICustomerMSUserSignUpCredentials) => signUpCustomer(data),
        {
            onSuccess: ({errorMessage, customer, token}: NonMemberRegistrationResponse) => {
                onRequestClose();
                if (errorMessage) setResponseErrorMessage(errorMessage);
                else {
                    setResponseErrorMessage('');
                }
                if (token) setToken(token);
                if (customer) {
                    setUser(customer);
                }
                sendSignUpAnalytic();
            },
            onError: (data: AxiosErrorWithApiResponseErrorDetails) => {
                const errorMessage = getErrorMessageByAxiosErrorWithErrorDetails(data);
                setResponseErrorMessage(errorMessage);
                pushDataLayer({
                    event: 'GeneralInteraction',
                    eventAction: 'SignUp As Account',
                    eventCategory: 'Login - D',
                    eventLabel: `Error - ${errorMessage}`,
                });
            },
        },
    );

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.currentTarget;
        setInput(prevState => ({...prevState, [name]: value}));
        setResponseErrorMessage('');
    }, []);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const validationResult = validateForm(INPUTS, input);

        setFieldsValidationResult(validationResult);
        if (!validationResult.hasErrors) {
            mutate({
                firstname: input.firstName.trim(),
                lastname: input.lastName.trim(),
                email: input.email,
                password: input.password,
            });
        }
    };

    const handleSignIn = () => {
        setPopup(LoginPopupNames.email);
        sendSignInNonUhcEvent();
    };

    return (
        <section className={createAccountFormStyles.wrapper}>
            {isError && <ErrorBlockConnected setPopup={setPopup} />}
            <h2 className={s.title}>No vision insurance? We got you!</h2>
            <p className={s.text}>
                Create an account and enjoy exclusive benefits
                <br />
                for Unitedhealthcare members
            </p>

            <form onSubmit={onSubmit} aria-label="form">
                {INPUTS.map(({name, placeholder, autoComplete, type}) => {
                    const fieldValidationError = fieldsValidationResult.errors?.[name];
                    return (
                        <div key={name} className={s.input}>
                            <Input
                                label={placeholder}
                                isError={Boolean(fieldValidationError)}
                                message={fieldValidationError ?? null}
                                type={type}
                                name={name}
                                onChange={onChange}
                                value={input[name]}
                                autoComplete={autoComplete}
                                isRequired
                            />
                        </div>
                    );
                })}

                <CtaButton
                    type="submit"
                    size="large"
                    ariaLabel="Create new account"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                >
                    Create UHCGlasses.com Account
                </CtaButton>
                {responseErrorMessage && <p className={s.errorMessage}>{responseErrorMessage}</p>}
            </form>

            <p className={`${s.text} ${s.signUp}`}>
                Already have an account? <button onClick={handleSignIn}>Sign in</button>
            </p>

            {!isError && <AccessBenefits setPopup={setPopup} />}
        </section>
    );
};

const mapDispatchToProps = {
    setToken: setTokenAction,
    setUser: setUserAction,
};

export const CreateAccountBlockConnected = connect<
    null,
    Pick<PropsType, keyof typeof mapDispatchToProps>,
    Omit<PropsType, keyof typeof mapDispatchToProps>
>(
    null,
    mapDispatchToProps,
)(CreateAccountBlock);
