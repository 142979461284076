export const FULL_STORY_EXCLUDE = 'fs-exclude';

export const PAGE_PARAM2 = {
    HOME_PAGE: 'HOMEPAGE',
    CATEGORY: 'CATEGORY',
    PRODUCT: 'PRODUCT',
    PRODUCT_WIZARD: 'PRODUCT WIZARD',
    CART: 'CART',
    CHECKOUT: 'CHECKOUT',
    WISHLIST: 'WISHLIST',
    MY_ACCOUNT: 'MY ACCOUNT',
    CONTACT_US: 'CONTACT US',
    ORDER_TRACKING: 'ORDER TRACKING',
    OTHER: 'OTHER',
    RMA: 'RMA',
    TYP: 'TYP',
};
