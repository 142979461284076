/**
 * Type of the user authorized.
 *
 * @enum {number}
 */
export enum USER_MEMBER_TYPE {
    /**
     * Users who have member id
     * and can access all priveleges with
     * their insuarence
     */
    UHC_MEMBER = 'UHC_MEMBER',
    /**
     * Users who don't have a member id
     * and a full coverage by their insurance
     */
    NOT_UHC_MEMBER = 'NOT_UHC_MEMBER',
}
