import {Status} from 'constants/reducerStatus';
import {ApplicationStoreType} from 'constants/typescript/types';
import {Oauth2TypeReducerType} from 'reducers/oauth2/oauth2Type';

/**
 * Return oauth2Data object
 *
 * @param {store} store - store
 * @returns {Oauth2Type} oauth2Data object
 */
export const oauth2Selector = (store: ApplicationStoreType): Oauth2TypeReducerType =>
    store.oauth2Data;

/**
 * get user current refresh token
 *
 * @param {store} store - store
 * @returns {string} token
 */
export const getUserRefreshToken = (store: ApplicationStoreType): string =>
    store.oauth2Data.refresh_token;

/**
 * get user current login status
 *
 * @param {store} store - store
 * @returns {boolean} is logged in
 */
export const isUserLoggedIn = (store: ApplicationStoreType): boolean =>
    Boolean(store.oauth2Data.access_token);

export const canOpenWP = (store: ApplicationStoreType): boolean => store.oauth2Data.canOpenWP;

/**
 * Return oauth2Data status
 *
 * @param {store} store - store
 * @returns {Status} oauth2Data status
 */
export const getOauth2Status = (store: ApplicationStoreType): Status =>
    store.oauth2Data.status || Status.Fulfilled;

/**
 * Return oauth2Data loading
 *
 * @param {store} store - store
 * @returns {boolean} oauth2Data loading
 */
export const getOauth2Loading = (store: ApplicationStoreType): boolean =>
    store.oauth2Data.status === Status.Pending;

/**
 * Return oauth2Data error message
 *
 * @param {store} store - store
 * @returns {string} oauth2Data error message
 */
export const getOauth2ErrorMessage = (store: ApplicationStoreType): string =>
    store.oauth2Data.error?.message || '';
