import {Status} from 'constants/reducerStatus';

import {UserResponseType} from './userType';

export const UserFixture: UserResponseType = {
    id: 1001,
    email: 'test@example.com',
    firstname: 'testFirstname',
    lastname: 'testLastname',
    billingAddressId: 100,
    shippingAddressId: 100,
    addresses: [],
    prescriptions: [],
    newFormatPrescriptions: [],
    discount: {
        group_id: 10,
        frame_discount: {
            basic: {discount: '50.0000', type: 'by_percent'},
            brand: {discount: '25.0000', type: 'by_percent'},
        },
        status: Status.Fulfilled,
    },
    uvp: {
        enrollmentEnd: null,
        maxPlanQty: 5,
        planId: 5,
        subscription: '2018-09-30T00:00:00+00:00',
        usedPlanQty: 1,
        originalEmail: null,
        company: {
            logo: 'company/logo/testCompny.jpg',
            title: 'Test Company',
        },
    },
    planId: 5,
    status: Status.Fulfilled,
    insuranceCompany: null,
    isEligible: false,
};

export const SavedPrescriptionsFixture = [
    {
        id: 566133,
        name: 'qweqwre',
        comment: '',
        createdAt: '2019-05-30T15:00:02+00:00',
        updatedAt: '2021-02-10T11:26:49+00:00',
        data: {
            sph_os: '-13.50',
            sph_od: '-13.75',
            cyl_os: '-4.25',
            cyl_od: '-4.50',
            axi_os: null,
            axi_od: null,
            add_os: null,
            add_od: null,
            pd: null,
            pd_right: null,
            pd_left: null,
            pd_near: null,
            sixoversix_id: null,
        },
        isSixoversix: false,
    },
];
