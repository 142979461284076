// @flow
import {type SagaIterator} from 'redux-saga';
import {takeEvery, call, put, putResolve, all, takeLatest} from 'redux-saga/effects';

import {FULFILLED} from 'constants/actionSuffix';
import {PrescriptionType} from 'features/wizard/store/reducers/prescription/prescriptionTypes';
import {
    fetchSavedCustomerPrescriptions,
    saveCustomerPrescription,
    startSavePrescriptionSaga,
    updateCustomerPrescription,
} from 'features/wizard/store/reducers/prescription/savedCustomerPrescription';
import {
    getPrescriptions,
    updatePrescription,
    createPrescription,
    deletePrescription,
} from 'reducers/prescription';
import {getUser} from 'reducers/user/user';

import {mapToFlatV2EndpointPrescription, PrescriptionAdapterV2Type} from './prescriptionMappers';

/**
 * Redirect to my account prescription page
 */
export function* updateUserData(): SagaIterator {
    yield put(getUser());
}

/**
 * Update prescriptions after delete action
 */
export function* updatePrescriptions(): SagaIterator {
    yield all([putResolve(getPrescriptions()), put(getUser())]);
}

/**
 * Save prescription saga
 *
 * @param {object} action - prescription to save
 */
export function* savePrescription(action: {
    payload: {
        prescription: PrescriptionType,
        isUpdate: boolean,
    },
}): SagaIterator {
    try {
        const {prescription, isUpdate} = action.payload;
        if (isUpdate) {
            const mappedData: PrescriptionAdapterV2Type = yield call(
                mapToFlatV2EndpointPrescription,
                prescription,
            );
            // istanbul ignore next
            yield putResolve(updateCustomerPrescription(mappedData));
        } else {
            yield putResolve(saveCustomerPrescription(prescription));
        }
        yield put(fetchSavedCustomerPrescriptions());
    } catch (e) {
        console.error(e); // eslint-disable-line no-console
    }
}

/**
 * Begin of saga
 */
export function* prescriptionSaga(): SagaIterator {
    yield takeEvery(
        [`${updatePrescription}${FULFILLED}`, `${createPrescription}${FULFILLED}`],
        updateUserData,
    );
    yield takeEvery(`${deletePrescription}${FULFILLED}`, updatePrescriptions);
    yield takeLatest(startSavePrescriptionSaga, savePrescription);
}
