import {createAction, createReducer} from 'redux-act';

import {FULFILLED} from 'constants/actionSuffix';
import {API_URL_ELASTICSEARCH} from 'constants/api';
import {CATEGORY_PRODUCT_LIMIT} from 'constants/category';
import {Status} from 'constants/reducerStatus';
import {STORE_ID} from 'constants/store';
import {RequestType} from 'constants/typescript/types';
import {getCategoryParams} from 'libs/url';

import {CategoryItemsType, CategoryParamsType} from './categoryItemsType';

const {categoryParams} = getCategoryParams();

export const defaultState: CategoryItemsType = {
    categoryUrl: '',
    products: [],
    total: 0,
    page: categoryParams.page,
    isAppend: false,
    status: Status.Pending,
};

type PageType = {
    page: number;
};

export const getCategoryItems = createAction<number, CategoryParamsType, RequestType, PageType>(
    'GET_CATEGORY_ITEMS',
    (categoryId, params) => ({
        request: {
            method: 'get',
            baseURL: API_URL_ELASTICSEARCH,
            url: `${STORE_ID}/products/${categoryId}`,
            params: {
                limit: CATEGORY_PRODUCT_LIMIT,
                offset: CATEGORY_PRODUCT_LIMIT * (params.page - 1),
                ...params.filters,
                dir: params.dir || null,
                order: params.order || null,
                q: params.q || null,
            },
        },
    }),
    (categoryId, params) => ({
        page: params.page,
    }),
);
export const startGetCategoryData = createAction('START_GET_CATEGORY_DATA');
export const setCategoryPage = createAction<number, CategoryItemsType>(
    'SET_CATEGORY_PAGE',
    page => page,
);

/**
 * Category items reducer
 *
 */
export const categoryItems = createReducer<CategoryItemsType>({}, defaultState)
    .on(`${getCategoryItems}${FULFILLED}`, (state, {data}) => ({
        page: state.page,
        total: data.total,
        products: [...(state.isAppend ? state.products : []), ...data.items],
        categoryUrl: `${window.location.pathname}${window.location.search}`,
        status: Status.Fulfilled,
        isAppend: false,
    }))
    .on(getCategoryItems, (state, payload, meta) => ({
        ...state,
        page: meta?.page || 0,
        status: Status.Pending,
    }))
    .on(setCategoryPage, (state, page) => ({
        ...state,
        page,
        isAppend: true,
        status: Status.Pending,
    }));
