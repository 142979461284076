import React, {FC, ReactElement, useState} from 'react';

import classNames from 'classnames';
import {connect} from 'react-redux';

import {Icon} from 'components/__helpers/icon/icon';
import {ApplicationStoreType} from 'constants/typescript/types';
import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';

import {MergeAccountFormConnected} from '../mergeAccountForm/mergeAccountForm';

import s from './mergeAccountErrorPopup.scss';

export type PropsType = {
    phone: string;
    errorMessage: string;
    openPopup: (node: ReactElement) => void;
};

const MERGE_ERRORS_TITLES = [
    'You are not eligible for vision benefits',
    'You already have an activated member account',
    "We couldn't find a policy that matches the information you entered.",
    "Your policy isn't eligible for vision benefits.",
];

export const MergeAccountErrorPopup: FC<PropsType> = ({phone, errorMessage, openPopup}) => {
    const [isDetailsOpen, setDetailsOpen] = useState(false);

    const validateTitle = () => {
        return MERGE_ERRORS_TITLES.includes(errorMessage) ? errorMessage : 'An error occurred';
    };

    const validateMessage = () => {
        if (errorMessage === 'You already have an activated member account') {
            return 'Please log out and log in again using your existing member account details.';
        }
        return (
            <>
                Please{' '}
                <a className={s.errorLink} onClick={() => openPopup(<MergeAccountFormConnected />)}>
                    try again
                </a>{' '}
                or contact customer service.
            </>
        );
    };

    return (
        <section className={s.ErrorWrap}>
            <a className={s.tryAgain} onClick={() => openPopup(<MergeAccountFormConnected />)}>
                <Icon name="arrow" />
                <span>Try Again</span>
            </a>
            <div className={s.ErrorDesc}>
                <Icon name="mergeError" />
                <p className={s.errorTitle}>{validateTitle()}</p>
                <p className={s.errorText}>{validateMessage()}</p>
                <a
                    className={classNames(s.infoLink, {[s.opened]: isDetailsOpen})}
                    onClick={() => setDetailsOpen(prevState => !prevState)}
                >
                    Need help?
                    <Icon name="arrow" />
                </a>
            </div>
            {isDetailsOpen ? (
                <div className={s.helpInfo}>
                    <p>For additional assistance:</p>
                    <ul>
                        <li>
                            See our <a className={s.errorLink}>troubleshooting FAQ</a>
                        </li>
                        <li>
                            Contact your insurance customer service at{' '}
                            <a href={`tel:${phone}`} className={s.errorLink}>
                                {phone}
                            </a>
                        </li>
                    </ul>
                </div>
            ) : null}
        </section>
    );
};

export const mapStateToProps = (store: ApplicationStoreType) => ({
    phone: store.storeInfo.phones.default,
});

export const mapDispatchToProps = {
    openPopup: (content: ReactElement) =>
        setCmsPopupVisibility(true, content, {
            className: 'mergeAccountForm',
        }),
};

export const MergeAccountErrorPopupConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MergeAccountErrorPopup);
