import {createAction, createReducer} from 'redux-act';

import {RouteType, PageType} from './routeType';

export const defaultState: RouteType = {
    page: '',
    params: {},
    prevPage: {page: '', params: {}},
    isLoading: false,
};

export const setPage = createAction<PageType, PageType>('SET_PAGE', page => page);

/**
 * Page info reducer
 */
export const route = createReducer<RouteType>({}, defaultState).on(setPage, (state, payload) => ({
    ...payload,
    prevPage: {
        page: state.page,
        params: state.params,
    },
    isLoading: false,
}));
