import React, {ReactNode} from 'react';

import Loadable from 'react-loadable';

import {PropsType} from '../icon';

/**
 * It loads svg icon sprites
 */
export const Loader = Loadable({
    loader: () => import('../baseIcon/baseIcon'),
    render({BaseIcon}, props: PropsType): ReactNode {
        return <BaseIcon {...props} />;
    },
    loading: () => null,
});
