import React, {useMemo, FC} from 'react';

import ReactModal from 'react-modal';

import {Icon} from 'components/__helpers/icon/icon';

import s from './modal.scss';

export type PropsType = {
    onRequestClose?: () => void;
    isOpen: boolean;
    contentStyles?: Partial<CSSStyleDeclaration>;
    children?: React.ReactElement | string;
};

const customStyles = {
    content: {
        border: 'none',
        borderRadius: '8px',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        maxHeight: '95vh',
        maxWidth: '95vw',
        minHeight: '52px',
        padding: '0',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backdropFilter: 'blur(3px)',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 4,
    },
};

ReactModal.setAppElement('body');

export const Modal: FC<PropsType> = React.memo(
    ({isOpen, contentStyles, children, onRequestClose}) => {
        const reactModalStyles = useMemo(
            () => ({
                ...customStyles,
                content: {
                    ...customStyles.content,
                    ...contentStyles,
                },
            }),
            [contentStyles],
        );
        return (
            <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={reactModalStyles}>
                <button className={s.closeButton} aria-label="Close modal" onClick={onRequestClose}>
                    <Icon name="close" />
                </button>

                {children}
            </ReactModal>
        );
    },
);

Modal.displayName = 'Modal';
