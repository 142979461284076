// @flow
import type {ApplicationStoreType} from 'constants/flow/flowTypes';
import type {AddressItemType} from 'reducers/address';
import type {AddressEditType} from 'reducers/addressEdit';

/**
 * Get addresses data
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {CartType} - addresses data
 */
export const getAddressesData = (state: ApplicationStoreType): Array<AddressItemType> =>
    state.user.addresses;

/**
 * Get edit address data
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {CartType} - edit address data
 */
export const getEditAddressData = (state: ApplicationStoreType): AddressEditType =>
    state.addressEdit;

/**
 * Get errors
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {CartType} - edit address data
 */
export const getAddressErrors = (state: ApplicationStoreType) => state.address.error;
