/* istanbul ignore file */
export const frameProperties = ['basic', 'brand', 'frame_allowance', 'copay'];
export const lenseProperties = [
    {name: 'Lenses:All SV Lenses', viewName: 'Standard 1.5 Index Distance/Near lenses'},
    {name: 'Lenses:All BF Lenses', viewName: 'Standard 1.5 Index Lined Bifocal'},
    {name: 'Lenses:All Progressive Lenses', viewName: 'Digital Progressive 1.5 Index'},
];

export const accountPlanProperties = [
    'svregpak-Standard-Index1.5-HMC',
    'svregpak-Super-Thin1.61-HMC',
    'progregpak-Value-Vpro1.5-HMC',
    'progregpak-Standard-Vpro1.61-HMC',
    'bifpak-Standard-FT1.5-HMC',
    'bifpak-Super-FT1.6-HC-AS-HC-UV-HMC',
    'bifpak-Super',
    'color-tint-tint-gray',
    'photo-gray',
    'digital-block',
];

export const welcomePopupPlanProperties = [
    'svregpak-Standard-Index1.5-HMC',
    'progregpak-Value-Vpro1.5-HMC',
    'bifpak-Super',
    'color-tint-tint-gray',
    'digital-block',
    'photo-gray',
];

export const otherBenefitsProperties = [
    'contact-lenses',
    'eye-examination-reimbursement',
    'out-of-network-reimbursement',
];

export const ALLOWANCE_TYPE = {
    FLAT: 'Plan Wide Allowance:All Materials',
    FRAME: 'Frames:Frame',
    CONTACT_LENSES: 'Contact Lenses:Non-Selection Contact Lenses',
    SAFETY_GLASSES: 'Frames:Safety Eyewear (Employee Only): Frame',
};
