// @flow
import type {ApplicationStoreType} from 'constants/flow/flowTypes';
import type {ProductType, ProductChildrenType} from 'reducers/product';
import type {ImagesTryOnType} from 'reducers/tryOn/tryOnType';

/**
 * Get active product
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {ProductType} - Active product
 */
export const getParentProduct = (store: ApplicationStoreType): ProductType =>
    store.categoryItems.products.find(product => product.id === store.tryOn.parentId);

/**
 * Get id of selected product
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {number} - product id
 */
export const getSelectedId = (store: ApplicationStoreType): number =>
    store.categoryColors[store.tryOn.parentId] || store.tryOn.productId;

/**
 * Get default product
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {ProductType|null} - default selected product
 */
export const getDefaultProduct = (store: ApplicationStoreType): ProductType | null =>
    store.categoryItems.products[0] || null;

/**
 * Get selected product
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {ProductChildrenType | null} - selected product
 */
export const getSelectedProduct = (store: ApplicationStoreType): ProductChildrenType | null => {
    const parentProduct = getParentProduct(store);
    const selectedId = getSelectedId(store);
    const defaultProduct = getDefaultProduct(store);

    if (parentProduct) {
        return parentProduct.children.find(product => product.id === selectedId) || null;
    } else if (defaultProduct && defaultProduct.children.length > 0) {
        return defaultProduct.children[0];
    }
    return null;
};

/**
 * Get and sort user photos TryOn
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {ImagesTryOnType} - try on images
 */
export const getUserPhotos = (store: ApplicationStoreType): ImagesTryOnType =>
    store.tryOn.imagesTryOn.filter(item => item.is_user_photo).sort((a, b) => b.id - a.id);

/**
 * Get TryOn photo upload type
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {ImagesTryOnType} - try on images
 */
export const getUploadType = (store: ApplicationStoreType): ImagesTryOnType =>
    store.tryOn.uploadType;

/**
 * Get TryOn data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {ImagesTryOnType} - try on images
 */
export const getTryOnData = (store: ApplicationStoreType) => store.tryOn;

/**
 * Get product data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {{ProductID, categoryId}} - product data
 */
export const getProductData = (store: ApplicationStoreType) => ({
    productId: store.product.item.id,
    categoryId: store.category.id,
});

/**
 * Get and sort photos for FaceSlider
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {ImagesTryOnType} - try on images
 */
export const getFaceSliderPhotos = (store: ApplicationStoreType): ImagesTryOnType => {
    const defaultPhotos = store.tryOn.imagesTryOn.filter(item => !item.is_user_photo);
    const userPhotos = getUserPhotos(store);
    return [...userPhotos, ...defaultPhotos];
};
