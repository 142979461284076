import {createSelector} from 'reselect';

import {ALLOWANCE_TYPE, otherBenefitsProperties} from 'constants/myPlan';
import {ApplicationStoreType} from 'constants/typescript/types';
import {MyPlanType} from 'reducers/myPlan';
import {UhcBenefitType, DiscountItemDataType} from 'reducers/myPlan/myPlanType';
import {UserReducerType, UserPlanSettingsType} from 'reducers/user/userType';
import {isUHCMember} from 'selectors/user/user';

/**
 * Returns user account data
 *
 * @param {ApplicationStoreType} state - application state
 * @returns {UserReducerType} - account data
 */
export const getUserAccountData = (state: ApplicationStoreType): UserReducerType => state.user;

/**
 * Returns user account UVP specific data
 *
 * @param {ApplicationStoreType} state - app state
 * @returns {UserPlanSettingsType | null} - uvp data
 */
export const getUserAccountUVPData = (state: ApplicationStoreType): UserPlanSettingsType | null =>
    getUserAccountData(state).uvp;
/**
 * Is deluxe plan
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {boolean} - is deluxe plan
 */
export const isDeluxePlan = (store: ApplicationStoreType): boolean => {
    const {data} = store.myPlan.discounts.otherBenefits;
    return Object.keys(data).some(
        key => otherBenefitsProperties.includes(key) && data[key].discount !== null,
    );
};

/**
 * Get uvp plan
 *
 * @deprecated
 * @param {ApplicationStoreType} store - Application state
 * @returns {MyPlanType} - uvp plan
 */
export const getUvpPlan = (store: ApplicationStoreType): MyPlanType => store.myPlan;

/**
 * Get customer's benefits
 *
 * @param {ApplicationStoreType} store - redux store
 * @returns {UhcBenefitType[] | []} - customer benefits
 */
export const getUhcBenefits = (store: ApplicationStoreType): UhcBenefitType[] | [] =>
    store.myPlan.benefits ? store.myPlan.benefits : [];

/**
 * Get flat benefit
 */
export const getFlatBenefit = createSelector(
    getUhcBenefits,
    benefits => benefits?.find(benefit => benefit.description === ALLOWANCE_TYPE.FLAT) || null,
);

/**
 * Get frame benefit
 */
export const getFrameBenefit = createSelector(
    getUhcBenefits,
    benefits => benefits?.find(benefit => benefit.description === ALLOWANCE_TYPE.FRAME) || null,
);

/**
 * Check page category
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {boolean} - is current category safety glasses
 */
export const isSafetyCategory = (store: ApplicationStoreType): boolean =>
    Boolean(store.category.url === '/safety-glasses');

/**
 * Get safety benefit. Return value only for safety glasses category
 */
export const getSafetyBenefit = createSelector(
    getUhcBenefits,
    isSafetyCategory,
    (benefits, isSafety) => {
        return (
            (isSafety &&
                benefits?.find(benefit =>
                    benefit.description.includes(ALLOWANCE_TYPE.SAFETY_GLASSES),
                )) ||
            null
        );
    },
);

/**
 * Get frame discounts
 *
 * @param {ApplicationStoreType} store - redux store
 * @returns {DiscountItemDataType} - Frame discounts for standard user
 */
export const getFrameDiscounts = (store: ApplicationStoreType): DiscountItemDataType =>
    store.myPlan.discounts?.frame?.data || [];

/**
 * Get user plan copay
 *
 * @param {ApplicationStoreType} store - redux store
 * @returns {DiscountFrameData} - Frame discounts for standard user
 */
export const getUserCopay = (store: ApplicationStoreType): number => {
    const frameCopayBenefits = store.myPlan.benefits?.find(el => el.description === 'Frames:Frame');
    if (frameCopayBenefits) {
        return frameCopayBenefits.copay;
    }
    return 0;
};

/**
 * Get a benefit according to priority
 *
 * @returns {number} - benefit discount value
 */
export const getUhcDiscount = createSelector(
    getFlatBenefit,
    getFrameBenefit,
    getSafetyBenefit,
    (flat, frame, safety): number => {
        const actualBenefit = flat || safety || frame;
        return actualBenefit ? actualBenefit.allowance : 0;
    },
);

/**
 * Returns an email address actual for the user
 */
export const getUserEmailAddress = createSelector(
    getUserAccountData,
    getUserAccountUVPData,
    isUHCMember,
    (userAccountData, userAccountUVPData, whetherUserIsUHCMember): string => {
        const userEmailAddress = whetherUserIsUHCMember
            ? userAccountUVPData && userAccountUVPData.originalEmail
            : userAccountData.email;
        return userEmailAddress || '';
    },
);
