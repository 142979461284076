// @flow

import {createSelector} from 'reselect';

import type {ApplicationStoreType} from 'constants/flow/flowTypes';
import {UserReducerType} from 'reducers/user';

import {Status} from '../../constants/reducerStatus';
import {filterEmail} from '../../libs/utils/utils';

/**
 * Get user  data from store
 *
 * @param {ApplicationStoreType} store - current store
 * @returns {UserReducerType} - user  data
 */

export const getUserData = (store: ApplicationStoreType): UserReducerType => store.user;

/**
 * Return user UHC status
 *
 * @param {store} store - store
 * @returns {boolean} UHC user status
 */
export const isUHCMember = createSelector(getUserData, userData =>
    Boolean(userData.insuranceCompany === 'UHC'),
);

export const isUHCBenefitsEligible = createSelector(getUserData, userData => userData.isEligible);

export const getUserEmail = createSelector(getUserData, userData => filterEmail(userData.email));

export const getUserEmailAnalytic = createSelector(getUserData, userData => userData.email);

export const getUserId = createSelector(getUserData, userData => userData.id);
export const getInsuranceCompany = createSelector(
    getUserData,
    userData => userData.insuranceCompany,
);
export const isUserStatusFulfilled = createSelector(
    getUserData,
    userData => userData.status === Status.Fulfilled,
);
export const getUserFirstName = createSelector(getUserData, userData => userData.firstname);
