import React, {useCallback, useState, ChangeEvent, ReactElement} from 'react';

import {InputsValuesType} from 'libs/validation/validateForm';

import {EmailLoginDataType, LoginPopupContext} from './loginPopupContext';

const defaultFormValue = {
    id: '',
    birthday: '',
    first_name: '',
    last_name: '',
    zip_code: '',
};

/**
 * Authorization popup provider
 */
export const LoginPopupProvider: React.FC<{children: ReactElement}> = ({children}) => {
    const [isMember, setIsMember] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [accountUuid, setAccountUuid] = useState('');
    const [canEditPhone, setCanEditPhone] = useState(true);
    const [emailLoginData, setEmailLoginData] = useState<EmailLoginDataType>({
        email: {value: '', error: ''},
        password: {value: '', error: ''},
    });

    const [memberLoginData, setInputValue] =
        useState<Record<keyof InputsValuesType, string>>(defaultFormValue);

    const [isFullNameFrom, toggleFormOption] = useState<boolean>(true);

    const setMemberLoginData = useCallback(
        (event: ChangeEvent<{name: keyof InputsValuesType} & HTMLInputElement>) => {
            if (!event.target) return;
            const {name, value} = event.target;
            setInputValue(prevState => ({
                ...prevState,
                [name]: value,
            }));
        },
        [setInputValue],
    );

    const clearMemberLoginData = useCallback(() => {
        setInputValue(defaultFormValue);
    }, []);

    return (
        <LoginPopupContext.Provider
            value={{
                setPhoneNumber,
                phoneNumber,
                setEmailLoginData,
                emailLoginData,
                isMember,
                setIsMember,
                memberLoginData,
                setMemberLoginData,
                accountUuid,
                setAccountUuid,
                canEditPhone,
                setCanEditPhone,
                isFullNameFrom,
                toggleFormOption,
                defaultFormValue,
                clearMemberLoginData,
                setInputValue,
            }}
        >
            {children}
        </LoginPopupContext.Provider>
    );
};
