import {createAction, createReducer} from 'redux-act';

export enum PrescriptionStep {
    Unselected,
    FillOnline,
    UseSaved,
    UploadImage,
    SelectStrength,
}

export enum UsageStep {
    Unselected,
    OfficeLenses,
}
export const PrescriptionStepTitle = {
    [PrescriptionStep.Unselected]: 'How do you want to add your prescription?',
    [PrescriptionStep.FillOnline]: 'Fill it out online',
    [PrescriptionStep.UseSaved]: 'Use saved prescription',
    [PrescriptionStep.UploadImage]: 'Upload image',
    [PrescriptionStep.SelectStrength]: "Select your readers' lens power",
};

export type StepType = 'usage' | 'prescription' | 'lens' | 'upgrades' | 'summary';

export type WizardFeatureReducerType = {
    wizardPopupVisibility: boolean;
    currentStep: StepType;
    prescriptionStep: PrescriptionStep;
    usageStep: UsageStep;
    packagesSubStep: number;
    isInAnimation: boolean;
    configureProductId: number | null;
    quoteItemId: number | null;
};

export const initialState: WizardFeatureReducerType = {
    wizardPopupVisibility: false,
    currentStep: 'usage',
    prescriptionStep: PrescriptionStep.Unselected,
    usageStep: UsageStep.Unselected,
    packagesSubStep: 0,
    isInAnimation: false,
    configureProductId: null,
    quoteItemId: null,
};

export const setWizardPopupVisibility = createAction<boolean, boolean>(
    'SET_WIZARD_POPUP_VISIBILITY',
    wizardPopupVisibility => wizardPopupVisibility,
);

export const setCurrentStep = createAction<StepType>('WIZARD_SET_CURRENT_STEP');
export const setPrescriptionStep = createAction<PrescriptionStep>('WIZARD_SET_PRESCRIPTION_STEP');
export const setUsageStep = createAction<UsageStep>('WIZARD_SET_USAGE_STEP');
export const setPackagesSubStep = createAction<number>('WIZARD_SET_PACKAGES_SUB_STEP');
export const setWizardAnimation = createAction<boolean>('WIZARD_SET_ANIMATION_POPUP');
export const setConfigureProductId = createAction<number>('SET_CONFIGURE_PRODUCT_ID');
export const setQuoteItemId = createAction<number>('SET_QUOTE_ITEM_ID');
export const setDarknessChanged = createAction('WIZARD_DARKNESS_CHANGED');

export const wizardFeature = createReducer<WizardFeatureReducerType>({}, initialState)
    .on(setWizardPopupVisibility, (state, wizardPopupVisibility) => {
        return wizardPopupVisibility ? {...state, wizardPopupVisibility} : initialState;
    })
    .on(setCurrentStep, (state, step) => ({
        ...state,
        currentStep: step,
    }))
    .on(setPrescriptionStep, (state, step) => ({
        ...state,
        prescriptionStep: step,
    }))
    .on(setUsageStep, (state, step) => ({
        ...state,
        usageStep: step,
    }))
    .on(setPackagesSubStep, (state, payload) => ({
        ...state,
        packagesSubStep: payload,
    }))
    .on(setWizardAnimation, (state, isInAnimation: boolean) => ({
        ...state,
        isInAnimation,
    }))
    .on(setConfigureProductId, (state, configureProductId) => ({
        ...state,
        configureProductId,
    }))
    .on(setQuoteItemId, (state, quoteItemId) => ({
        ...state,
        quoteItemId,
    }))
    .on(`${setDarknessChanged}`, state => ({...state, isDarknessChanged: true}));
