import {equals, where} from 'ramda';

import {PrescriptionType} from 'features/wizard/store/reducers/prescription/prescriptionTypes';
import {Validation} from 'libs/validation/validation';

import {
    PrescriptionValidationRulesType,
    rules as prescriptionRules,
} from './prescriptionRules/prescriptionRules';

/**
 * Checks if the prescription empty or not.
 *
 * @param {PrescriptionType} prescription - Prescription object.
 */
export const isEyePrescriptionEmpty = where({
    sph: equals('None'),
    cyl: equals('None'),
    axi: equals('None'),
});

/**
 * Validates prescription values.
 *
 * @param {string} usageSku - Usage sku.
 * @param {PrescriptionType} prescription - Prescription.
 * @returns {Array<boolean, ErrorsType>} - Validation result.
 */
export const validatePrescription = (
    usageSku: string,
    prescription: PrescriptionType,
): [boolean, Record<keyof PrescriptionValidationRulesType, string>] => {
    const validator = new Validation<PrescriptionValidationRulesType>(
        {
            sph_od: {value: prescription.od.sph},
            sph_os: {value: prescription.os.sph},
            cyl_od: {value: prescription.od.cyl},
            cyl_os: {value: prescription.os.cyl},
            axi_od: {value: prescription.od.axi},
            axi_os: {value: prescription.os.axi},
            add_od: {value: prescription.od.add},
            add_os: {value: prescription.os.add},
            usage: {value: usageSku},
        },
        prescriptionRules,
    );

    const validationResults = validator.all();
    const isPrescriptionValid = Object.keys(validationResults).every(
        (field: string) => validationResults[field] === '',
    );
    return [isPrescriptionValid, validationResults];
};
