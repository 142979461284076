import React, {ComponentType, FC, LazyExoticComponent, useMemo} from 'react';

import {REACTPV} from '@optimaxdev/analytics/desktop';
import {useParams} from 'react-router-dom';

import {useSetPage} from 'router/hooks/useSetPage/useSetPage';

import {PAGE_PARAM2} from '../../../constants/analytics';

export type PropsType = {
    page: string;
    component: LazyExoticComponent<ComponentType>;
    event?: (typeof REACTPV)[keyof typeof REACTPV];
    page_type_param2?: string;
};

export const PageWrapper: FC<PropsType> = ({
    page,
    page_type_param2 = PAGE_PARAM2.OTHER,
    event = REACTPV.OTHER,
    component: Component,
}) => {
    // This is temporary decision to update path param IDs in store, in future we should use useParams directly where it uses
    const urlParams = useParams();
    const filteredParams = useMemo(() => ({id: urlParams.id || ''}), [urlParams.id]);

    useSetPage({
        page,
        params: filteredParams,
        event,
        page_type_param2,
    });
    return <Component />;
};
