// @flow
import React, {PureComponent, type Node} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {Sticky} from 'react-sticky';

import {TopStrip} from 'components/__helpers/header/topStrip/topStrip';
import {MainMenu} from 'components/__helpers/header/mainMenu/mainMenu';
import {CheckoutMenuConnected} from 'components/__helpers/header/checkoutMenu/checkoutMenu';
import type {ApplicationStoreType} from 'constants/flow/flowTypes';
import {isUserLoggedIn} from 'selectors/oauth2Data/oauth2Data';

import s from './header.scss';

type PropsType = {
    page: string,
    isLoggedIn: boolean,
};

type StateType = {
    topOffset: number,
};

/**
 * Default class for header
 */
export class Header extends PureComponent<PropsType, StateType> {
    defaultOffset = 120;

    state = {
        topOffset: this.defaultOffset,
    };

    /**
     * Return inner sticky content
     *
     * @param {{isSticky: boolean}} stickyProps - sticky props
     * @returns {Node} sticky content
     */
    getStickyContent = (stickyProps: {isSticky: boolean}): Node => (
        <div
            className={classNames(s.wrapper, {
                [s.sticky]: stickyProps.isSticky,
                [s.static]: !stickyProps.isSticky,
                [s.showAnimation]: stickyProps.isSticky,
            })}
            onAnimationEnd={() => this.changeOffset(stickyProps.isSticky)}
        >
            <MainMenu isSticky={stickyProps.isSticky} isLoggedIn={this.props.isLoggedIn} />
        </div>
    );

    /**
     * Change topOffset state
     *
     * @param {boolean} isSticky - current sticky state
     */
    changeOffset = (isSticky: boolean) => {
        /* Please add a test for coverage, when the component is converted to a functional component. */
        // istanbul ignore next
        this.setState({topOffset: isSticky ? 40 : this.defaultOffset});
    };

    /**
     * Render jsx to html
     *
     * @returns {Node} Rendered react component
     */
    render(): Node {
        const {page} = this.props;

        if (['wizard', 'checkout', 'configureWizard'].includes(page)) {
            return (
                <header className={classNames(s.wrapHeader, s.sticky)}>
                    {['wizard', 'configureWizard'].includes(page) && (
                        <MainMenu isSticky isLoggedIn={this.props.isLoggedIn} />
                    )}
                    {page === 'checkout' && <CheckoutMenuConnected />}
                </header>
            );
        }

        return (
            <header>
                <div className={s.wrapHeader}>
                    <TopStrip />
                    <Sticky topOffset={this.state.topOffset}>{this.getStickyContent}</Sticky>
                </div>
            </header>
        );
    }
}
/* istanbul ignore next */
export const mapStateToProps = (store: ApplicationStoreType) => ({
    page: store.route.page,
    isLoggedIn: isUserLoggedIn(store),
});

export const HeaderConnected = connect(mapStateToProps)(Header);
