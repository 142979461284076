import React from 'react';

import classNames from 'classnames';
import {connect} from 'react-redux';

import {Icon} from 'components/__helpers/icon/icon';
import {ApplicationStoreType} from 'constants/typescript/types';
import {MenuItemsLoginPopUpShownEvent} from 'libs/analytics/loginPopUp/GeneralInteractionEvents';
import {isUserLoggedIn} from 'selectors/oauth2Data/oauth2Data';

import s from './cartBtn.scss';

export type PropsType = {
    page: string;
    isLoggedIn: boolean;
    cartCount: number;
    className?: string;
};

/**
 * Class for header's cart button component
 *
 * @returns {React.FC} node
 */
export const CartBtn: React.FC<PropsType> = ({page, isLoggedIn, cartCount, className = ''}) => {
    const clickHandlerAnalytics = () => {
        if (isLoggedIn) return;
        MenuItemsLoginPopUpShownEvent(page === 'category', 'CartClick');
    };

    return (
        <div className={classNames(s.cart, className)} onClick={clickHandlerAnalytics}>
            <Icon name="cartIcon" />
            {cartCount > 0 && (
                <div className={s.counter}>
                    <span className={s.amount}>{cartCount > 9 ? '9+' : cartCount}</span>
                </div>
            )}
        </div>
    );
};

/* istanbul ignore next */
export const mapStateToProps = (store: ApplicationStoreType) => ({
    cartCount: store.cart.totals.count,
    page: store.route.page,
    isLoggedIn: isUserLoggedIn(store),
});

export const CartBtnConnected = connect(mapStateToProps)(CartBtn);
