import React, {ReactElement} from 'react';

import {Outlet} from 'react-router-dom';

import {LINK_PAGE} from 'constants/links';
import {useRedirectUnauthorised} from 'router/hooks/useRedirectUnauthorised/useRedirectUnauthorised';

/*
 * It's works like authorisation middleware, but maybe replace this to router loader callback may works better
 */
export const Authorisation = (): ReactElement | null => {
    const isLoggedIn = useRedirectUnauthorised(`${LINK_PAGE.HOME}#login`);

    return isLoggedIn ? <Outlet /> : null;
};
