// @flow
import React from 'react';
import {type SagaIterator} from 'redux-saga';
import {put, takeEvery, call, select, all} from 'redux-saga/effects';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {setPage} from 'reducers/route/index';
import {resetPassword, setValidationError} from 'reducers/newPassword/index';
import {NewPasswordSetPopupConnected} from 'components/newPasswordSetPopup/newPasswordSetPopup';
import {logOutActions} from 'sagas/oauth2/oauth2';
import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';
import {getSearchParams} from 'libs/url';
import {forgotPassword} from 'reducers/password';
import {getPasswordErrorMessage} from 'selectors/password/password';

import {NewPasswordPopupConnected} from '../../components/newPassword/newPassword';

/**
 * Open change password popup
 */
export function* openChangePasswordPopup(): SagaIterator {
    if (window.location.href.includes('resetpassword')) {
        yield call(logOutActions);
        yield put(setCmsPopupVisibility(true, <NewPasswordPopupConnected />));
    }
}

/**
 * Open change password success popup
 */
export function* openChangePassSuccessPopup(): SagaIterator {
    yield put(setCmsPopupVisibility(true, <NewPasswordSetPopupConnected />));
}

/**
 * Resend new reset token if current token expired and set error message
 */
export function* handleRejectResetPassword(): SagaIterator {
    const {email} = yield call(getSearchParams);
    const errorMessage = yield select(getPasswordErrorMessage);

    if (errorMessage === 'Customer token has been expired') {
        yield all([
            put(forgotPassword(email)),
            put(
                setValidationError(
                    'Your reset link has expired. We have sent you a new one. Please check email.',
                ),
            ),
        ]);
    }
}
/**
 * Begin of saga
 */
export function* newPasswordSaga(): SagaIterator {
    yield takeEvery(setPage, openChangePasswordPopup);
    yield takeEvery(`${resetPassword}${FULFILLED}`, openChangePassSuccessPopup);
    yield takeEvery(`${resetPassword}${REJECTED}`, handleRejectResetPassword);
}
