import {getUrlParameter} from 'libs/url';

export const AFF_ID_STORE_PARAM = 'aff_id';

/**
 * Normalize default value affiliate id from backend
 *
 * @param {string} affId - affiliate id
 * @returns {string} - normalize affiliate id
 */
export function normalizeAffId(affId?: string): string {
    return affId && affId !== 'undefined' ? affId : '';
}

/**
 * Get actual affiliate id.
 *
 * Affiliates have a priority, where:
 * URL - high (https://glassesusa.com?affid=AFFILIATE_ID)
 * localStorage - low (грс_aff_id)
 *
 * @returns {string} affiliate id
 */
export const getAffId = () => {
    const affIdFromUrl = getUrlParameter('affId');
    const affIdFromStorage = localStorage.getItem(AFF_ID_STORE_PARAM);
    return affIdFromUrl || affIdFromStorage || '';
};

/**
 * Save affiliate id
 *
 * @param {string} affIdParam - passed affId
 */
export const saveAffId = (affIdParam = '') => {
    const affId = affIdParam || getAffId();
    localStorage.setItem(AFF_ID_STORE_PARAM, affId);
};
