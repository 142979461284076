import {createAction} from 'redux-act';

import {API_URL_CUSTOMER} from 'constants/api';
import {CLIENT_ID, CUSTOMER_SUPPORT_CLIENT_ID} from 'constants/clientId';
import {SITE_ID} from 'constants/store';
import {RequestType} from 'constants/typescript/types';
import {
    LogInType,
    MemberAuthorizationMetaData,
} from 'features/loginPopup/api/customerMs/customerMsType';

import {Oauth2Type} from './oauth2Type';
import {ENDPOINTS} from '../../constants/endpoints';

/**
 * Calculates expiration time by senconds
 * before a token will be expired.
 *
 * @param {number} expiresInSeconds - seconds before expiration
 * @returns {number} - a time value in milliseconds when the token will become expired.
 */
const getExpirationTimeByExpiresInSeconds = (expiresInSeconds: number): number => {
    const today = new Date();
    today.setSeconds(today.getSeconds() + expiresInSeconds);
    return today.getTime();
};

/**
 * Add expires date for OAuth2 token data
 * In IE11 received JSON
 *
 * @param {Oauth2Type | string} data - OAuth2 token data
 * @returns {Oauth2Type} OAuth2 token data with expires date
 */
export const addExpiresDate = (data: Oauth2Type | string): Oauth2Type => {
    const parsedData: Oauth2Type = typeof data === 'string' ? JSON.parse(data) : data;
    return {
        ...parsedData,
        expiresDate: getExpirationTimeByExpiresInSeconds(parsedData.expires_in),
    };
};

export const prepareGetTokensData = (isCustomerSupport: boolean) => ({
    grantType: isCustomerSupport ? 'customer_support' : 'password',
    clientId: isCustomerSupport ? CUSTOMER_SUPPORT_CLIENT_ID : CLIENT_ID,
});

export const getTokens = createAction<string, string, boolean, RequestType>(
    'GET_TOKENS',
    (email: string, password: string, isCustomerSupport: boolean): RequestType => {
        const {grantType, clientId} = prepareGetTokensData(Boolean(isCustomerSupport));
        return {
            request: {
                baseURL: API_URL_CUSTOMER,
                method: 'POST',
                url: 'token',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data: `grant_type=${grantType}&website_id=${SITE_ID}&client_id=${clientId}&username=${email}&password=${password}`,
                transformResponse: [addExpiresDate],
            },
        };
    },
);

export const setToken = createAction<LogInType & MemberAuthorizationMetaData>(
    'SET_TOKEN',
    data => ({
        ...data,
        expiresDate: getExpirationTimeByExpiresInSeconds(data.expires_in),
    }),
);

export const putRefreshToken = createAction<string, RequestType>(
    'PUT_REFRESH_TOKEN',
    (refreshToken: string): RequestType => ({
        request: {
            baseURL: API_URL_CUSTOMER,
            method: 'POST',
            url: 'token',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: `grant_type=refresh_token&client_id=${CLIENT_ID}&refresh_token=${refreshToken}`,
            transformResponse: [addExpiresDate],
        },
    }),
);

export const deleteUHCMember = createAction<RequestType>(
    'DELETE_UHC_MEMBER',
    (): RequestType => ({
        request: {
            headers: {Authorization: ''},
            method: 'DELETE',
            url: `/backend/${ENDPOINTS.DELETE_UHC_MEMBER}`,
        },
    }),
);

export const setCanOpenWP = createAction('SET_CAN_OPEN_WP', data => data);

export const isTokenExist = createAction('IS_TOKEN_EXIST');

export const isTokenSet = createAction('IS_TOKEN_SET');

export const clearTokens = createAction('CLEAR_TOKENS');

export const logOut = createAction('LOG_OUT');

export const clearOathError = createAction('CLEAR_OATH_ERROR');

export const closeMFAPopup = createAction('MFA_LOGIN_POPUP_CLOSED');
