export const PRODUCT_TYPES: {
    READERS: 'readers';
    SUNGLASSES: 'sunglasses';
    ACCESSORY: 'accessories';
    EYEGLASSES: 'eyeglasses';
    CONTACT_LENSES: 'contact_lenses';
    UPGRADE: 'upgrade';
} = {
    READERS: 'readers',
    SUNGLASSES: 'sunglasses',
    ACCESSORY: 'accessories',
    EYEGLASSES: 'eyeglasses',
    CONTACT_LENSES: 'contact_lenses',
    UPGRADE: 'upgrade',
};

export const STOCK_STATUSES: {
    OUT_OF_STOCK: 'out_of_stock';
    IN_STOCK: 'in_stock';
    LOW_STOCK: 'low_stock';
} = {
    OUT_OF_STOCK: 'out_of_stock',
    IN_STOCK: 'in_stock',
    LOW_STOCK: 'low_stock',
};

export const STOCK_STATUSES_FOR_ANALYTICS = {
    [STOCK_STATUSES.OUT_OF_STOCK]: 'OOS',
    [STOCK_STATUSES.LOW_STOCK]: 'Low In Stock',
    [STOCK_STATUSES.IN_STOCK]: 'In Stock',
};
