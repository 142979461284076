import {createSelector} from 'reselect';

import {ApplicationStoreType} from 'constants/typescript/types';
import {FILL_IT_ONLINE} from 'constants/wizard/wizard';
import {CartProductsType} from 'reducers/cartProducts';
import {PageType} from 'reducers/route/index';
import {ConfigureType} from 'reducers/wizard/configure/configureType';
import {SchemeWithoutValueType} from 'reducers/wizard/prescriptionScheme';
import {getPageData} from 'selectors/page/getPageData';

import {getCartProducts} from '../../cartProducts/cartProducts';

/**
 * Return is configure data
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {ConfigureType} - Configure data
 */
export const getConfigure = (state: ApplicationStoreType): ConfigureType => state.wizard.configure;

export const getConfigureProductId = (store: ApplicationStoreType) =>
    store.wizard.wizardFeature.configureProductId;
export const getQuoteItemIdForNewConfigure = (store: ApplicationStoreType) =>
    store.wizard.wizardFeature.quoteItemId;
// returns parent product name for new configure page or null if it is not configure
export const getConfigureProductName = createSelector(
    getCartProducts,
    getConfigureProductId,
    (cartProducts: Omit<CartProductsType, 'status'>, productId) => {
        const configureProduct = Object.values(cartProducts).find(
            item =>
                item?.children && item.children.find(childrenItem => childrenItem.id === productId),
        );
        return configureProduct ? configureProduct.name : null;
    },
);
/**
 * Return is configure page
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {boolean} - Is configure page
 */
export const isConfigurePage = createSelector(
    getPageData,
    ({page}: PageType): boolean => page === 'configureWizard',
);

/**
 * Return configure.prescriptionScheme
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {SchemeWithoutValueType | null} - Configure prescription scheme
 */
export const getConfigurePrescription = createSelector(
    [isConfigurePage, getConfigure],
    (isConfigure: boolean, configure: ConfigureType): SchemeWithoutValueType | null =>
        isConfigure && configure && configure.prescription ? configure.prescription : null,
);

/**
 * Return configure.prescriptionScheme boolean that shows currentPrescription has name
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {boolean} - Configure prescription scheme
 */
export const isFillOnlinePrescription = (state: ApplicationStoreType): boolean => {
    const {prescription} = state.wizard.configure;
    const {prescriptionsScheme, prescriptionSendBy} = state.wizard;

    return Boolean(
        prescription &&
            prescriptionsScheme &&
            !prescriptionSendBy.content.file &&
            prescription.method === FILL_IT_ONLINE &&
            prescription.id &&
            !prescription.name &&
            prescription.id !== prescriptionsScheme.id,
    );
};
