import {pushDataLayer} from '@optimaxdev/analytics/desktop';
import {type ActionType} from 'redux-actions';
import {type SagaIterator} from 'redux-saga';
import {select, put, putResolve, all, takeEvery} from 'redux-saga/effects';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {updateAccountSettings} from 'reducers/accountSettings';
import {updateAddress, createAddress, deleteAddress} from 'reducers/address';
import {clearAddress} from 'reducers/addressEdit';
import {setShippingAddress, setBillingAddress} from 'reducers/order';
import {getShippingMethods} from 'reducers/shipping';
import {getUser} from 'reducers/user';
import {getAddressesData, getEditAddressData} from 'selectors/address/address';
import {getShippingData} from 'selectors/checkout/checkout';
import {
    getOrderData,
    getSelectedShippingAddressId,
    getSelectedBillingAddressId,
} from 'selectors/order/order';
import {getPageData} from 'selectors/page/getPageData';

const sendCheckoutInteractionEvent = () => {
    pushDataLayer({
        event: 'CheckoutInteraction',
        eventCategory: 'Checkout - D',
        eventAction: 'Step 1 - Delivery',
        eventLabel: 'CTA - Continue',
    });
};

/**
 * Close address form after create action and select new address
 */
export function* selectNewAddress(): SagaIterator {
    yield all([put(clearAddress()), putResolve(getUser())]);
    const {page} = yield select(getPageData);

    const addresses = yield select(getAddressesData);
    const {isPaymentStep} = yield select(getOrderData);
    const setAddress = isPaymentStep ? setBillingAddress : setShippingAddress;

    yield put(setAddress(addresses[addresses.length - 1].id));
    if (page === 'checkout') sendCheckoutInteractionEvent();
}

/**
 * Get user data after address delete action
 */
export function* updateUser(): SagaIterator {
    yield putResolve(getUser());

    const shippingAddressId = yield select(getSelectedShippingAddressId);
    const billingAddressId = yield select(getSelectedBillingAddressId);

    yield all([
        put(setShippingAddress(shippingAddressId)),
        put(setBillingAddress(billingAddressId)),
    ]);
}

/**
 * Get shipping data when shipping address was changed
 *
 * @param {ActionType<string>} action - action
 */
export function* updateShippingData(action: ActionType<string>): SagaIterator {
    const addresses = yield select(getAddressesData);
    const shipping = yield select(getShippingData);

    if (addresses.length === 0) return;

    const address = addresses.find(item => item.id === action.payload);

    if (shipping.countryCode !== address.countryCode) {
        yield put(getShippingMethods(address.countryCode));
    }
}

/**
 * Close address form after update action
 */
export function* closeForm(): SagaIterator {
    const {page} = yield select(getPageData);
    const {countryCode: currentCountryCode} = yield select(getEditAddressData);
    const {countryCode} = yield select(getShippingData);

    yield all([put(clearAddress()), put(getUser())]);

    if (page === 'checkout' && countryCode !== currentCountryCode) {
        yield put(getShippingMethods(currentCountryCode));
    }
    if (page === 'checkout') sendCheckoutInteractionEvent();
}

/**
 * Get user data after update account settings
 */
export function* getUserData(): SagaIterator {
    yield put(getUser());
}

/**
 * Begin of saga
 */
export function* addressSaga(): SagaIterator {
    yield takeEvery(`${updateAddress}${FULFILLED}`, closeForm);
    yield takeEvery(`${createAddress}${FULFILLED}`, selectNewAddress);
    yield takeEvery([`${deleteAddress}${FULFILLED}`, `${deleteAddress}${REJECTED}`], updateUser);
    yield takeEvery(setShippingAddress, updateShippingData);
    yield takeEvery(`${updateAccountSettings}${FULFILLED}`, getUserData);
}
