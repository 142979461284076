// @flow
import type {ApplicationStoreType} from 'constants/flow/flowTypes';
import type {ShippingType} from 'reducers/shipping';

/**
 * Get shipping data
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {ShippingType} - shipping data
 */
export const getShippingData = (state: ApplicationStoreType): ShippingType => state.shipping;
