import {pushDataLayer} from '@optimaxdev/analytics/desktop';

export const sendTopStripMenuAnalytics = (action: string, label = ''): void => {
    pushDataLayer({
        event: 'GeneralInteraction',
        eventCategory: 'Header - D',
        eventAction: action,
        eventLabel: label,
    });
};
