import {createAction, createReducer} from 'redux-act';

import {FieldType, OrderedItemType} from 'features/contacts/reducers/successPage/successPageTypes';

export enum ScreenType {
    SEND_LATER = 'SEND_LATER',
    SUCCESS = 'SUCCESS',
    UPLOAD = 'UPLOAD',
    DR_DETAILS = 'DR_DETAILS',
}

export const US_CODE = 'US';
export const SEND_LATER_SCREEN = 'SEND_LATER';
export const SUCCESS_SCREEN = 'SUCCESS';
export const UPLOAD_SCREEN = 'UPLOAD';
export const DR_DETAILS_SCREEN = 'DR_DETAILS_SCREEN';
export const DEFAULT_PRESCRIPTION_DATA = {
    doctorDetails: {
        patientName: '',
        name: '',
        city: '',
        regionCode: '',
    },
};
export const FILL_DR_DETAILS_OR_UPLOAD_FILE = 'fill dr details or upload file';
export const YOU_WILL_RECEIVE_A_CONFIRMATION = 'you will receive a confirmation';
export const DONT_FORGET_TO_SEND_US_YOUR_DR_DETAILS = 'dont forget to send us your dr details';
export const DONT_FORGET_TO_SEND_US_A_COPY_OF_YOUR_PRESCRIPTION =
    'dont forget to send us a copy of your prescription';
export const WE_WILL_VERIFY_THE_PRESCRIPTION = 'we will verify the prescription';
export const WE_GOT_IT = 'we got it';

export type SuccessScreenType =
    | typeof FILL_DR_DETAILS_OR_UPLOAD_FILE
    | typeof YOU_WILL_RECEIVE_A_CONFIRMATION
    | typeof WE_WILL_VERIFY_THE_PRESCRIPTION
    | typeof WE_GOT_IT
    | typeof DONT_FORGET_TO_SEND_US_YOUR_DR_DETAILS
    | typeof DONT_FORGET_TO_SEND_US_A_COPY_OF_YOUR_PRESCRIPTION;

export type DoctorDetailsAndUploadValidatedType = Record<
    string,
    | {
          city: string;
          name: string;
          patientName: string;
          regionCode: string;
      }
    | {
          upload: string;
      }
>;

export type SuccessPageType = {
    items: Array<FieldType>;
    orderedItems: Array<OrderedItemType>;
    successScreen: SuccessScreenType;
    itemsValidated: DoctorDetailsAndUploadValidatedType;
    status: string;
};

export const initialState: SuccessPageType = {
    orderedItems: [],
    items: [],
    successScreen: FILL_DR_DETAILS_OR_UPLOAD_FILE,
    itemsValidated: {},
    status: 'pending',
};

/**
 * Provides fill for one item which one need to be configured before send to backend (set upload file or doc details)
 */
export const setFields = createAction<Array<FieldType>>('SUCCESS_PAGE_SET_FIELDS');

/**
 * Provides fill for one field of item which one need to be configured before send to backend (set upload file or doc details)
 */
export const changeField = createAction<FieldType>('SUCCESS_PAGE_CHANGE_FIELD');

/**
 * it changes success screen
 */
export const changeSuccessScreen = createAction<SuccessScreenType>('CHANGE_SUCCESS_SCREEN');
/**
 * it sets errors for success page
 */
export const setErrorsSuccessPage =
    createAction<DoctorDetailsAndUploadValidatedType>('SET_ERRORS_SUCCESS_PAGE');

/**
 * Ordered items reducer on success page
 */
export const successPage = createReducer<SuccessPageType>({}, initialState)
    /**
     * Subscribe actions
     * https://www.npmjs.com/package/redux-act#typescript
     */
    .on(
        setFields,
        (state: SuccessPageType, payload: Array<FieldType>): SuccessPageType => ({
            ...state,
            items: payload,
        }),
    )
    .on(
        changeField,
        (state: SuccessPageType, payload: FieldType): SuccessPageType => ({
            ...state,
            items: state.items.map(item => ({
                ...item,
                ...(item.orderItemId === payload.orderItemId ? payload : {}),
            })),
        }),
    )
    .on(setErrorsSuccessPage, (state, payload) => ({
        ...state,
        itemsValidated: payload,
    }))
    .on(
        changeSuccessScreen,
        (state: SuccessPageType, payload: SuccessScreenType): SuccessPageType => ({
            ...state,
            successScreen: payload,
        }),
    );
