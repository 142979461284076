import React from 'react';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {Icon} from 'components/__helpers/icon/icon';
import {LINK_PAGE, LINK_UHC_SUPPORT} from 'constants/links';
import {ApplicationStoreType} from 'constants/typescript/types';
import {StoreInfoType} from 'reducers/store/storeInfoType';

import s from './footer.scss';

export type PropsType = {
    contacts: StoreInfoType;
    isHideFooter: boolean;
};

const LINKS: Array<{text: string; link: string; isNewPage?: boolean}> = [
    {
        text: 'Terms of use',
        link: LINK_PAGE.TERMS,
    },
    {
        text: 'Returns',
        link: LINK_PAGE.CONTACT_US,
    },
    {
        text: 'Privacy policy',
        link: LINK_PAGE.POLICY,
    },
    {
        text: 'Track your order',
        link: LINK_PAGE.TRACKING,
    },
    {
        text: 'Contact us',
        link: LINK_PAGE.CONTACT_US,
    },
    {
        text: 'FAQ',
        link: LINK_PAGE.FAQ,
        isNewPage: true,
    },
    {
        text: 'Accessibility',
        link: LINK_PAGE.ACCESSIBILITY,
    },
    {
        text: 'Help Center',
        link: LINK_UHC_SUPPORT,
    },
    {
        text: 'Purchase Policy Terms',
        link: LINK_PAGE.ADDITIONAL_PURCHASE,
    },
];

/**
 * Footer component
 *
 * @param {PropsType} props current props
 * @returns {React.FC} rendered component
 */
export const Footer: React.FC<PropsType> = ({contacts, isHideFooter}) => {
    if (isHideFooter) return null;

    return (
        <footer className={s.footer}>
            <section className={s.linkBlock}>
                <Link to={LINK_PAGE.HOME} className={s.logo} aria-label="home page">
                    <img src={require('./img/footerLogo.svg')} alt="logo" />
                </Link>
                <ul className={s.links}>
                    {LINKS.map(item =>
                        item.text === 'Returns' ? {...item, link: LINK_PAGE.RMA} : item,
                    ).map(({text, link, isNewPage}) => (
                        <li key={text} className={s.link}>
                            {isNewPage || link === LINK_UHC_SUPPORT ? (
                                <a key={link} href={link} rel="noopener noreferrer" target="_blank">
                                    {text}
                                </a>
                            ) : (
                                <Link key={link} to={link}>
                                    {text}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
                <ul className={s.contacts}>
                    <li>
                        <h4 className={s.title}>Email Us</h4>
                        <a className={s.link} href={`mailto:${contacts.email}`}>
                            <Icon name="mail" />
                            {contacts.email}
                        </a>
                    </li>
                    <li>
                        <h4 className={s.title}>Call us</h4>

                        <a className={s.link} href={`tel:+${contacts.phones.default}`}>
                            <Icon name="phone" />
                            {contacts.phones.default}
                        </a>
                        <p className={s.accessHours}>9 a.m. - 9 p.m. monday to friday</p>
                    </li>
                </ul>
            </section>
            <section className={s.copyright}>
                <p>© {new Date().getUTCFullYear()} United Vision Plans All rights reserved</p>
                <p>All trademarks are property of their respective owner.</p>
            </section>
        </footer>
    );
};

/* istanbul ignore next */
const mapStateToProps = (store: ApplicationStoreType) => ({
    contacts: store.storeInfo,
    isHideFooter: ['wizard', 'configureWizard'].includes(store.route.page),
});

export const FooterConnected = connect(mapStateToProps)(Footer);
