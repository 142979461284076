import {ChangeEvent, createContext} from 'react';

import {InputsValuesType} from 'libs/validation/validateForm';

export type EmailLoginDataType = {
    email: {value: string; error: string};
    password: {value: string; error: string};
};

type DefaultFormValueType = {
    id: string;
    birthday: string;
    first_name: string;
    last_name: string;
    zip_code: string;
};

export type LoginPopupContextType = {
    emailLoginData: EmailLoginDataType;
    phoneNumber: string;
    setPhoneNumber: (data: string) => void;
    setEmailLoginData: (data: EmailLoginDataType) => void;
    isMember: boolean;
    setIsMember: (isMember: boolean) => void;
    setMemberLoginData: (
        data: ChangeEvent<{name: keyof InputsValuesType} & HTMLInputElement>,
    ) => void;
    memberLoginData: Record<keyof InputsValuesType, string>;
    setAccountUuid: (uuid: string) => void;
    accountUuid: string;
    canEditPhone: boolean;
    setCanEditPhone: (canEditPhone: boolean) => void;
    isFullNameFrom: boolean;
    toggleFormOption: (isFullNameFrom: boolean) => void;
    clearMemberLoginData: () => void;
    defaultFormValue: DefaultFormValueType;
    setInputValue: React.Dispatch<React.SetStateAction<Record<string, string>>>;
};

export const LoginPopupContext = createContext<LoginPopupContextType | null>(null);
