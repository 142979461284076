export const LINK_WOMANS_EYEGLASSES = '/womens-eyeglasses';
export const LINK_MENS_EYEGLASSES = '/mens-eyeglasses';
export const LINK_KIDS_EYEGLASSES = '/kids-glasses';
export const LINK_PRESCRIPTION_SUNGLASSES = '/prescription-sunglasses';
export const LINK_DESIGNER_GLASSES = '/designer-glasses';
export const LINK_SAFETY_GLASSES = 'safety-glasses';
export const LINK_MOST_POPULAR = '/best-sellers';
export const LINK_PROGRESSIVE_LENSES = '/progressive-lenses';
export const LINK_EYEGLASSES_COLLECTION = '/eyeglasses-collection';
export const LINK_BENEFIT_PLANS = '/member/benefit-plans';
export const LINK_OPTIMAX_SITE = 'http://www.optimaxeyewear.com/';
export const LINK_SEARCH = '/salesperson/result';
export const LINK_UHC_SUPPORT = 'https://support.uhcglasses.com/';
export const LINK_CONTACTS_LENSES = '/contact-lenses';

export const LINK_BRAND = {
    RAY_BAN: '/ray-ban',
    DKNY: `${LINK_DESIGNER_GLASSES}?brand=dkny`,
    OAKLEY: '/oakley-glasses',
    ARMANI: `${LINK_DESIGNER_GLASSES}?brand=armani_exchange`,
    VERSACE: `${LINK_DESIGNER_GLASSES}?brand=versace`,
    MICHAEL_KORS: '/michael-kors-eyewear',
    CALVIN_KLEIN: `${LINK_DESIGNER_GLASSES}?brand=calvin_klein`,
    TOM_FORD: `${LINK_DESIGNER_GLASSES}?brand=tom_ford`,
    JUST_CAVALLI: `${LINK_DESIGNER_GLASSES}?brand=just_cavalli`,
    LAGERFELD: `${LINK_DESIGNER_GLASSES}?brand=lagerfeld`,
    LACOSTE: `${LINK_DESIGNER_GLASSES}?brand=lacoste`,
    BURBERRY: `${LINK_DESIGNER_GLASSES}?brand=burberry`,
    VOGUE: `${LINK_DESIGNER_GLASSES}?brand=vogue`,
    CAROLINA_LEMKE: `${LINK_DESIGNER_GLASSES}?brand=carolina_lemke`,
    COACH: `${LINK_DESIGNER_GLASSES}?brand=coach`,
    HUGO_BOSS: `${LINK_DESIGNER_GLASSES}?brand=hugo_boss`,
    PRADA: '/prada-eyewear',
    MIU_MIU: `${LINK_DESIGNER_GLASSES}?brand=miu_miu`,
    PERSOL: '/persol-eyewear',
    MUSE: '/muse-eyewear',
    TORY_BURCH: `${LINK_DESIGNER_GLASSES}?brand=tory_burch`,
    COSTA_DEL_MAR: `${LINK_DESIGNER_GLASSES}?brand=costa_del_mar`,
    OTTOTO: '/ottoto',
    GUCCI: '/gucci-eyewear',
    COACHEXP: '/coach-eyewear',
};

export const ROLE = {
    MEMBER: '/member',
    EMPLOYER: '/employer',
    BROKER: '/broker',
};

export const ROLE_PAGE = {
    WHY_US: '/why-us',
    BENEFIT_PLANS: '/benefit-plans',
    FAQ: '/faq',
};

export const LINK_PAGE = {
    HOME: '/',
    CAREERS: '/pages/careers',
    ABOUT_US: '/pages/about-us',
    FAQ: '/faq',
    RMA: '/rma',
    EMPLOYER_FAQ: `${ROLE.EMPLOYER}${ROLE_PAGE.FAQ}`,
    BROKER_FAQ: `${ROLE.BROKER}${ROLE_PAGE.FAQ}`,
    CONTACT_US: '/pages/contact-us',
    POLICY: '/pages/policy',
    TERMS: '/pages/terms-of-use',
    TRY_ON_NOTICE: '/pages/virtual-try-on-notice',
    ADDITIONAL_PURCHASE: '/pages/additional-purchase',
    CCPA: '/pages/ccpa',
    DATA_SUBJECT_REQUEST: '/pages/data-subject-request',
    BROKER_BENEFIT_PLANS: `${ROLE.BROKER}${ROLE_PAGE.BENEFIT_PLANS}`,
    WISH_LIST: '/mypicks/index/list',
    CART: '/checkout/cart',
    CHECKOUT: '/checkout/onepage',
    THANK_YOU: '/checkout/onepage/success/',
    TRACKING: '/tracking/search',
    REIMBURSEMENT: '/returns',
    PAGE_404: '/404',
    ACCESSIBILITY: '/accessibility',
    CUSTOMER_SUPPORT_LOGIN: '/customer-support-login',
    CONTACT_LENSES: '/contact-lenses',
};

export const LINK_MY_ACCOUNT = {
    ACCOUNT: '/customer/account',
    PLAN: '/customer/account/plan',
    SETTINGS: '/customer/account/edit',
    PASSWORD: '/customer/account/password',
    ADDRESSES: '/customer/address',
    PRESCRIPTIONS: '/glassesprescription/customer',
    TRY_ON_PHOTOS: '/mirror/customer',
    ORDERS: '/sales/orders',
    RESET_PASSWORD: '/customer/account/resetpassword',
};
