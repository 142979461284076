export const DROPDOWN_MENU_CONTACTS_LINKS = {
    contacts: {
        menuLinks: {
            id: 1,
            links: [
                {title: 'Shop All Contacts', link: '/contact-lenses', isUnderlined: false},
                {
                    title: 'Daily',
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&contact_lens_type=daily',
                },
                {
                    title: 'Weekly',
                    link: '/contact-lenses?frame=1&tab=0&dir=desc&order=bestsellers&p=1&contact_lens_type=weekly',
                },
                {
                    title: 'Monthly',
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&contact_lens_type=monthly',
                },
                {
                    title: 'Single Vision',
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&contact_lens_prescription_type=single_vision',
                },
                {
                    title: 'Multifocal & Bifocal',
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&contact_lens_prescription_type=multifocal',
                },
            ],
        },
        menuBrands: {
            links: [
                {
                    name: 'Acuvue' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=acuvue',
                },
                {
                    name: 'Airoptimax' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=air_optix',
                },
                {
                    name: 'Biofinity' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=biofinity',
                },
                {
                    name: 'Biotrue' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=biotrue',
                },
                {
                    name: 'Dailies' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=dailies',
                },
                {
                    name: 'Clariti' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=clariti',
                },
                {
                    name: 'Ultra' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=ultra',
                },
                {
                    name: 'Proclear' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=proclear',
                },
                {
                    name: 'BauschLomb' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&manufacturer=bausch_lomb',
                },
                {
                    name: 'CooperVision' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&manufacturer=cooper_vision',
                },
                {
                    name: 'ShopAll' as const,
                    hide: false,
                    link: '/contact-lenses',
                },
            ],
        },
    },
};
