import {createAction} from 'redux-act';

import {API_URL} from 'constants/api';
import {ENDPOINTS} from 'constants/endpoints';
import {RequestType} from 'constants/typescript/types';

import {SITE_ID, STORE_ID} from '../../../../constants/store';
import {RMA_ORDER_ITEMS_TYPE, RMA_PAGES, RMA_STATES} from '../../constants/common';
import {RmaRequestType} from '../../types';
import {SelectedOrderItemsType} from '../../types/firstStepType';

/**
 * The action that makes a request to change the state of the rma
 */
export const changeRmaState = createAction<RmaRequestType, RequestType>(
    'RMA_TOGGLE_STATE',
    data => ({
        request: {
            method: 'post',
            headers: {'X-Website-Id': SITE_ID, 'X-Store-Id': STORE_ID},
            data,
            baseURL: API_URL,
            url: ENDPOINTS.RMA,
        },
    }),
);

/**
 * Select order item ids
 */
export const selectRmaOrderItem = createAction<SelectedOrderItemsType>('RMA_SELECT_ORDER_ITEM');

/**
 * Clear selected order items ids
 */
export const clearSelectedRmaOrderItem = createAction('RMA_CLEAR_SELECTED_ORDER_ITEM');

/**
 * Select order item
 */
export const toggleRmaOrderItems = createAction<RMA_ORDER_ITEMS_TYPE>('RMA_TOGGLE_ORDER_ITEMS');

/**
 * Change rma step
 */
export const changeRmaStep = createAction<RMA_STATES>('RMA_CHANGE_STEP');

/**
 * Change rma page
 */
export const changeRmaPage = createAction<RMA_PAGES>('RMA_CHANGE_PAGE');
