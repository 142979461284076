import {setToken as setTokenAction} from 'reducers/oauth2';

import {inputs as inputsForm, inputsFullNameForm} from './memberLoginInputs';

/**
 * Get text for change method button
 */
export const getTextForChangeMethodButton = (isFullNameFrom: boolean) => {
    return isFullNameFrom ? 'Log in with your member ID' : 'Log in without your member ID';
};

/**
 * get input list
 */
export const getInputsList = (isFullNameFrom: boolean) =>
    isFullNameFrom ? inputsFullNameForm : inputsForm;

export type PropsType = {
    setToken: typeof setTokenAction;
    setIsError: (isError) => void;
    onRequestClose: () => void;
    setPopup: (popupName) => void;
};
