export const CROPPED_CANVAS_SIZE = {width: 320, height: 320};
export const MIN_AVAIL_PD = 20;
export const MAX_AVAIL_PD = 99;

export const FACE_SLIDER_SLICK_DEFAULT_SETTINGS = {
    swipe: true,
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    adaptiveHeight: false,
    slidesToScroll: 1,
};

export const ADJUST_CROPPER_OPTIONS = {
    style: {
        width: '322px',
        height: '322px',
        position: 'absolute',
        left: 0,
        top: 0,
    },
    minCropBoxWidth: 322,
    minCropBoxHeight: 322,
    minContainerWidth: 322,
    minContainerHeight: 322,
    cropBoxMovable: false,
    cropBoxResizable: false,
    toggleDragModeOnDblclick: false,
    guides: false,
    center: false,
    dragMode: 'move',
    viewMode: 0,
    autoCropArea: 0.66,
    responsive: false,
    modal: false,
};

export const draggableSettings = {
    axis: 'both',
    grid: [2, 2],
    zIndex: 0,
    bounds: 'parent',
};

export const HALF_PUPIL_OFFSET = {
    left: {x: 13, y: 13},
    right: {x: 13, y: 13},
};

export const POPUP_STEP = {
    INITIAL: '',
    UPLOAD_MENU: 'uploadMenu',
    ADD_YOUR_PHOTO: 'addYouPhoto',
    ADJUST: 'adjust',
    WEBCAM_UPLOAD: 'webcamUpload',
};

export const SLIDES_TO_SHOW = {
    product: 5,
    category: 3,
    wishlist: 8,
};

export const VERTICAL_POSITION = {
    product: true,
    category: false,
    wishlist: false,
};
