import SessionStore from 'store/storages/sessionStorage';

import {ACCOUNT, LOGIN_STATUS, UHC_MEMBER} from 'constants/loginStatus/loginStatus';

import {
    sendLoginAsEmailSuccessEvent,
    sendLoginAsUHCSuccessEvent,
    sendLoginStatusEvent,
} from '../../memberLogin/analytics';

export const sendAnalytics = (isMember, isFullNameFrom) => {
    if (isMember) {
        sendLoginAsUHCSuccessEvent(isFullNameFrom);
        if (SessionStore.read(LOGIN_STATUS) !== UHC_MEMBER) {
            sendLoginStatusEvent();
            SessionStore.write(LOGIN_STATUS, UHC_MEMBER);
        }
    } else {
        sendLoginAsEmailSuccessEvent();
        if (SessionStore.read(LOGIN_STATUS) !== ACCOUNT) {
            sendLoginStatusEvent(!isMember);
            SessionStore.write(LOGIN_STATUS, ACCOUNT);
        }
    }
};
