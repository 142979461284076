import React, {FC, ReactNode} from 'react';

import s from './topStripMenuDropdown.scss';

type PropsType = {
    children: ReactNode;
    activeClass: string;
};
export const TopStripMenuDropdown: FC<PropsType> = ({children, activeClass}) => (
    <div className={`${s.topStripMenuDropdown} ${activeClass}`}>
        <div className={s.dropdownContent}>{children}</div>
    </div>
);
