import React, {memo, useState} from 'react';

import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {Icon} from 'components/__helpers/icon/icon';
import {LINK_PAGE} from 'constants/links';
import {ApplicationStoreType} from 'constants/typescript/types';

import {LoginPopupNames} from '../../loginPopup';
import loginStyle from '../../loginPopup.scss';

import s from './errorBlock.scss';

export type PropsType = {
    phone: string;
    setPopup: (popup: LoginPopupNames) => void;
};

export const ErrorBlock = memo<PropsType>(({phone, setPopup}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={s.wrap}>
            <button className={s.backButton} onClick={() => setPopup(LoginPopupNames.member)}>
                <Icon name="arrow" />
                Try again
            </button>
            <div className={classNames(s.error, {[s.shadow]: isOpen})}>
                <div className={s.errorIcon}>!</div>
                <h3 className={loginStyle.title}>We couldn’t find your vision benefits</h3>
                <p className={`${loginStyle.text} ${loginStyle.signUp}`}>
                    If you have a vision insurance plan, check <br /> your details and{' '}
                    <button onClick={() => setPopup(LoginPopupNames.member)}>try again</button>
                </p>

                <button
                    className={classNames(s.needHelp, {[s.rotate]: isOpen})}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    Need help? <Icon name="arrow" />
                </button>
            </div>
            <AnimateHeight duration={300} height={isOpen ? 'auto' : '0'}>
                <div className={s.helpBlock}>
                    <h3 className={s.title}>For additional assistance</h3>
                    <ul className={s.list}>
                        <li>
                            See our{' '}
                            <Link className={s.link} to={LINK_PAGE.FAQ}>
                                troubleshooting FAQ
                            </Link>
                        </li>
                        <li>
                            Contact your insurance customer <br /> service at{' '}
                            <a className={s.link} href={`tel:${phone}`}>
                                {phone}
                            </a>
                        </li>
                    </ul>
                </div>
            </AnimateHeight>
        </div>
    );
});

/* istanbul ignore next */
const mapStateToProps = (store: ApplicationStoreType) => ({
    phone: store.storeInfo.phones.default,
});

export const ErrorBlockConnected = connect(mapStateToProps)(ErrorBlock);
