import {createAction, createReducer} from 'redux-act';

import {resetDataFromLocalStorage} from 'features/contacts/reducers/resetData/resetData';
import {fetchQuoteItemV2} from 'features/wizard/store/reducers/configure/configure';

export const initialState = {isAllowedForQuickOrder: false};

/**
 * List of actions
 */
export const toggleSavePrescriptionApproval = createAction(
    'TOGGLE_SAVE_PRESCRIPTION_APPROVAL_LENSES',
);

/**
 * save prescription reducer
 */
export const savePrescriptionApproval = createReducer({}, initialState)
    .on(toggleSavePrescriptionApproval, (state: {isAllowedForQuickOrder: boolean}) => ({
        isAllowedForQuickOrder: !state.isAllowedForQuickOrder,
    }))
    .on(`${fetchQuoteItemV2}_FULFILLED`, (state, {data}) => ({
        isAllowedForQuickOrder: Boolean(data.isAllowedForQuickOrder),
    }))
    .on(resetDataFromLocalStorage, () => initialState);
