/**
 * Constants for upsellTab and nestedUpsell, Tab - is on top level, nested - options according to tabs.
 * Current set active (setActiveOption) option receive object like {[parentNode]: childNode} ~ Record<string[], number>
 *     numbers in parent node will be converted to String type
 *
 * Example: the "BLUE LIGHT" Tab node is 232 and only one option "Lenses" with node 288
 *          if we click on hydrophobic option, expect object will be - {"232": 288}
 *          if we click to clear option - it will be clear all the options.
 *
 * Exception: Superhydrophobic option have the different parentNode, that depends on sended object to backend for
 * total price calculate. => that mean SuperHydrophobic option will be not active on Clear tab select,
 * but activate by clicking directly.
 */

export const upsellTitles = {
    TITLE_HYDROPHOBIC: 'Super Hydrophobic',
    TITLE_CLEAR: 'Clear',
    TITLE_BLUELIGHT_LENSES: 'Blue Light Lenses',
    TITLE_PHOTOCHROMIC_TRANSITION: 'Transitions® VI (Light Adjusting)',
    TITLE_PHOTOCHROMIC_PHOTOCHROMIC: 'Photochromic',
    TITLE_SUNGLASSES_POLARIZED: 'Polarized',
    TITLE_SUNGLASSES_TINT: 'Color Tint',
    TITLE_SUNGLASSES_MIRRORED: 'Mirrored',
};

export const upsellOptions = {
    CLEAR_OPTION: {'234': 2},

    HYDROPHOBIC_OPTION: {'1': 2},

    BLUELIGHT_WITH_LENSES: {'232': 288},

    PHOTOCHROMIC_WITH_TRANSITIONS: {'233': 60},
    PHOTOCHROMIC_WITH_PHOTOCHROMIC: {'233': 290},

    SUNGLASSES_WITH_POLARIZED: {'228': 58},
    SUNGLASSES_WITH_COLOR_TINT: {'228': 273},
    SUNGLASSES_WITH_MIRRORED: {'228': 21},
};

export const upsellsNodes = {
    CLEAR_TAB: 234,
    CLEAR_DEFAULT_OPTION: 2,
    HYDROPHOBIC_TAB: 1,
    HYDROPHOBIC_OPTION: 2,

    BLUE_LIGHT_TAB: 232,
    BLUE_LIGHT_LENSES: 288,

    PHOTOCHROMIC_TAB: 233,
    PHOTOCHROMIC_TRANSITIONS: 60,
    PHOTOCHROMIC_PHOTOCHROMIC: 290,

    SUNGLASSES_TAB: 228,
    SUNGLASSES_POLARIZED: 58,
    SUNGLASSES_COLOR_TINT: 273,
    SUNGLASSES_MIRRORED: 21,
};

export const upsellsAlias = {
    HYDROPHOBIC: 'hydrophobic',
};
