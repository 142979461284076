// @flow

/**
 * Returns correct className depends on window width
 *
 * @param {string} className  - some class
 * @returns {string} - className with desktop/mobile postfix
 */
export const getCorrectClassName = (className: string): string => {
    const {innerWidth} = window;
    const classNamePostFix = innerWidth >= 1024 ? 'desktop' : 'mobile';
    return `${className}--${classNamePostFix}`;
};
