// @flow
import {assocPath} from 'ramda';
import {createActions, handleActions, type ActionType} from 'redux-actions';

import {prescriptionErrorActions} from 'reducers/prescription';

import type {PrescriptionEditType} from './prescriptionEditType';

export const defaultState: PrescriptionEditType = {
    id: -1,
    prevId: -1,
    comment: '',
    name: '',
    data: {
        add_od: null,
        add_os: null,
        axi_od: null,
        axi_os: null,
        cyl_od: null,
        cyl_os: null,
        sph_od: null,
        sph_os: null,
        pd: null,
        pd_left: null,
        pd_near: null,
        pd_right: null,
        isTwoPd: false,
    },
    error: '',
};

type FieldType = {name: string, value: string | number};

export const {setPrescription, updateField, updateScheme, clearField, clearPrescription} =
    createActions({
        SET_PRESCRIPTION: (
            prescription: PrescriptionEditType,
        ): ActionType<PrescriptionEditType> => ({
            ...prescription,
            prevId: prescription.id,
        }),
        UPDATE_FIELD: (name: string, value: string | number): ActionType<FieldType> => ({
            name,
            value,
        }),
        UPDATE_SCHEME: (scheme: {name: string, value: string}) => scheme,
        CLEAR_FIELD: (name: string): ActionType<string> => name,
        CLEAR_PRESCRIPTION: (): ActionType<> => {},
    });

/**
 * Prescription edit reducer
 *
 * @returns {PrescriptionEditType} state - next state
 */
export const prescriptionEdit = handleActions(
    {
        [setPrescription]: (
            state: PrescriptionEditType,
            {payload: {id, comment, name, data}}: ActionType<typeof setPrescription>,
        ): PrescriptionEditType => ({
            ...(state.prevId === id
                ? state
                : {
                      id,
                      prevId: id,
                      comment,
                      name,
                      data: {
                          ...data,
                          isTwoPd: data.pd_left && data.pd_right,
                      },
                      error: '',
                  }),
        }),
        [updateField]: (
            state: PrescriptionEditType,
            {payload: {name, value}}: ActionType<typeof updateField>,
        ): PrescriptionEditType => ({
            ...state,
            [name]: value,
        }),
        [updateScheme]: (
            state: PrescriptionEditType,
            {payload: {name, value}}: ActionType<typeof updateField>,
        ): PrescriptionEditType => ({
            ...state,
            ...assocPath(name.split('.'), value, state),
        }),
        [clearField]: (
            state: PrescriptionEditType,
            {payload: name}: ActionType<typeof updateField>,
        ): PrescriptionEditType => ({
            ...state,
            [name]: defaultState[name],
        }),
        [clearPrescription]: (): PrescriptionEditType => defaultState,
        [prescriptionErrorActions]: (
            state: PrescriptionEditType,
            {
                payload: {
                    error: {response},
                },
            }: ActionType<typeof prescriptionErrorActions>,
        ): PrescriptionEditType => ({
            ...state,
            error: response.data.message,
        }),
    },
    defaultState,
);
