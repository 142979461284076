// @flow
type ReturnObjectType = {
    [string]: {
        value: string,
        error: string,
    },
};

/**
 * Get name and text error for field
 *
 * @param {string} message - message with name and text for error
 * @returns {ReturnObjectType} - field
 */
export const getField = (message: string): ReturnObjectType => {
    const arr = message.split("'");

    if (arr.length === 0 || arr[0] === '') return {};

    return {
        [arr[1].toLowerCase()]: `${arr[0]}${arr[2]}`,
    };
};
