export const DEVELOP_CLIENT_ID = process.env.CLIENT_ID || '92bc3831-83ae-4089-a44e-2db8991d6e3c';
export const PRODUCTION_CLIENT_ID = '04a1379c-bdb0-4e33-a2b8-190d7cb85ad7';

export const selectClientId = () =>
    process.env.APP_ENV === 'production' ? PRODUCTION_CLIENT_ID : DEVELOP_CLIENT_ID;

export const CLIENT_ID = selectClientId();

export const CUSTOMER_SUPPORT_CLIENT_ID =
    process.env.APP_ENV === 'production'
        ? '4b664ade-117d-476c-8da9-6de62f22ed7f'
        : 'be304618-b16e-45b8-981e-9e0557fa6fa5';

export const CLIENT_ID_UHC =
    process.env.APP_ENV === 'production' ? '748ec77d-676d-4b7e-9793-4d2e6235a53d' : 'memberId';
