// @flow
import type {AxiosResponse} from 'axios';
import FormData from 'form-data';
import {createActions, handleActions, type ActionType} from 'redux-actions';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';
import {getField} from 'reducers/contactUsForm/utils/getField';

import type {ContactUsFormType} from './contactUsFormType';

export const defaultState: ContactUsFormType = {
    form: {
        name: '',
        phone: '',
        email: '',
        message: '',
        selected: 'member',
        file: '',
        fileName: '',
        reason_inquiries: '',
    },
    errors: {
        name: '',
        phone: '',
        email: '',
        message: '',
        fileValidationError: '',
        reason_inquiries: '',
    },
    isError: false,
    message: '',
};

const formDataKeys = {
    selected: 'user',
    name: 'name',
    phone: 'telephone',
    email: 'email',
    message: 'comment',
    file: 'files_1',
    reason_inquiries: 'reason_inquiries',
};

export const {inputValue, inputError, clearMessage, clearForm, sendFormData, clearFormErrors} =
    createActions({
        INPUT_VALUE: (key, value) => ({key, value}),
        INPUT_ERROR: (key, error) => ({key, error}),
        CLEAR_MESSAGE: () => {},
        CLEAR_FORM: () => {},
        SEND_FORM_DATA: (
            form: $PropertyType<ContactUsFormType, 'form'>,
        ): ActionType<AxiosResponse> => {
            const data = new FormData();

            Object.keys(form).forEach((key: string) => {
                if (formDataKeys[key]) data.append(formDataKeys[key], form[key]);
            });

            return {
                request: {
                    method: 'post',
                    url: `/backend/${ENDPOINTS.CONTACT_US_FORM}`,
                    data,
                },
            };
        },
        CLEAR_FORM_ERRORS: () => {},
    });

export const contactUsForm = handleActions(
    {
        [inputValue]: (
            state: ContactUsFormType,
            {payload: {key, value}}: ActionType<typeof inputValue>,
        ): ContactUsFormType => ({
            ...state,
            form: {
                ...state.form,
                [key]: value,
            },
            errors: {
                ...state.errors,
                [key]: '',
            },
        }),
        [inputError]: (
            state: ContactUsFormType,
            {payload: {key, error}}: ActionType<typeof inputError>,
        ): ContactUsFormType => ({
            ...state,
            errors: {
                ...state.errors,
                [key]: error,
            },
        }),
        [clearForm]: (state: ContactUsFormType): ContactUsFormType => ({
            ...state,
            form: defaultState.form,
        }),
        [clearMessage]: (state: ContactUsFormType): ContactUsFormType => ({
            ...state,
            message: '',
        }),
        [`${sendFormData}${FULFILLED}`]: (
            state: ContactUsFormType,
            {payload}: ActionType<typeof sendFormData>,
        ): ContactUsFormType => ({
            ...state,
            isError: payload.data.error,
            message: payload.data.message,
        }),
        [`${sendFormData}${REJECTED}`]: (
            state: ContactUsFormType,
            {
                payload: {
                    error: {response},
                },
            }: ActionType<typeof sendFormData>,
        ): ContactUsFormType => {
            const field = getField(response.data.message ? response.data.message : '');

            return {
                ...state,
                isError: response.data.error,
                errors: {
                    ...state.errors,
                    ...field,
                },
            };
        },
        [clearFormErrors]: (state: ContactUsFormType): ContactUsFormType => ({
            ...state,
            errors: defaultState.errors,
        }),
    },
    defaultState,
);
