// @flow
import type {AxiosResponse} from 'axios';
import {createActions, handleActions, type ActionType} from 'redux-actions';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';

import type {TrackingType} from './trackingType';

export const defaultState = {
    message: '',
    status: '',
    subtitle: '',
    title: '',
    tracking: [],
    isLoading: false,
};

export const {getTracking, clearTracking} = createActions({
    GET_TRACKING: (orderId: string, email: string): ActionType<AxiosResponse> => ({
        request: {
            headers: {Authorization: ''},
            method: 'GET',
            url: `/backend/${ENDPOINTS.GET_TRACKING}/${orderId}/${email}`,
        },
    }),
    CLEAR_TRACKING: (): ActionType<> => {},
});

/**
 * Tracking reducer
 *
 * @returns {TrackingType} state - next state
 */
export const tracking = handleActions(
    {
        [`${getTracking}${FULFILLED}`]: (
            state: TrackingType,
            {payload}: ActionType<typeof getTracking>,
        ): TrackingType => ({
            ...state,
            ...payload.data,
            isLoading: false,
        }),
        [getTracking]: (state: TrackingType): TrackingType => ({
            ...state,
            isLoading: true,
        }),
        [`${getTracking}${REJECTED}`]: (): TrackingType => ({
            ...defaultState,
            title: 'Having some problems with request. Please try again later.',
            isLoading: false,
        }),
        [clearTracking]: (): TrackingType => defaultState,
    },
    defaultState,
);
