import {createReducer} from 'redux-act';

import {PrescriptionType} from './prescriptionTypes';
import {
    fetchSavedCustomerPrescriptions,
    fetchSavedCustomerPrescriptionsMS,
    initialState,
    SavedPrescriptionsType,
} from './savedCustomerPrescription';
import {ApplicationStoreType} from '../../../../../constants/typescript/types';
import {adapterPrescriptionMs} from '../../../utils/prescription/prescription';

/**
 * Contains all saved prescriptions of logged customer
 */
export const savedCustomerPrescriptions = createReducer<SavedPrescriptionsType>(
    {
        [`${fetchSavedCustomerPrescriptions}_FULFILLED`]: (
            state: SavedPrescriptionsType,
            {data},
        ): SavedPrescriptionsType => {
            const prescriptions = data.map(prescription => ({
                ...prescription,
                isMultiPD: Boolean(prescription.pd.left && prescription.pd.right),
            }));

            return {
                prescriptions,
                status: 'fulfilled',
            };
        },
        [`${fetchSavedCustomerPrescriptions}_PENDING`]: (state: SavedPrescriptionsType) => ({
            ...state,
            status: 'pending',
        }),
        [`${fetchSavedCustomerPrescriptions}_REJECTED`]: (state: SavedPrescriptionsType) => ({
            ...state,
            status: 'rejected',
        }),
        [`${fetchSavedCustomerPrescriptionsMS}_FULFILLED`]: (
            state: SavedPrescriptionsType,
            {data},
        ): SavedPrescriptionsType => {
            const prescriptions = data.map(prescription => ({
                ...prescription,
                ...adapterPrescriptionMs(prescription.data),
            }));
            return {
                prescriptions,
                status: 'fulfilled',
            };
        },
        [`${fetchSavedCustomerPrescriptionsMS}`]: (state: SavedPrescriptionsType) => ({
            ...state,
            status: 'pending',
        }),
        [`${fetchSavedCustomerPrescriptionsMS}_REJECTED`]: (state: SavedPrescriptionsType) => ({
            ...state,
            status: 'rejected',
        }),
    },
    initialState,
);

/**
 * Return saved prescription
 *
 * @param {ApplicationStoreType} store - redux store
 * @returns {*} saved prescription
 */
export const getSavedCustomerPrescriptions = (store: ApplicationStoreType): PrescriptionType[] =>
    store.wizardMs.prescription.savedCustomerPrescriptions.prescriptions;
