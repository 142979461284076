// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';
import {createAction} from 'redux-act';

import {type AddressItemType} from 'reducers/address';

import type {AddressEditType, StartSetAddressType} from './addressEditType';

export const defaultState = {
    id: -1,
    prevId: -1,
    firstname: '',
    lastname: '',
    telephone: '',
    street: '',
    city: '',
    postcode: '',
    countryCode: '',
    region: '',
    originalEmail: '',
    regionId: 0,
};

type FieldType = {name: string, value: string | number};

export type AutocompleteResType = {
    street: string,
    city: string,
    region: string,
    country: string,
    postCode: string,
};

export const {
    setAddress,
    updateField,
    clearField,
    openForm,
    closeForm,
    clearAddress,
    setAutocomplete,
} = createActions({
    SET_ADDRESS: (address: AddressItemType): ActionType<AddressItemType> => address,
    UPDATE_FIELD: (name: string, value: string | number): ActionType<FieldType> => ({
        name,
        value,
    }),
    CLEAR_FIELD: (name: string): ActionType<string> => name,
    OPEN_FORM: (id: number): ActionType<> => id,
    ClOSE_FORM: (): ActionType<> => {},
    CLEAR_ADDRESS: (): ActionType<> => {},
    SET_AUTOCOMPLETE: (selectedAddress: string): ActionType<AutocompleteResType> => selectedAddress,
});

export const startSetAddress = createAction<StartSetAddressType, StartSetAddressType>(
    'START_SET_ADDRESS',
    params => params,
);

const setEditAddress = (address: AddressItemType): AddressEditType =>
    Object.keys(address)
        .filter((key: string) => Object.keys(defaultState).includes(key))
        .reduce((obj: any, field: string) => ({...obj, [field]: address[field]}), {});

/**
 * Address edit reducer
 *
 * @returns {AddressEditType} state - next state
 */
export const addressEdit = handleActions(
    {
        [setAddress]: (
            state: AddressEditType,
            {payload: address}: ActionType<typeof setAddress>,
        ): AddressEditType => ({
            ...setEditAddress(address),
            prevId: address.id,
        }),
        [updateField]: (
            state: AddressEditType,
            {payload: {name, value}}: ActionType<typeof updateField>,
        ): AddressEditType => ({
            ...state,
            [name]: value,
        }),
        [clearField]: (
            state: AddressEditType,
            {payload: name}: ActionType<typeof updateField>,
        ): AddressEditType => ({
            ...state,
            [name]: defaultState[name],
        }),
        [openForm]: (state: AddressEditType, {payload: id}): AddressEditType => ({
            ...(state.prevId === id ? state : defaultState),
            id,
            prevId: id,
        }),
        [closeForm]: (state: AddressEditType): AddressEditType => ({
            ...state,
            id: -1,
        }),
        [clearAddress]: (): AddressEditType => defaultState,
    },
    defaultState,
);
