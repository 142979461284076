import {createAction, createReducer} from 'redux-act';

import {ENDPOINTS} from 'constants/endpoints';

export type ContactLensType = Array<{option: string; clean_option: string}>;
export type ContactLensPrescriptionType = {
    sph?: ContactLensType;
    bc?: ContactLensType;
    dia?: ContactLensType;
    axi?: ContactLensType;
    cyl?: ContactLensType;
    add?: ContactLensType;
};

export const initialState = {
    isWizardContainerOpen: false,
    contact_lens_colors: [],
};

/**
 * List of actions
 */
export const toggleVisibilityWizardContainer = createAction('TOGGLE_VISIBILITY_WIZARD_CONTAINER');
export const closeVisibilityWizardContainer = createAction('CLOSE_VISIBILITY_WIZARD_CONTAINER');
export const fetchProductWizard = createAction('FETCH_PRODUCT_WIZARD', (id: number) => ({
    request: {
        method: 'get',
        url: `/backend/${ENDPOINTS.PRODUCT_GET_WIZARD({id})}`,
    },
}));

/**
 * Step reducer
 */
export const wizard = createReducer({}, initialState)
    /**
     * Subscribe actions
     * https://www.npmjs.com/package/redux-act#typescript
     */
    .on(`${fetchProductWizard}_FULFILLED`, (state, {data}) => {
        if (
            Object.keys(state).includes('contact_lens_colors') &&
            !Object.keys(data).includes('contact_lens_colors')
        ) {
            // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
            return {...(({contact_lens_colors, ...others}) => ({...others}))(state), ...data};
        }
        return {
            ...state,
            ...data,
        };
    })
    .on(`${toggleVisibilityWizardContainer}`, state => ({
        ...state,
        isWizardContainerOpen: !state.isWizardContainerOpen,
    }))
    .on(`${closeVisibilityWizardContainer}`, state => ({
        ...state,
        isWizardContainerOpen: false,
    }));
