import {HALF_PUPIL_OFFSET} from 'constants/tryOn';

import {TryOnType} from './tryOnType';

/**
 * Generates an adapter object for Try-On data.
 
@param {TryOnType} tryonData - The Try-On data object.
@param {{ category_id: number, product_id: number }} productData - The product data object containing the category ID and product ID.
@param {number} pd - The PD (pupillary distance) value.
@returns {object} - The adapter object.
 */
export function adapterTryOnData(
    tryonData: TryOnType,
    productData: {categoryId: number; productId: number},
    pd: number,
) {
    return {
        category_id: '',
        product_id: productData.productId,
        canvas_width: 410,
        canvas_height: 528,
        gender: '',
        pupil_left_x: tryonData.leftPupilCord.x + HALF_PUPIL_OFFSET.left.x,
        pupil_left_y: tryonData.leftPupilCord.y + HALF_PUPIL_OFFSET.left.y,
        pupil_right_x: tryonData.rightPupilCord.x + HALF_PUPIL_OFFSET.right.x,
        pupil_right_y: tryonData.rightPupilCord.y + HALF_PUPIL_OFFSET.right.y,
        pd,
        tryon_photo_source: 'Upload',
    };
}
